import Axios from 'services/Client';
import ApplicationMilestones from 'models/ApplicationMilestones';
import UpdateSDApplicationTaskRequest from './requests/UpdateSDApplicationTaskRequest';
import UpdateSDApplicationFlagRequest from './requests/UpdateSDApplicationFlagRequest';
import UpdateSDApplicationKeyDateRequest from './requests/UpdateSDApplicationKeyDateRequest';

interface GetMilestonesResponse {
  milestones: ApplicationMilestones;
}

const SDApplicationManagementService = {
  getApplicationMilestones: async (
    applicationId: number,
  ): Promise<ApplicationMilestones> => {
    const response = await Axios.get<GetMilestonesResponse>(
      `/smart-dispense/${applicationId}/milestones`,
    );
    return response.data.milestones;
  },
  updateApplicationTask: async (
    applicationId: number,
    taskId: number,
    payload: UpdateSDApplicationTaskRequest,
  ): Promise<ApplicationMilestones> => {
    const response = await Axios.put<GetMilestonesResponse>(
      `/smart-dispense/${applicationId}/tasks/${taskId}`,
      payload,
    );
    return response.data.milestones;
  },

  updateApplicationFlag: async (
    applicationId: number,
    flagId: number,
    payload: UpdateSDApplicationFlagRequest,
  ): Promise<ApplicationMilestones> => {
    const response = await Axios.put<GetMilestonesResponse>(
      `/smart-dispense/${applicationId}/flags/${flagId}`,
      payload,
    );
    return response.data.milestones;
  },

  updateApplicationKeyDate: async (
    applicationId: number,
    keyDateId: number,
    payload: UpdateSDApplicationKeyDateRequest,
  ): Promise<ApplicationMilestones> => {
    const response = await Axios.put<GetMilestonesResponse>(
      `/smart-dispense/${applicationId}/key-dates/${keyDateId}`,
      payload,
    );
    return response.data.milestones;
  },
};

export default SDApplicationManagementService;