import Axios from '../Client';
import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import UpdateSDOutletDetailsRequest from './requests/UpdateSDOutletDetailsRequest';
import ToggleApplicationRequest from './requests/ToggleApplicationRequest';
import UpdateSDLegalEntityAddressRequest from './requests/UpdateSDLegalEntityAddressRequest';
import CreateBarRequest from './requests/CreateBarRequest';
import InnserveDetailsRequest from './requests/InnserveDetailsRequest';
import UpdateSDAdminDetailsRequest from './requests/SDAdminDetails';
import QueryParams from 'models/QueryParams';
import SDApplication from 'models/SDApplication';
import Mention from 'models/Mention';
import Pagination from 'models/Pagination';
import Comment from 'models/Comment';
import Application from 'models/Application';
import Contact from 'models/Contact';
import { Wholesaler } from 'models/Wholesaler';
import DeleteApplicationRequest from 'services/ApplicationService/requests/DeleteApplicationRequest';
import SubmitSDApplicationRequest from 'services/SDApplicationService/requests/SubmitSDApplicationRequest';
import ApproveSDApplicationRequest from 'services/SDApplicationService/requests/ApproveSDApplicationRequest';
import AccountNumbersRequest from 'services/SDApplicationService/requests/AccountNumbersRequest';
import CreateNewSDApplicationRequest from 'services/SDApplicationService/requests/CreateNewSDApplicationRequest';
import UpdateSDApplicationRequest from 'services/SDApplicationService/requests/UpdateSDApplicationRequest';
import UpdateSDSalesRepsRequest from 'services/SDApplicationService/requests/UpdateSDSalesRepsRequest';
import CreateNoteRequest from 'services/ApplicationService/requests/CreateNoteRequest';
import CreateHeinekenDirectRequest from 'services/ApplicationService/requests/CreateHeinekenDirectRequest';
import CompleteSDApplicationRequest from 'services/SDApplicationService/requests/CompleteSDApplicationRequest';
import SDApplicantRequest from 'services/SDApplicationService/requests/SDApplicantRequest';
import UpdateContractInfoRequest from './requests/UpdateContractInfoRequest/UpdateContractInfoRequest';

const SDApplicationService = {
  createNewSDApplication: async (
    payload: CreateNewSDApplicationRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.post<SDApplication>(
      `/smart-dispense`,
      payload,
    );
    return response.data;
  },

  paginateApplications: async (
    params: QueryParams,
    config?: AxiosRequestConfig,
  ): Promise<Pagination<SDApplication>> => {
    let paramsObject = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== '' && v !== null),
    );

    const response = await Axios.get<Pagination<SDApplication>>(
      `/smart-dispense`,
      {
        ...config,
        params: { ...paramsObject },
        paramsSerializer: (obj) => {
          return qs.stringify(obj, { encode: false });
        },
      },
    );
    return response.data;
  },

  findApplication: async (id: number): Promise<SDApplication> => {
    const response = await Axios.get<SDApplication>(`/smart-dispense/${id}`);
    return response.data;
  },

  updateApplication: async (
    id: number,
    payload: UpdateSDApplicationRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${id}`,
      payload,
    );
    return response.data;
  },

  updateSalesReps: async (
    id: number,
    payload: UpdateSDSalesRepsRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${id}/sales-reps`,
      payload,
    );
    return response.data;
  },

  updateSDOutletDetails: async (
    applicationId: number,
    payload: UpdateSDOutletDetailsRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/outlet`,
      payload,
    );
    return response.data;
  },

  updateSDLegalEntityAddress: async (
    applicationId: number,
    payload: UpdateSDLegalEntityAddressRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/legal-address`,
      payload,
    );
    return response.data;
  },

  loadApplicationComments: async (id: number): Promise<Comment[]> => {
    const response = await Axios.get<Comment[]>(`smart-dispense/${id}/notes`);
    return response.data;
  },

  loadApplicationMentionUsers: async (id: number): Promise<Mention[]> => {
    const response = await Axios.get<Mention[]>(
      `smart-dispense/${id}/mentions`,
    );
    return response.data;
  },

  createNote: async (
    id: number,
    payload: CreateNoteRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.post<SDApplication>(
      `smart-dispense/${id}/notes`,
      payload,
    );
    return response.data;
  },

  createApplicant: async (
    id: number,
    payload: SDApplicantRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.post<SDApplication>(
      `/smart-dispense/${id}/applicants`,
      payload,
    );
    return response.data;
  },

  deleteApplicant: async (
    applicationId: number,
    applicantId: number,
  ): Promise<SDApplication> => {
    const response = await Axios.delete<SDApplication>(
      `/smart-dispense/${applicationId}/applicants/${applicantId}`,
    );
    return response.data;
  },

  updateApplicant: async (
    applicationId: number,
    applicantId: number,
    payload: SDApplicantRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/applicants/${applicantId}`,
      payload,
    );
    return response.data;
  },

  createBar: async (
    id: number,
    payload: CreateBarRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.post<SDApplication>(
      `/smart-dispense/${id}/outlet/bar`,
      payload,
    );
    return response.data;
  },

  deleteBar: async (
    applicationId: number,
    barId: number,
  ): Promise<SDApplication> => {
    const response = await Axios.delete<SDApplication>(
      `/smart-dispense/${applicationId}/outlet/bar/${barId}`,
    );
    return response.data;
  },

  updateBar: async (
    applicationId: number,
    barId: number,
    payload: CreateBarRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/outlet/bar/${barId}`,
      payload,
    );
    return response.data;
  },

  updateInnserveDetails: async (
    applicationId: number,
    payload: InnserveDetailsRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/inserve`,
      payload,
    );
    return response.data;
  },

  updateContractInfo: async (
    applicationId: number,
    payload: UpdateContractInfoRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/contract-info`,
      payload,
    );
    return response.data;
  },

  updateAdminDetails: async (
    id: number,
    payload: UpdateSDAdminDetailsRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${id}/admin-details`,
      payload,
    );
    return response.data;
  },

  deleteApplication: async (
    applicationId: number,
    payload: DeleteApplicationRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.delete<SDApplication>(
      `/smart-dispense/${applicationId}`,
      { data: payload },
    );
    return response.data;
  },

  submitApplication: async (
    applicationId: number,
    payload: SubmitSDApplicationRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/submit`,
      payload,
    );
    return response.data;
  },

  approveApplication: async (
    applicationId: number,
    payload: ApproveSDApplicationRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/approve`,
      payload,
    );
    return response.data;
  },

  listContactDetails: async (id: number): Promise<Contact[]> => {
    const response = await Axios.get<Contact[]>(
      `/smart-dispense/${id}/persons`,
    );
    return response.data;
  },

  createHeinekenDirect: async (
    id: number,
    payload: CreateHeinekenDirectRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.post<SDApplication>(
      `/smart-dispense/${id}/heineken-direct`,
      payload,
    );
    return response.data;
  },

  updateHeinekenDirect: async (
    applicationId: number,
    heinekenDirectId: number,
    payload: CreateHeinekenDirectRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/heineken-direct/${heinekenDirectId}`,
      payload,
    );
    return response.data;
  },

  deleteHeinekenDirect: async (
    applicationId: number,
    heinekenDirectId: number,
  ): Promise<SDApplication> => {
    const response = await Axios.delete<SDApplication>(
      `/smart-dispense/${applicationId}/heineken-direct/${heinekenDirectId}`,
    );
    return response.data;
  },

  reopenApplication: async (applicationId: number) => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/reopen`,
    );
    return response.data;
  },

  completeApplication: async (
    applicationId: number,
    payload: CompleteSDApplicationRequest,
  ) => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/complete`,
      payload,
    );
    return response.data;
  },

  toggleApplication: async (
    applicationId: number,
    payload: ToggleApplicationRequest,
  ): Promise<SDApplication | Application> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/change`,
      payload,
    );
    return response.data;
  },

  requestAccountNumbers: async (
    applicationId: number,
    payload: AccountNumbersRequest,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/master-data`,
      payload,
    );
    return response.data;
  },

  loadWholesalers: async (): Promise<Wholesaler[]> => {
    const response = await Axios.get<Wholesaler[]>(`/wholesalers`);
    return response.data;
  },
};

export default SDApplicationService;
