import Axios from 'services/Client';
import { ApplicationHGSSDirectDebit } from 'models/ApplicationHGSSDirectDebit';
import { UpdateApplicationHGSSDirectDebitRequest } from './requests/UpdateApplicationHGSSDirectDebitRequest';
import SkipDirectDebitSignature from 'services/SDHGSSDirectDebitService/requests/SkipDirectDebitSignature';
import SDApplication from 'models/SDApplication';

export const HGSSDirectDebitService = {
  loadApplicationHGSSDirectDebit: async (
    applicationId: number,
  ): Promise<ApplicationHGSSDirectDebit[]> => {
    const response = await Axios.get<ApplicationHGSSDirectDebit[]>(
      `/smart-dispense/${applicationId}/direct-debit`,
    );
    return response.data;
  },
  updateApplicationHGSSDirectDebit: async (
    applicationId: number,
    payload: UpdateApplicationHGSSDirectDebitRequest,
  ): Promise<ApplicationHGSSDirectDebit[]> => {
    const response = await Axios.post<ApplicationHGSSDirectDebit[]>(
      `/smart-dispense/${applicationId}/direct-debit`,
      payload,
    );
    return response.data;
  },
  loadApplicationHGSSDirectDebitDocument: async (
    applicationId: number,
    contactId: number,
  ): Promise<string> => {
    const response = await Axios.get<{ url: string }>(
      `/smart-dispense/${applicationId}/direct-debit/${contactId}`,
    );
    return response.data.url;
  },
  skipDirectDebitSignature: async (
    applicationId: number,
    payload: SkipDirectDebitSignature,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/direct-debit`,
      payload,
    );
    return response.data;
  },
};
