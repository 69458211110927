export const config = {
  auth: {
    clientId: '4e05248f-ae64-413d-b96c-a4132c94c450',
    authority:
      'https://login.microsoftonline.com/66e853de-ece3-44dd-9d66-ee6bdf4159d4',
    response_type: 'id_token',
    redirectUri: process.env.REACT_APP_AD_REDIRECT_URL,
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  },
};
