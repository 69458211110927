import React from 'react';
import styled from 'styled-components';
import { Menu, Popover, Position, Icon } from '@blueprintjs/core';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import theme from 'theme';
import './ApplicationsSidebarMenu.scss';

const Container = styled.div`
  .bp3-icon {
    color: #fff;
  }
  &:hover {
    text-decoration: none;
    background-color: rgba(0, 157, 0, 0.5);
  }
  .applications-tab {
    cursor: default;
    pointer-events: none;
    &.active-applications {
      background-color: ${theme.cta.active};
      cursor: default;
      pointer-events: none;
    }
  }
`;
export interface Sublink {
  title: string;
  url: string;
}

interface Props {
  icon: any;
  url: string;
  sublinks?: Sublink[];
  position?: 'bottom-right' | 'bottom-left';
}

const ApplicationsSidebarMenu: React.FC<Props & RouteComponentProps> = (
  props,
) => {
  const { icon, url, sublinks, position, location, history } = props;
  const positions = {
    'bottom-right': Position.BOTTOM_RIGHT,
    'bottom-left': Position.BOTTOM_LEFT,
  };

  const isSublinkActive = (sublink: {
    title: string;
    url: string;
  }): boolean => {
    return (location.pathname + location.search).includes(sublink.url);
  };

  const isActive = (url: string): boolean => {
    return location.pathname.startsWith(url);
  };

  return (
    <Container className="sidebar-menu">
      <Popover
        modifiers={{
          arrow: { enabled: false },
          keepTogether: { enabled: true },
        }}
        position={(position && positions[position]) || Position.RIGHT}
        content={
          <Menu>
            {sublinks &&
              sublinks.map((sublink, i) => (
                <Menu.Item
                  key={i}
                  className={isSublinkActive(sublink) ? 'active-sublink' : ''}
                  id={`${sublink.title}`}
                  text={sublink.title}
                  onClick={() => {
                    history.push(sublink.url);
                  }}
                />
              ))}
          </Menu>
        }
      >
        <Link
          to={url}
          className={`applications-tab ${
            isActive(url) ? 'active-applications' : ''
          }`}
        >
          {<Icon icon={icon} iconSize={20} />}
        </Link>
      </Popover>
    </Container>
  );
};

export default withRouter(ApplicationsSidebarMenu);
