import Axios from 'services/Client';
import Classification from 'models/Classification';

const ClassificationService = {
  listClassifications: async (): Promise<Classification[]> => {
    const response = await Axios.get<Classification[]>('/classifications');
    return response.data;
  },
};

export default ClassificationService;
