import Axios from 'services/Client';
import ComboApplication from 'models/ComboApplication';

export const ComboContractService = {
  requestContractSignature: async (
    applicationId: number,
  ): Promise<ComboApplication> => {
    const response = await Axios.post<ComboApplication>(
      `/combo/${applicationId}/contract/signature`,
    );
    return response.data;
  },
  viewContractMainApplicant: async (
    applicationId: number,
  ): Promise<{ url: string }> => {
    const response = await Axios.get<{ url: string }>(
      `/combo/${applicationId}/contract/signature`,
    );
    return response.data;
  },
  viewContractSecondaryApplicant: async (
    applicationId: number,
  ): Promise<{ url: string }> => {
    const response = await Axios.get<{ url: string }>(
      `/combo/${applicationId}/contract/signature/additional-applicant`,
    );
    return response.data;
  }
};
