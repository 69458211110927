import Axios from 'services/Client';
import Application from 'models/Application';

const SigningService = {
  createSigningRequest: async (
    applicationId: number,
    applicantId: number,
  ): Promise<Application> => {
    const response = await Axios.get<Application>(
      `/applications/${applicationId}/applicants/${applicantId}/sign`,
    );
    return response.data;
  },
  viewSigningUrl: async (
    applicationId: number,
    applicantId: number,
  ): Promise<{ url: string }> => {
    const response = await Axios.get<{ url: string }>(
      `/applications/${applicationId}/applicants/${applicantId}/sign-url`,
    );
    return response.data;
  },
  viewStaticUrl: async (
    applicationId: number,
    applicantId: number,
  ): Promise<{ url: string }> => {
    const response = await Axios.get<{ url: string }>(
      `/applications/${applicationId}/applicants/${applicantId}/view-signature`,
    );
    return response.data;
  },
};

export default SigningService;