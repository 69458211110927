import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from 'components/common/PrivateRoute';
import { Loader } from 'components/App/App';
import { SidebarWrapper } from 'components/common/SidebarWrapper';
import { Provider as SDApplicationProvider } from 'contexts/SDApplicationContext';
import { Provider as BarLayoutProvider } from 'contexts/BarLayoutContext';
import { SDApplicationDetailProvider } from 'contexts/SDApplicationDetailContext';
import { SDApplicationManagementProvider } from 'contexts/SDApplicationManagementContext';
import { ComboApplicationDetailProvider } from 'contexts/ComboApplicationDetailContext';
import { ComboApplicationManagementProvider } from 'contexts/ComboApplicationManagementContext';

export const routeBase = '/smart-dispense/applications';

const SDApplicationsList = lazy(
  () => import('pages/SmartDispenseApplications/SDApplicationsList'),
);

const SDApplicationDetail = lazy(
  () => import('pages/SmartDispenseApplications/SDApplicationDetail'),
);

const ComboApplicationDetails = lazy(
  () => import('pages/ComboApplications/ComboApplicationDetails'),
);

const ApplicationsIndex: React.FC = () => {
  return (
    <SidebarWrapper>
      <SDApplicationProvider>
        <BarLayoutProvider>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                path={routeBase}
                exact
                component={SDApplicationsList}
                authenticated={true}
              />

              <Route
                path={`${routeBase}/combo/:id`}
                exact
                component={() => (
                  <ComboApplicationDetailProvider>
                    <ComboApplicationManagementProvider>
                      <ComboApplicationDetails />
                    </ComboApplicationManagementProvider>
                  </ComboApplicationDetailProvider>
                )}
                authenticated={true}
              />

              <Route
                path={`${routeBase}/:id`}
                exact
                component={() => (
                  <SDApplicationDetailProvider>
                    <SDApplicationManagementProvider>
                      <SDApplicationDetail />
                    </SDApplicationManagementProvider>
                  </SDApplicationDetailProvider>
                )}
                authenticated={true}
              />
              <Redirect from="*" to={routeBase} />
            </Switch>
          </Suspense>
        </BarLayoutProvider>
      </SDApplicationProvider>
    </SidebarWrapper>
  );
};

export default ApplicationsIndex;
