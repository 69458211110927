import Axios from '../Client';
import { AxiosRequestConfig } from 'axios';
import ComboApplication from 'models/ComboApplication';
import SDApplication from 'models/SDApplication';
import Application from 'models/Application';
import Contact from 'models/Contact';
import Comment from 'models/Comment';
import Mention from 'models/Mention';
import Pagination from 'models/Pagination';
import UpdateApplicationRequest from 'services/ComboApplicationService/requests/UpdateApplicationRequest';
import UpdateSalesRepsRequest from 'services/ComboApplicationService/requests/UpdateSalesRepsRequest';
import InnserveDetailsRequest from './requests/InnserveDetailsRequest';
import UpdateOutletDetailsRequest from './requests/UpdateOutletDetailsRequest';
import UpdateOutletSalesRequest from 'services/ComboApplicationService/requests/UpdateOutletSalesRequest';
import UpdateBillingDetailsRequest from 'services/ComboApplicationService/requests/UpdateBillingDetailsRequest';
import UpdateOrderDetailsRequest from 'services/ComboApplicationService/requests/UpdateOrderDetailsRequest';
import CreateNoteRequest from 'services/ComboApplicationService/requests/CreateNoteRequest';
import UpdateAdminDetailsRequest from 'services/ComboApplicationService/requests/UpdateAdminDetailsRequest';
import UpdateAdditionalInfoRequest from 'services/ComboApplicationService/requests/UpdateAdditionalInfoRequest';
import UpdateOffersRequest from 'services/ComboApplicationService/requests/UpdateOffersRequest';
import ApplicantRequest from 'services/ComboApplicationService/requests/ApplicantRequest';
import CreateHeinekenDirectRequest from 'services/ComboApplicationService/requests/CreateHeinekenDirectRequest';
import CreateNewApplicationRequest from 'services/ComboApplicationService/requests/CreateNewApplicationRequest';
import SubmitApplicationRequest from 'services/ComboApplicationService/requests/SubmitApplicationRequest';
import CompleteApplicationRequest from 'services/ComboApplicationService/requests/CompleteApplicationRequest';
import AccountNumbersRequest from 'services/ComboApplicationService/requests/AccountNumbersRequest';
import QueryParams from 'models/QueryParams';
import StringHelper from 'utils/StringHelper';
import DeleteAppicationRequest from 'services/ComboApplicationService/requests/DeleteApplicationRequest';
import ToggleApplicationRequest from 'services/ComboApplicationService/requests/ToggleApplicationRequest';
import CreateBarRequest from 'services/ComboApplicationService/requests/CreateBarRequest';
import UpdateContractInfoRequest from './requests/UpdateContractInfoRequest/UpdateContractInfoRequest';

const baseUrl = '/combo';

const ComboApplicationService = {
  findApplication: async (
    id: number,
    url: string,
  ): Promise<ComboApplication> => {
    const response = await Axios.get<ComboApplication>(`${url}/${id}`);
    return response.data;
  },
  updateApplication: async (
    id: number,
    payload: UpdateApplicationRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${id}`,
      payload,
    );
    return response.data;
  },
  updateSalesReps: async (
    id: number,
    payload: UpdateSalesRepsRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${id}/sales-reps`,
      payload,
    );
    return response.data;
  },
  updateOutletDetails: async (
    applicationId: number,
    payload: UpdateOutletDetailsRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${applicationId}/outlet`,
      payload,
    );
    return response.data;
  },
  updateOutletSales: async (
    applicationId: number,
    payload: UpdateOutletSalesRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${applicationId}/outlet-sales`,
      payload,
    );
    return response.data;
  },
  updateBillingDetails: async (
    id: number,
    payload: UpdateBillingDetailsRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${id}/billing-details`,
      payload,
    );
    return response.data;
  },
  updateOrderDetails: async (
    id: number,
    payload: UpdateOrderDetailsRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${id}/order-details`,
      payload,
    );
    return response.data;
  },

  updateAdminDetails: async (
    id: number,
    payload: UpdateAdminDetailsRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${id}/admin-details`,
      payload,
    );
    return response.data;
  },

  updateAdditionalInfo: async (
    id: number,
    payload: UpdateAdditionalInfoRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${id}/additional-info`,
      payload,
    );
    return response.data;
  },

  updateOffers: async (
    id: number,
    payload: UpdateOffersRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${id}/offers`,
      payload,
    );
    return response.data;
  },

  updateHeinekenDirect: async (
    applicationId: number,
    heinekenDirectId: number,
    payload: CreateHeinekenDirectRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${applicationId}/heineken-direct/${heinekenDirectId}`,
      payload,
    );
    return response.data;
  },

  listContactDetails: async (id: number, url: string): Promise<Contact[]> => {
    const response = await Axios.get<Contact[]>(`${url}/${id}/persons`);
    return response.data;
  },

  loadApplicationComments: async (id: number): Promise<Comment[]> => {
    const response = await Axios.get<Comment[]>(`${baseUrl}/${id}/notes`);
    return response.data;
  },
  loadApplicationMentionUsers: async (id: number): Promise<Mention[]> => {
    const response = await Axios.get<Mention[]>(`${baseUrl}/${id}/mentions`);
    return response.data;
  },

  createNote: async (
    id: number,
    payload: CreateNoteRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.post<ComboApplication>(
      `${baseUrl}/${id}/notes`,
      payload,
    );
    return response.data;
  },

  createApplicant: async (
    id: number,
    payload: ApplicantRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.post<ComboApplication>(
      `${baseUrl}/${id}/applicants`,
      payload,
    );
    return response.data;
  },
  deleteApplicant: async (
    applicationId: number,
    applicantId: number,
  ): Promise<ComboApplication> => {
    const response = await Axios.delete<ComboApplication>(
      `${baseUrl}/${applicationId}/applicants/${applicantId}`,
    );
    return response.data;
  },
  updateApplicant: async (
    applicationId: number,
    applicantId: number,
    payload: ApplicantRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${applicationId}/applicants/${applicantId}`,
      payload,
    );
    return response.data;
  },
  createHeinekenDirect: async (
    id: number,
    payload: CreateHeinekenDirectRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.post<ComboApplication>(
      `${baseUrl}/${id}/heineken-direct`,
      payload,
    );
    return response.data;
  },

  createNewApplication: async (
    payload: CreateNewApplicationRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.post<ComboApplication>(`${baseUrl}`, payload);
    return response.data;
  },

  deleteHeinekenDirect: async (
    applicationId: number,
    heinekenDirectId: number,
  ): Promise<ComboApplication> => {
    const response = await Axios.delete<ComboApplication>(
      `${baseUrl}/${applicationId}/heineken-direct/${heinekenDirectId}`,
    );
    return response.data;
  },

  submitApplication: async (
    applicationId: number,
    payload: SubmitApplicationRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${applicationId}/submit`,
      payload,
    );
    return response.data;
  },

  requestAccountNumbers: async (
    applicationId: number,
    payload: AccountNumbersRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${applicationId}/master-data`,
      payload,
    );
    return response.data;
  },
  paginateApplications: async (
    params: QueryParams,
    config?: AxiosRequestConfig,
  ): Promise<Pagination<ComboApplication>> => {
    const stringified = StringHelper.formatQueryParams(params);
    const response = await Axios.get<Pagination<ComboApplication>>(
      `${baseUrl}${stringified === '' ? '' : '?' + stringified}`,
      config,
    );
    return response.data;
  },
  completeApplication: async (
    applicationId: number,
    payload: CompleteApplicationRequest,
  ) => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${applicationId}/complete`,
      payload,
    );
    return response.data;
  },
  reopenApplication: async (applicationId: number) => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${applicationId}/reopen`,
    );
    return response.data;
  },
  deleteApplication: async (
    applicationId: number,
    payload: DeleteAppicationRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.delete<ComboApplication>(
      `${baseUrl}/${applicationId}`,
      { data: payload },
    );
    return response.data;
  },
  toggleApplication: async (
    applicationId: number,
    payload: ToggleApplicationRequest,
  ): Promise<Application | SDApplication> => {
    const response = await Axios.put<Application | SDApplication>(
      `${baseUrl}/${applicationId}/change`,
      payload,
    );
    return response.data;
  },

  updateInnserveDetails: async (
    applicationId: number,
    payload: InnserveDetailsRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${applicationId}/inserve`,
      payload,
    );
    return response.data;
  },

  updateContractInfo: async (
    applicationId: number,
    payload: UpdateContractInfoRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `/smart-dispense/${applicationId}/contract-info`,
      payload,
    );
    return response.data;
  },

  updateBar: async (
    applicationId: number,
    barId: number,
    payload: CreateBarRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `${baseUrl}/${applicationId}/outlet/bar/${barId}`,
      payload,
    );
    return response.data;
  },
  createBar: async (
    id: number,
    payload: CreateBarRequest,
  ): Promise<ComboApplication> => {
    const response = await Axios.post<ComboApplication>(
      `${baseUrl}/${id}/outlet/bar`,
      payload,
    );
    return response.data;
  },
  deleteBar: async (
    applicationId: number,
    barId: number,
  ): Promise<ComboApplication> => {
    const response = await Axios.delete<ComboApplication>(
      `${baseUrl}/${applicationId}/outlet/bar/${barId}`,
    );
    return response.data;
  },
};

export default ComboApplicationService;
