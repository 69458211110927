import Axios from 'services/Client';
import ApplicationHGSSAdditionalId from 'models/ApplicationHGSSAdditionalId';
import UpdateApplicationHGSSAdditionalIdRequest from './requests/UpdateApplicationHGSSAdditionalIdRequest';

const HGSSAdditionalIdService = {
  loadApplicationHGSSAdditionalIds: async (
    applicationId: number,
  ): Promise<ApplicationHGSSAdditionalId[]> => {
    const response = await Axios.get<ApplicationHGSSAdditionalId[]>(
      `/applications/${applicationId}/additional_id`,
    );
    return response.data;
  },
  updateApplicationHGSSAdditionalIds: async (
    applicationId: number,
    payload: UpdateApplicationHGSSAdditionalIdRequest,
  ): Promise<ApplicationHGSSAdditionalId[]> => {
    const response = await Axios.post<ApplicationHGSSAdditionalId[]>(
      `/applications/${applicationId}/additional_id`,
      payload,
    );
    return response.data;
  },
  loadApplicationHGSSAdditionalIdDocument: async (
    applicationId: number,
    applicantId: number,
  ): Promise<string> => {
    const response = await Axios.get<{ url: string }>(
      `/applications/${applicationId}/additional_id/${applicantId}`,
    );
    return response.data.url;
  },
};

export default HGSSAdditionalIdService; 