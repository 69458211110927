import Axios from 'services/Client';
import OutletSegment from 'models/OutletSegment';

const OutletSegmentService = {
  listOutletSegments: async (): Promise<OutletSegment[]> => {
    const response = await Axios.get<OutletSegment[]>('/outlet-segments');
    return response.data;
  },
};

export default OutletSegmentService;
