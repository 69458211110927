import Axios from 'services/Client';
import SDApplication from 'models/SDApplication';

const SDSigningService = {
  createSigningRequest: async (
    applicationId: number,
    applicantId: number,
  ): Promise<SDApplication> => {
    const response = await Axios.get<SDApplication>(
      `/smart-dispense/${applicationId}/applicants/${applicantId}/sign`,
    );
    return response.data;
  },
  viewSigningUrl: async (
    applicationId: number,
    applicantId: number,
  ): Promise<{ url: string }> => {
    const response = await Axios.get<{ url: string }>(
      `/smart-dispense/${applicationId}/applicants/${applicantId}/sign-url`,
    );
    return response.data;
  },
};

export default SDSigningService;