import Axios from '../Client';
import Enquiry from 'models/Enquiry';
import Pagination from 'models/Pagination';
import Comment from 'models/Comment';
import Mention from 'models/Mention';
import { AssignEnquiryRequest } from 'services/EnquiryService/requests/AssignEnquiryRequest';
import { StatusReasonRequest } from 'services/EnquiryService/requests/StatusReasonRequest';
import { UpdateEnquiryRequest } from 'services/EnquiryService/requests/UpdateEnquiryRequest';
import StringHelper from 'utils/StringHelper';
import QueryParams from 'models/QueryParams';
import { AxiosRequestConfig } from 'axios';
import { CreateEnquiryNoteRequest } from './requests/CreateEnquiryNoteRequest';

export const EnquiryService = {
  paginateEnquiries: async (
    params: QueryParams,
    config?: AxiosRequestConfig,
  ): Promise<Pagination<Enquiry>> => {
    const stringified = StringHelper.formatQueryParams(params);
    const response = await Axios.get<Pagination<Enquiry>>(
      `/enquiries${stringified === '' ? '' : '?' + stringified}`,
      config,
    );
    return response.data;
  },

  approveEnquiry: async (id: number): Promise<Enquiry> => {
    const response = await Axios.post<Enquiry>(`/enquiries/${id}/approve`);
    return response.data;
  },

  assignEnquiry: async (
    id: number,
    payload: AssignEnquiryRequest,
  ): Promise<Enquiry> => {
    const response = await Axios.put<Enquiry>(
      `/enquiries/${id}/sales-posts`,
      payload,
    );
    return response.data;
  },

  onHoldEnquiry: async (
    id: number,
    payload: StatusReasonRequest,
  ): Promise<Enquiry> => {
    const response = await Axios.post<Enquiry>(
      `/enquiries/${id}/on-hold`,
      payload,
    );
    return response.data;
  },

  findEnquiry: async (id: number): Promise<Enquiry> => {
    const response = await Axios.get<Enquiry>(`/enquiries/${id}`);
    return response.data;
  },

  loadEnquiryComments: async (id: number): Promise<Comment[]> => {
    const response = await Axios.get<Comment[]>(`/enquiries/${id}/comments`);
    return response.data;
  },
  loadEnquiryMentionUsers: async (id: number): Promise<Mention[]> => {
    const response = await Axios.get<Mention[]>(`/enquiries/${id}/mentions`);
    return response.data;
  },

  rejectEnquiry: async (
    id: number,
    payload: StatusReasonRequest,
  ): Promise<Enquiry> => {
    const response = await Axios.post<Enquiry>(
      `/enquiries/${id}/reject`,
      payload,
    );
    return response.data;
  },

  updateEnquiry: async (
    id: number,
    payload: UpdateEnquiryRequest,
  ): Promise<Enquiry> => {
    const response = await Axios.put<Enquiry>(`/enquiries/${id}`, payload);
    return response.data;
  },
  createEnquiryNote: async (
    id: number,
    payload: CreateEnquiryNoteRequest,
  ): Promise<Enquiry> => {
    const response = await Axios.post<Enquiry>(
      `/enquiries/${id}/note`,
      payload,
    );
    return response.data;
  },
};
