import Axios from '../Client';
import KeyContact from 'models/KeyContact';

const KeyContactService =  {
  listKeyContacts: async (): Promise<KeyContact[]> => {
    const response = await Axios.get<KeyContact[]>(`/key-contacts`);
    return response.data;
  },
};

export default KeyContactService;
