import React, { lazy, Suspense } from 'react';
import { Loader } from 'components/App/App';
import { Switch, Redirect } from 'react-router-dom';
import Route from 'components/common/PrivateRoute';
import { SidebarWrapper } from 'components/common/SidebarWrapper';
import { Provider as SDEnquiryProvider } from 'contexts/SDEnquiryContext';
import { Provider as SDEnquiryDetailProvider } from 'contexts/SDEnquiryDetailContext';

export const routeBase = '/smart-dispense/enquiries';

const SDEnquiriesList = lazy(
  () => import('pages/SmartDispenseEnquiries/SmartDispenseEnquiriesList'),
);

//change it when enquiries detail ready
const SDEnquiriesDetails = lazy(
  () => import('pages/SmartDispenseEnquiries/SDEnquiryDetail'),
);

const EnquiriesIndex: React.FC = () => {
  return (
    <SidebarWrapper>
      <SDEnquiryProvider>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route
              path={routeBase}
              exact
              component={SDEnquiriesList}
              authenticated={true}
            />
            <SDEnquiryDetailProvider>
              <Route
                path={`${routeBase}/:id`}
                exact
                component={SDEnquiriesDetails}
                authenticated={true}
              />
            </SDEnquiryDetailProvider>
            <Redirect from="*" to={routeBase} />
          </Switch>
        </Suspense>
      </SDEnquiryProvider>
    </SidebarWrapper>
  );
};

export default EnquiriesIndex;
