import React from 'react';
import styled from 'styled-components';
import { Spinner as BPSpinner } from '@blueprintjs/core';
import theme from 'theme';

const SpinnerContainer = styled.div`
  .bp3-spinner-head {
    stroke: ${theme.background.sidebar};
  }
  &.white {
    .bp3-spinner-head {
      stroke: white;
    }
  }
`;

interface Props {
  appearance?: 'white' | '' | null;
  size?: number;
}

const Spinner: React.FC<Props> = (props) => {
  const { size, appearance } = props;
  return (
    <SpinnerContainer className={`spinner ${appearance ? appearance : ''}`}>
      <BPSpinner size={size || 40}></BPSpinner>
    </SpinnerContainer>
  );
};

export default Spinner;
