import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Route from 'components/common/PrivateRoute';
import { routeBase as enquiryRouteBase } from 'pages/Enquiries';
import { routeBase as sdEnquiryRouteBase } from 'pages/SmartDispenseEnquiries';
import { routeBase as applicationRouteBase } from 'pages/Applications';
import { routeBase as smartDispenseRouteBase } from 'pages/SmartDispenseApplications';
import AuthHelper from 'utils/AuthHelper';
import { Provider } from 'contexts/GlobalContext';
import Spinner from 'components/common/Spinner';
import Enquiries from 'pages/Enquiries';
import SDEnquiries from 'pages/SmartDispenseEnquiries';
import Applications from 'pages/Applications';
import SmartDispense from 'pages/SmartDispenseApplications';

// Lazy load pages
const Login = lazy(() => import(/* webpackChunkName: "login" */ 'pages/Login'));
const ForgottenPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "login" */ 'pages/ResetPassword/ForgottenPassword'
    ),
);
const SetNewPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "login" */ 'pages/ResetPassword/SetNewPassword'
    ),
);

const Container = styled.div`
  background-color: ${theme.background.default};
`;

const LoaderContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = () => (
  <LoaderContainer>
    <Spinner />
  </LoaderContainer>
);

const App: React.FC = () => {
  return (
    <Provider>
      <Container>
        <Router>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                path={enquiryRouteBase}
                component={Enquiries}
                authenticated={true}
              />
              <Route
                path={sdEnquiryRouteBase}
                component={SDEnquiries}
                authenticated={true}
              />
              <Route
                path={applicationRouteBase}
                component={Applications}
                authenticated={true}
              />
              <Route
                path={smartDispenseRouteBase}
                component={SmartDispense}
                authenticated={true}
              />
              <Route path="/" exact component={Login} authenticated={false} />
              <Route
                path="/forgotten-password"
                exact
                component={ForgottenPassword}
                authenticated={false}
              />
              <Route
                path="/password-reset/:token"
                exact
                component={SetNewPassword}
                authenticated={false}
              />
              <Redirect from="*" to={AuthHelper.getDefaultRoute()} />
            </Switch>
          </Suspense>
        </Router>
      </Container>
    </Provider>
  );
};

export default App;
