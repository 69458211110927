import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthHelper from 'utils/AuthHelper';

interface Props {
  component: any;
  authenticated: boolean;
  path: string;
  [x: string]: any;
}

function PrivateRoute({
  component: Component,
  authenticated,
  path,
  ...other
}: Props) {
  return (
    <Route
      path={path}
      render={() => {
        return AuthHelper.isAuthenticated() === authenticated ? (
          <Component {...other} />
        ) : (
          <Redirect to={AuthHelper.getDefaultRoute()} />
        );
      }}
    />
  );
}

export default PrivateRoute;
