import Axios from '../Client';
import StatusReason from 'models/StatusReason';

const StatusReasonService = {
  listStatusReasons: async (status: string): Promise<StatusReason[]> => {
    const response = await Axios.get<StatusReason[]>(
      `/status-reasons?type=${status}`,
    );
    return response.data;
  },
};

export default StatusReasonService;