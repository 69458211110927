import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from 'components/common/PrivateRoute';
import { Loader } from 'components/App/App';
import { ApplicationDetailProvider } from 'contexts/ApplicationDetailContext';
import { ApplicationManagementProvider } from 'contexts/ApplicationManagementContext';
import { Provider as ApplicationProvider } from 'contexts/ApplicationContext';
import { Provider as BarLayoutProvider } from 'contexts/BarLayoutContext';
import { SidebarWrapper } from 'components/common/SidebarWrapper';
import { ComboApplicationDetailProvider } from 'contexts/ComboApplicationDetailContext';
import { ComboApplicationManagementProvider } from 'contexts/ComboApplicationManagementContext';

export const routeBase = '/applications';

const ApplicationsList = lazy(
  () =>
    import(
      /* webpackChunkName: "applications-detail" */ 'pages/Applications/ApplicationsList'
    ),
);

const ApplicationDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "applications-detail" */ 'pages/Applications/ApplicationDetail'
    ),
);

const ComboApplicationDetails = lazy(
  () => import('pages/ComboApplications/ComboApplicationDetails'),
);

const ApplicationsIndex: React.FC = () => {
  return (
    <SidebarWrapper>
      <ApplicationProvider>
        <BarLayoutProvider>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                path={routeBase}
                exact
                component={ApplicationsList}
                authenticated={true}
              />

              <Route
                path={`${routeBase}/combo/:id`}
                exact
                component={() => (
                  <ComboApplicationDetailProvider>
                    <ComboApplicationManagementProvider>
                      <ComboApplicationDetails />
                    </ComboApplicationManagementProvider>
                  </ComboApplicationDetailProvider>
                )}
                authenticated={true}
              />

              <Route
                path={`${routeBase}/:id`}
                component={() => (
                  <ApplicationDetailProvider>
                    <ApplicationManagementProvider>
                      <ApplicationDetail />
                    </ApplicationManagementProvider>
                  </ApplicationDetailProvider>
                )}
                authenticated={true}
              />

              {/* TODO: refactor the route base once the applications list view has
        been implemented */}
              <Redirect from="*" to={routeBase} />
            </Switch>
          </Suspense>
        </BarLayoutProvider>
      </ApplicationProvider>
    </SidebarWrapper>
  );
};

export default ApplicationsIndex;
