import Axios from 'axios';
import AuthHelper from 'utils/AuthHelper';
// import AuthService from 'services/AuthService';
// import PermissionsHelper from 'utils/PermissionsHelper';
// import config from 'utils/ActiveDirectoryHelper';
// import * as Msal from 'msal';

// const msalInstance = new Msal.UserAgentApplication(config);

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.request.use(async (config) => {
  let token = null;

  // FIXME: HP-151 P1 production issue workaround.
  //
  // *** TEMP FIX ***
  // Active Directory login disabled to avoid getting stuck in failed login loop.
  // No AD users available to test locally with, so having to fix blind and push to
  // Client staging to test if login works.
  //
  // Accepting this may create a (lower priority) bug around email links. Fixing the P1 issue is the priority.

  // const ssoRequestConfig = {
  //   loginHint: AuthHelper.getAuthUser(),
  //   cache: {
  //     cacheLocation: 'localStorage',
  //   },
  // };
  //
  // if (AuthHelper.getAuthType() === 'active_directory') {
  //   const tokenResponse = await msalInstance
  //     .ssoSilent(ssoRequestConfig)
  //     .then((response) => {
  //       return response.idToken.rawIdToken;
  //     })
  //     .catch(async (error) => {
  //       // handle error by invoking an interactive login method
  //       console.log(error); //adding this in for trouble shooting on dev environment
  //       await msalInstance
  //         .loginPopup(ssoRequestConfig)
  //         .then(async (response) => {
  //           const { user } = await AuthService.login({
  //             token: response.idToken.rawIdToken,
  //           });
  //           AuthHelper.setAuthUser(response.uniqueId);
  //           AuthHelper.setAuthType('active_directory');
  //           PermissionsHelper.setPermissions(user.permissions);
  //           return response.idToken.rawIdToken;
  //         });
  //     });
  //   config.headers.Authorization = `Bearer ${tokenResponse}`;
  //   console.log('AD login', tokenResponse); //adding this in for trouble shooting on dev environment
  // } else {
  //   token = AuthHelper.getToken();
  //   if (token != null) {
  //     config.headers.Authorization = `Bearer ${token}`;
  //   }
  // }

  token = AuthHelper.getToken();
  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default axios;
