import React from 'react';
import styled from 'styled-components';
import { Button as BPButton } from '@blueprintjs/core';
import theme from 'theme';

const ButtonContainer = styled.div`
  .bp3-button {
    height: 100%;
    &:disabled {
      opacity: 0.6;
    }
    .bp3-icon {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  .bp3-intent-primary {
    box-shadow: inset 0 0 0 1px rgba(0, 157, 0, 0.2),
      inset 0 -1px 1px 0 rgba(67, 90, 111, 0.2) !important;
    background-image: linear-gradient(
      to top,
      ${theme.background.buttonPrimary},
      ${theme.background.buttonPrimary}
    ) !important;
    &:not([disabled]):hover {
      background-image: linear-gradient(to bottom, #439809, #4ca212) !important;
    }
  }
  .bp3-intent-success {
    box-shadow: inset 0 0 0 1px rgba(23, 54, 39, 0.2),
      inset 0 -1px 1px 0 rgba(67, 90, 111, 0.14) !important;
    background-image: linear-gradient(to bottom, #4eb209, #439809) !important;
    &:not([disabled]):hover {
      background-image: linear-gradient(to bottom, #54c109, #4ca212) !important;
    }
  }
  .bp3-intent-danger {
    box-shadow: inset 0 0 0 1px rgba(23, 54, 39, 0.2),
      inset 0 -1px 1px 0 rgba(67, 90, 111, 0.14) !important;
    background-image: linear-gradient(to bottom, #ec4c47, #d64540);
    &:not([disabled]):hover {
      background-image: linear-gradient(to bottom, #f3534e, #e44944);
    }
  }
  .add-button {
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px #7ab801,
      inset 0 -1px 1px 0 rgba(67, 90, 111, 0.09);
    background-color: #ffffff;
    color: #7ab801;
    .bp3-icon {
      color: #7ab801;
    }
    &:hover {
      color: #6da204;
      .bp3-icon {
        color: #6da204;
      }
    }
  }
  .bp3-loading {
    .bp3-spinner {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`;

interface Props {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  icon?: any;
  rightIcon?: any;
  appearance?: '' | 'primary' | 'success' | 'danger' | 'none';
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent) => void;
}

const Button: React.FC<Props> = (props) => {
  const {
    className,
    appearance,
    type,
    disabled,
    onClick,
    children,
    icon,
    rightIcon,
    loading,
  } = props;

  const appearanceClass =
    appearance === 'primary'
      ? 'bp3-intent-primary'
      : appearance === 'success'
      ? 'bp3-intent-success'
      : appearance === 'danger'
      ? 'bp3-intent-danger'
      : '';

  return (
    <ButtonContainer className="button-container">
      <BPButton
        type={type || 'button'}
        onClick={onClick}
        disabled={disabled}
        icon={icon || false}
        rightIcon={rightIcon || false}
        loading={loading}
        className={`${appearanceClass} ${className ? className : ''}`}
        minimal={appearance === 'none'}
      >
        {children}
      </BPButton>
    </ButtonContainer>
  );
};

export default Button;
