const theme = {
  text: {
    heading: '#000',
    body: '#0c1826',
    body2: '#4a4a4a',
    secondary: '#7a7a7a',
    tertiary: '#4a4a4a',
    disabled: 'rgba(112, 112, 112, 0.31)',
    input: '#222222',
    link: '#0055b0',
  },
  background: {
    hr: '#e7e7e7',
    card: '#fbfbfb',
    default: '#f2f3f4',
    defaultAlt: '#f9f9f9',
    defaultPanel: '#cee1f4',
    defaultNavA: 'rgba(249, 249, 249, 0.94)',
    defaultNavB: 'rgba(249, 249, 249, 0.94)',
    defaultCheckbox: '#eeeeef',
    defaultRadio: '#222224',
    button: '#d2d9ec',
    buttonPrimary: '#009d00',
    sidebar: '#004e23',
    focus: 'rgba(0, 157, 0, 0.6)',
  },
  cta: {
    primary: '#439809',
    primaryLight: '#a8d91b',
    primaryPunchy: '#79bc1d',
    primaryDark: '#006e31',
    secondary: '#26386b',
    secondaryLight: '#26386b',
    secondaryPunchy: '#040b34',
    secondaryDark: '#01041c',
    tertiary: '#6854c0',
    active: '#009d00',
  },
  status: {
    success: '#79bc1d',
    successPale: '#97d02c',
    successLight: '#b0e238',
    successDark: '#317f00',
    warning: '#fd7306',
    warningPale: '#ffe199',
    warningLight: '#febe0f',
    warningDark: '#d26006',
    danger: '#ff4d39',
    dangerPale: '#ff775c',
    dangerLight: '#ff7b4e',
    dangerDark: '#cc0500',
    new: '#4761AC',
    assigned: '#ff9440',
  },
};

export default theme;