import Axios from 'services/Client';
import Cluster from 'models/Cluster';

const ClusterService = {
  listClusters: async (): Promise<Cluster[]> => {
    const response = await Axios.get<Cluster[]>(`/clusters`);
    return response.data;
  },
};

export default ClusterService;