import React, { useState, useEffect, createContext } from 'react';
import axios, { AxiosResponse } from 'axios';
import SupplierService from 'services/SupplierService';
import RegionService from 'services/RegionService';
import BusinessTypeService from 'services/BusinessTypeService';
import BrandService from 'services/BrandService';
import AttractionService from 'services/AttractionService';
import InnovativeSolutionService from 'services/InnovativeSolutionService';
import FindOutService from 'services/FindOutService';
import HeinekenService from 'services/HeinekenCustomerService';
import Region from 'models/Region';
import Supplier from 'models/Supplier';
import Permissions from 'models/Permissions';
import BusinessType from 'models/BusinessType';
import Brand from 'models/Brand';
import Attraction from 'models/Attraction';
import InnovativeSolution from 'models/InnovativeSolution';
import FindOut from 'models/FindOut';
import HeinekenCustomer from 'models/HeinekenCustomer';
import AuthHelper from 'utils/AuthHelper';
import PermissionsHelper from 'utils/PermissionsHelper';
import Client from 'services/Client';
import showToast from 'components/common/Toast';

interface GlobalContextInterface {
  initialised: boolean;
  error: boolean;
  permissionsError: boolean;
  loading: boolean;
  authenticated: boolean;
  permissions: Permissions | null;
  loaded: boolean;
  suppliers: Supplier[];
  regions: Region[];
  businessTypes: BusinessType[];
  brands: Brand[];
  attractions: Attraction[];
  innovativeSolutions: InnovativeSolution[];
  findOuts: FindOut[];
  heinekenCustomers: HeinekenCustomer[];
  successLogout: boolean;
  loadStaticLists: () => void;
  setAuthenticated: (val: boolean) => void;
  setSuccessLogout: (val: boolean) => void;
  setPermissions: (val: Permissions | null) => void;
}

const GlobalContext = createContext<GlobalContextInterface>({
  initialised: false,
  error: false,
  permissionsError: false,
  loading: false,
  authenticated: AuthHelper.isAuthenticated(),
  loaded: false,
  permissions: PermissionsHelper.getPermissions(),
  suppliers: [],
  regions: [],
  businessTypes: [],
  brands: [],
  attractions: [],
  innovativeSolutions: [],
  findOuts: [],
  heinekenCustomers: [],

  successLogout: false,
  loadStaticLists: () => null,
  setAuthenticated: (val: boolean) => null,
  setSuccessLogout: (val: boolean) => null,
  setPermissions: (val: Permissions | null) => null,
});

export const Consume: () => GlobalContextInterface = () => {
  const consumer = React.useContext(GlobalContext);
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [error, setError] = useState<boolean>(false);
  const [permissionsError, setPermissionsError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(
    AuthHelper.isAuthenticated(),
  );
  const [successLogout, setSuccessLogout] = useState<boolean>(false);
  // Loaded flag used to determine if the global static lists have been loaded
  const [loaded, setLoaded] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [attractions, setAttractions] = useState<Attraction[]>([]);
  const [innovativeSolutions, setInnovativeSolutions] = useState<
    InnovativeSolution[]
  >([]);
  const [findOuts, setFindOuts] = useState<FindOut[]>([]);
  const [heinekenCustomers, setHeinekenCustomers] = useState<
    HeinekenCustomer[]
  >([]);

  const [permissions, setPermissions] = useState<Permissions | null>(
    PermissionsHelper.getPermissions(),
  );

  const loadStaticLists = async () => {
    try {
      setLoading(true);
      const [
        suppliers,
        regions,
        businessTypes,
        brands,
        attractions,
        innovativeSolutions,
        findOuts,
        heinekenCustomers,
      ] = (await axios.all([
        SupplierService.listSuppliers(),
        RegionService.listRegions(),
        BusinessTypeService.listBusinessTypes(),
        BrandService.listBrands(),
        AttractionService.listAttractions(),
        InnovativeSolutionService.listInnovativeSolutions(),
        FindOutService.listFindOuts(),
        HeinekenService.listHeinekenCustomers(),
      ])) as [
        Supplier[],
        Region[],
        BusinessType[],
        Brand[],
        Attraction[],
        InnovativeSolution[],
        FindOut[],
        HeinekenCustomer[],
      ];
      setSuppliers(suppliers);
      setRegions(regions);
      setBusinessTypes(businessTypes);
      setBrands(brands);
      setAttractions(attractions);
      setInnovativeSolutions(innovativeSolutions);
      setFindOuts(findOuts);
      setHeinekenCustomers(heinekenCustomers);
      setLoaded(true);
    } catch (e) {
      setError(true);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isLogoutEndpoint = (response: AxiosResponse): boolean => {
      return (
        response.config.method === 'delete' &&
        response.config.url === `${process.env.REACT_APP_API_URL}/auth`
      );
    };

    Client.interceptors.response.use(
      (response) => response,
      (err) => {
        // Dont automatically logout if the user is trying to hit the logout endpoint, receiving a 401
        if (
          err.response !== undefined &&
          AuthHelper.isAuthenticated() &&
          !isLogoutEndpoint(err.response) &&
          err.response.status === 401
        ) {
          AuthHelper.removeAuth();
          setAuthenticated(false);
          setSuccessLogout(true);
          setPermissions(null);
          showToast({
            message: 'You have been automatically logged out!',
            type: 'warning',
          });
        }
        return Promise.reject(err);
      },
    );
    Client.interceptors.response.use(
      (response) => response,
      (err) => {
        // Set Error Type if 403 permissions error
        if (
          err.response !== undefined &&
          AuthHelper.isAuthenticated() &&
          err.response.status === 403
        ) {
          setPermissionsError(true);
        }
        return Promise.reject(err);
      },
    );
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        initialised: true,
        error,
        permissionsError,
        loading,
        authenticated,
        loaded,
        permissions,
        suppliers,
        regions,
        businessTypes,
        brands,
        attractions,
        innovativeSolutions,
        findOuts,
        heinekenCustomers,
        successLogout,
        loadStaticLists,
        setAuthenticated,
        setSuccessLogout,
        setPermissions,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
