import Axios from 'services/Client';
import PricingSector from 'models/PricingSector';

const PricingSectorService =  {
  listPricingSectors: async (): Promise<PricingSector[]> => {
    const response = await Axios.get<PricingSector[]>('/pricing-sectors');
    return response.data;
  },
};

export default PricingSectorService;