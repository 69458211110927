import { Position, Toaster, Intent } from '@blueprintjs/core';
import './Toast.scss';

type Type = 'error' | 'warning' | undefined;

interface Props {
  type?: Type;
  message: string;
}

const Toast = Toaster.create({
  className: 'toast',
  position: Position.TOP,
});

const show = (props: Props) => {
  const { type, message } = props;

  let intent: Intent = Intent.NONE;

  switch (type) {
    case 'error':
      intent = Intent.DANGER;
      break;
    case 'warning':
      intent = Intent.WARNING;
      break;

    default:
      intent = Intent.SUCCESS;
      break;
  }
  Toast.show({
    message,
    intent,
    timeout: 3000,
  });
};
export default show;
