import React, { createContext, useState } from 'react';
import SDEnquiry from 'models/SDEnquiry';
import Comment from 'models/Comment';
import Mention from 'models/Mention';
import SDEnquiryService from 'services/SDEnquiryServices';
import CreateSDEnquiryNoteRequest from 'services/SDEnquiryServices/requests/CreateSDEnquiryNoteRequest';

interface SDEnquiryDetailContextInterface {
  initialised: boolean;
  error: Error;
  loading: Loading;
  currentEnquiry: SDEnquiry | null;
  setCurrentEnquiry: (enquiry: SDEnquiry | null) => void;
  findEnquiry: (id: number) => Promise<SDEnquiry | undefined> | null;
  enquiryComments: Comment[];
  loadEnquiryComments: (id: number) => Promise<Comment[]> | null;
  createEnquiryNote: (
    id: number,
    payload: CreateSDEnquiryNoteRequest,
  ) => Promise<SDEnquiry | undefined> | null;
  enquiryMentionUsers: Mention[];
  loadEnquiryMentionUsers: (id: number) => Promise<Mention[]> | null;
}

interface Loading {
  enquiryFound: boolean;
  loadEnquiryComments: boolean;
  createEnquiryNote: boolean;
  loadEnquiryMentionUsers: boolean;
}

interface Error {
  enquiryFound: boolean;
  loadEnquiryComments: boolean;
  createEnquiryNote: boolean;
  loadEnquiryMentionUsers: boolean;
}

const SDEnquiryDetailContext = createContext<SDEnquiryDetailContextInterface>({
  initialised: false,
  error: {
    enquiryFound: false,
    loadEnquiryComments: false,
    createEnquiryNote: false,
    loadEnquiryMentionUsers: false,
  },
  loading: {
    enquiryFound: true,
    loadEnquiryComments: true,
    createEnquiryNote: false,
    loadEnquiryMentionUsers: true,
  },
  enquiryComments: [],
  currentEnquiry: null,
  setCurrentEnquiry: (enquiry: SDEnquiry | null) => null,
  findEnquiry: (id: number) => null,
  loadEnquiryComments: (id: number) => null,
  createEnquiryNote: (id: number, payload: CreateSDEnquiryNoteRequest) => null,
  enquiryMentionUsers: [],
  loadEnquiryMentionUsers: (id: number) => null,
});

export const Consume: () => SDEnquiryDetailContextInterface = () => {
  const consumer = React.useContext(SDEnquiryDetailContext);

  if (consumer.initialised === false) {
    throw new Error('EnquiryContextProvider not initialised');
  }
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [error, setError] = useState<Error>({
    enquiryFound: false,
    loadEnquiryComments: false,
    createEnquiryNote: false,
    loadEnquiryMentionUsers: false,
  });
  const [loading, setLoading] = useState<Loading>({
    enquiryFound: true,
    loadEnquiryComments: false,
    createEnquiryNote: false,
    loadEnquiryMentionUsers: false,
  });

  const [currentEnquiry, setCurrentEnquiry] = useState<SDEnquiry | null>(null);
  const [enquiryComments, setEnquiryComments] = useState<Comment[]>([]);
  const [enquiryMentionUsers, setEnquiryMentionUsers] = useState<Mention[]>([]);

  const findEnquiry = async (id: number): Promise<SDEnquiry | undefined> => {
    try {
      setError({ ...error, enquiryFound: false });
      setLoading({ ...loading, enquiryFound: true });
      const enquiry = await SDEnquiryService.findEnquiry(id);
      setCurrentEnquiry(enquiry);
      return enquiry;
    } catch (e) {
      setError({ ...error, enquiryFound: true });
      throw e;
    } finally {
      setLoading({ ...loading, enquiryFound: false });
    }
  };

  const loadEnquiryComments = async (id: number): Promise<Comment[]> => {
    try {
      setError({ ...error, loadEnquiryComments: false });
      setLoading({ ...loading, loadEnquiryComments: true });
      const comments = await SDEnquiryService.loadEnquiryComments(id);
      setEnquiryComments(comments);
      return comments;
    } catch (e) {
      setError({ ...error, loadEnquiryComments: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadEnquiryComments: false });
    }
  };

  const createEnquiryNote = async (
    id: number,
    payload: CreateSDEnquiryNoteRequest,
  ): Promise<SDEnquiry | undefined> => {
    try {
      setError({ ...error, createEnquiryNote: false });
      setLoading({ ...loading, createEnquiryNote: true });
      await SDEnquiryService.createEnquiryNote(id, payload);
      const enquiry = await SDEnquiryService.findEnquiry(id);
      setCurrentEnquiry(enquiry);
      return enquiry;
    } catch (e) {
      setError({ ...error, createEnquiryNote: true });
      throw e;
    } finally {
      setLoading({ ...loading, createEnquiryNote: false });
    }
  };

  const loadEnquiryMentionUsers = async (id: number): Promise<Mention[]> => {
    try {
      setError({ ...error, loadEnquiryMentionUsers: false });
      setLoading({ ...loading, loadEnquiryMentionUsers: true });
      const mentions = await SDEnquiryService.loadEnquiryMentionUsers(id);
      setEnquiryMentionUsers(mentions);
      return mentions;
    } catch (e) {
      setError({ ...error, loadEnquiryMentionUsers: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadEnquiryMentionUsers: false });
    }
  };

  return (
    <SDEnquiryDetailContext.Provider
      value={{
        initialised: true,
        error,
        loading,
        currentEnquiry,
        setCurrentEnquiry,
        findEnquiry,
        enquiryComments,
        loadEnquiryComments,
        createEnquiryNote,
        enquiryMentionUsers,
        loadEnquiryMentionUsers,
      }}
    >
      {children}
    </SDEnquiryDetailContext.Provider>
  );
};
