import Permissions from 'models/Permissions';

const STORAGE_TOKEN_KEY = 'permissions_object';

export default class PermsissionsHelper {
  static getPermissions(): Permissions | null {
    const stored = window.localStorage.getItem(STORAGE_TOKEN_KEY);
    if (stored) {
      return JSON.parse(stored);
    }
    return null;
  }

  static setPermissions(permissions: Permissions): void {
    window.localStorage.setItem(STORAGE_TOKEN_KEY, JSON.stringify(permissions));
  }

  static removePermissions(): void {
    window.localStorage.removeItem(STORAGE_TOKEN_KEY);
  }

  static hasPermission(
    permissions: Permissions | null,
    section: string,
    required: string,
  ): boolean {
    return permissions ? permissions[section].includes(required) : false;
  }
}
