import React, { lazy, Suspense } from 'react';
import { Loader } from 'components/App/App';
import { Switch, Redirect } from 'react-router-dom';
import Route from 'components/common/PrivateRoute';
import { Provider as EnquiryProvider } from 'contexts/EnquiryContext';
import { Provider as EnquiryDetailProvider } from 'contexts/EnquiryDetailContext';
import { SidebarWrapper } from 'components/common/SidebarWrapper';

export const routeBase = '/enquiries';

// Lazy load pages
const EnquiriesList = lazy(
  () =>
    import(
      /* webpackChunkName: "enquiries-list" */ 'pages/Enquiries/EnquiriesList'
    ),
);
const EnquiryDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "enquiries-detail" */ 'pages/Enquiries/EnquiryDetail'
    ),
);

const EnquiriesIndex: React.FC = () => {
  return (
    <SidebarWrapper>
      <EnquiryProvider>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route
              path={routeBase}
              exact
              component={EnquiriesList}
              authenticated={true}
            />
            <EnquiryDetailProvider>
              <Route
                path={`${routeBase}/:id`}
                exact
                component={EnquiryDetail}
                authenticated={true}
              />
            </EnquiryDetailProvider>
            <Redirect from="*" to={routeBase} />
          </Switch>
        </Suspense>
      </EnquiryProvider>
    </SidebarWrapper>
  );
};

export default EnquiriesIndex;
