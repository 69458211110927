import React, { useState, createContext } from 'react';
import ComboApplicationManagementService from 'services/ComboApplicationManagementService';
import ComboHGSSAdminService from 'services/ComboHGSSAdminService';
import ComboHGSSAdditionalIdService from 'services/ComboHGSSAdditionalIdService';
import ComboHGSSPersonalGuaranteeService from 'services/ComboHGSSPersonalGuaranteeService';
import ApplicationMilestones from 'models/ApplicationMilestones';
import ApplicationHGSSAdmin from 'models/ApplicationHGSSAdmin';
import { ApplicationHGSSDirectDebit } from 'models/ApplicationHGSSDirectDebit';
import ApplicationHGSSAdditionalId from 'models/ApplicationHGSSAdditionalId';
import ApplicationHGSSPersonalGuarantee from 'models/ApplicationHGSSPersonalGuarantee';
import { UpdateApplicationHGSSDirectDebitRequest } from 'services/ComboHGSSDirectDebitService/requests/UpdateApplicationHGSSDirectDebitRequest';
import UpdateApplicationTaskRequest from 'services/ComboApplicationManagementService/requests/UpdateApplicationTaskRequest';
import UpdateApplicationFlagRequest from 'services/ComboApplicationManagementService/requests/UpdateApplicationFlagRequest';
import UpdateApplicationKeyDateRequest from 'services/ComboApplicationManagementService/requests/UpdateApplicationKeyDateRequest';
import UpdateApplicationHGSSAdditionalIdRequest from 'services/ComboHGSSAdditionalIdService/requests/UpdateApplicationHGSSAdditionalIdRequest';
import UpdateApplicationHGSSPersonalGuaranteeRequest from 'services/ComboHGSSPersonalGuaranteeService/requests/UpdateApplicationHGSSPersonalGuaranteeRequest';
import { HGSSDirectDebitService } from 'services/ComboHGSSDirectDebitService';

interface ComboApplicationManagementInterface {
  initialised: boolean;
  error: NetworkState;
  loading: NetworkState;
  milestones: ApplicationMilestones | null;
  hgssAdmin: ApplicationHGSSAdmin | null;
  additionalIds: ApplicationHGSSAdditionalId[] | null;
  directDebitContacts: ApplicationHGSSDirectDebit[] | null;
  personalGuarantees: ApplicationHGSSPersonalGuarantee[] | null;
  loadMilestones: (applicationId: number) => void;
  loadHGSSAdmin: (applicationId: number) => void;
  loadApplicationHGSSAdditionalIds: (applicationId: number) => void;
  loadApplicationHGSSPersonalGuarantees: (applicationId: number) => void;
  loadApplicationHGSSDirectDebit: (applicationId: number) => void;
  updateTask: (
    applicationId: number,
    taskId: number,
    payload: UpdateApplicationTaskRequest,
  ) => void;
  updateFlag: (
    applicationId: number,
    flagId: number,
    payload: UpdateApplicationFlagRequest,
  ) => void;
  updateKeyDate: (
    applicationId: number,
    keyDateId: number,
    payload: UpdateApplicationKeyDateRequest,
  ) => void;
  updateHGSSAdmin: (
    applicationId: number,
    payload: ApplicationHGSSAdmin,
  ) => void;
  updateApplicationHGSSAdditionalIds: (
    applicationId: number,
    payload: UpdateApplicationHGSSAdditionalIdRequest,
  ) => void;
  updateApplicationHGSSPersonalGuarantees: (
    applicationId: number,
    payload: UpdateApplicationHGSSPersonalGuaranteeRequest,
  ) => void;
  updateApplicationHGSSDirectDebit: (
    applicationId: number,
    payload: UpdateApplicationHGSSDirectDebitRequest,
  ) => void;
}

interface NetworkState {
  loadMilestones: boolean;
  loadHGSSAdmin: boolean;
  loadApplicationHGSSAdditionalIds: boolean;
  loadApplicationHGSSDirectDebit: boolean;
  loadApplicationHGSSPersonalGuarantees: boolean;
  updateTask: boolean;
  updateFlag: boolean;
  updateKeyDate: boolean;
  updateHGSSAdmin: boolean;
  updateApplicationHGSSAdditionalIds: boolean;
  updateApplicationHGSSPersonalGuarantees: boolean;
  updateApplicationHGSSDirectDebit: boolean;
}

const initNetworkState: NetworkState = {
  loadMilestones: false,
  loadHGSSAdmin: false,
  loadApplicationHGSSAdditionalIds: false,
  loadApplicationHGSSPersonalGuarantees: false,
  loadApplicationHGSSDirectDebit: false,
  updateTask: false,
  updateFlag: false,
  updateKeyDate: false,
  updateHGSSAdmin: false,
  updateApplicationHGSSAdditionalIds: false,
  updateApplicationHGSSPersonalGuarantees: false,
  updateApplicationHGSSDirectDebit: false,
};

const ComboApplicationManagementContext =
  createContext<ComboApplicationManagementInterface>({
    initialised: false,
    loading: initNetworkState,
    error: initNetworkState,
    milestones: null,
    hgssAdmin: null,
    additionalIds: null,
    directDebitContacts: null,
    personalGuarantees: null,
    loadMilestones: (applicationId: number) => null,
    loadHGSSAdmin: (applicationId: number) => null,
    loadApplicationHGSSAdditionalIds: (applicationId: number) => null,
    loadApplicationHGSSPersonalGuarantees: (applicationId: number) => null,
    loadApplicationHGSSDirectDebit: (applicationId: number) => null,
    updateTask: (
      applicationId: number,
      taskId: number,
      payload: UpdateApplicationTaskRequest,
    ) => null,
    updateFlag: (
      applicationId: number,
      flagId: number,
      payload: UpdateApplicationFlagRequest,
    ) => null,
    updateKeyDate: (
      applicationId: number,
      keyDateId: number,
      payload: UpdateApplicationKeyDateRequest,
    ) => null,
    updateHGSSAdmin: (applicationId: number, payload: ApplicationHGSSAdmin) =>
      null,
    updateApplicationHGSSAdditionalIds: (
      applicationId: number,
      payload: UpdateApplicationHGSSAdditionalIdRequest,
    ) => null,
    updateApplicationHGSSPersonalGuarantees: (
      applicationId: number,
      payload: UpdateApplicationHGSSPersonalGuaranteeRequest,
    ) => null,
    updateApplicationHGSSDirectDebit: (
      applicationId: number,
      payload: UpdateApplicationHGSSDirectDebitRequest,
    ) => null,
  });

export const Consume: () => ComboApplicationManagementInterface = () => {
  const consumer = React.useContext(ComboApplicationManagementContext);
  if (consumer.initialised === false) {
    throw new Error('ComboApplicationManagementContext not initialised');
  }
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [error, setError] = useState<NetworkState>(initNetworkState);
  const [loading, setLoading] = useState<NetworkState>(initNetworkState);
  const [milestones, setMilestones] = useState<ApplicationMilestones | null>(
    null,
  );
  const [hgssAdmin, setHgssAdmin] = useState<ApplicationHGSSAdmin | null>(null);
  const [additionalIds, setAdditionalIds] = useState<
    ApplicationHGSSAdditionalId[] | null
  >(null);
  const [personalGuarantees, setPersonalGuarantees] = useState<
    ApplicationHGSSPersonalGuarantee[] | null
  >(null);
  const [directDebitContacts, setDirectDebitContacts] = useState<
    ApplicationHGSSDirectDebit[] | null
  >(null);

  const loadMilestones = async (applicationId: number) => {
    try {
      setLoading({ ...loading, loadMilestones: true });
      setError({ ...error, loadMilestones: false });
      const milestones =
        await ComboApplicationManagementService.getApplicationMilestones(
          applicationId,
        );
      setMilestones(milestones);
    } catch (error) {
      setError({ ...error, loadMilestones: true });
      throw error;
    } finally {
      setLoading({ ...loading, loadMilestones: false });
    }
  };

  const loadHGSSAdmin = async (applicationId: number) => {
    try {
      setLoading({ ...loading, loadHGSSAdmin: true });
      setError({ ...error, loadHGSSAdmin: false });
      const hgss = await ComboHGSSAdminService.getHGSSAdmin(applicationId);
      setHgssAdmin(hgss);
    } catch (error) {
      setError({ ...error, loadHGSSAdmin: true });
      throw error;
    } finally {
      setLoading({ ...loading, loadHGSSAdmin: false });
    }
  };

  const loadApplicationHGSSAdditionalIds = async (applicationId: number) => {
    try {
      setLoading({ ...loading, loadApplicationHGSSAdditionalIds: true });
      setError({ ...error, loadApplicationHGSSAdditionalIds: false });
      const additionalIds =
        await ComboHGSSAdditionalIdService.loadApplicationHGSSAdditionalIds(
          applicationId,
        );
      setAdditionalIds(additionalIds);
    } catch (error) {
      setError({ ...error, loadApplicationHGSSAdditionalIds: true });
      throw error;
    } finally {
      setLoading({ ...loading, loadApplicationHGSSAdditionalIds: false });
    }
  };

  const loadApplicationHGSSPersonalGuarantees = async (
    applicationId: number,
  ) => {
    try {
      setLoading({ ...loading, loadApplicationHGSSPersonalGuarantees: true });
      setError({ ...error, loadApplicationHGSSPersonalGuarantees: false });
      const personalGuarantees =
        await ComboHGSSPersonalGuaranteeService.loadApplicationHGSSPersonalGuarantees(
          applicationId,
        );
      setPersonalGuarantees(personalGuarantees);
    } catch (error) {
      setError({ ...error, loadApplicationHGSSPersonalGuarantees: true });
      throw error;
    } finally {
      setLoading({ ...loading, loadApplicationHGSSPersonalGuarantees: false });
    }
  };

  const loadApplicationHGSSDirectDebit = async (applicationId: number) => {
    try {
      setLoading({ ...loading, loadApplicationHGSSDirectDebit: true });
      setError({ ...error, loadApplicationHGSSDirectDebit: false });
      const directDebitContacts =
        await HGSSDirectDebitService.loadApplicationHGSSDirectDebit(
          applicationId,
        );
      setDirectDebitContacts(directDebitContacts);
    } catch (error) {
      setError({ ...error, loadApplicationHGSSDirectDebit: true });
      throw error;
    } finally {
      setLoading({ ...loading, loadApplicationHGSSDirectDebit: false });
    }
  };

  const updateTask = async (
    applicationId: number,
    taskId: number,
    payload: UpdateApplicationTaskRequest,
  ) => {
    try {
      setLoading({ ...loading, updateTask: true });
      setError({ ...error, updateTask: false });
      const milestones =
        await ComboApplicationManagementService.updateApplicationTask(
          applicationId,
          taskId,
          payload,
        );
      setMilestones(milestones);
    } catch (error) {
      setError({ ...error, updateTask: true });
      throw error;
    } finally {
      setLoading({ ...loading, updateTask: false });
    }
  };

  const updateFlag = async (
    applicationId: number,
    flagId: number,
    payload: UpdateApplicationFlagRequest,
  ) => {
    try {
      setLoading({ ...loading, updateFlag: true });
      setError({ ...error, updateFlag: false });
      const milestones =
        await ComboApplicationManagementService.updateApplicationFlag(
          applicationId,
          flagId,
          payload,
        );
      setMilestones(milestones);
    } catch (error) {
      setError({ ...error, updateFlag: true });
      throw error;
    } finally {
      setLoading({ ...loading, updateFlag: false });
    }
  };

  const updateKeyDate = async (
    applicationId: number,
    keyDateId: number,
    payload: UpdateApplicationKeyDateRequest,
  ) => {
    try {
      setLoading({ ...loading, updateKeyDate: true });
      setError({ ...error, updateKeyDate: false });
      const milestones =
        await ComboApplicationManagementService.updateApplicationKeyDate(
          applicationId,
          keyDateId,
          payload,
        );
      setMilestones(milestones);
    } catch (error) {
      setError({ ...error, updateKeyDate: true });
      throw error;
    } finally {
      setLoading({ ...loading, updateKeyDate: false });
    }
  };

  const updateHGSSAdmin = async (
    applicationId: number,
    payload: ApplicationHGSSAdmin,
  ) => {
    try {
      setLoading({ ...loading, updateKeyDate: true });
      setError({ ...error, updateKeyDate: false });
      const hgss = await ComboHGSSAdminService.updateHGSSAdmin(
        applicationId,
        payload,
      );
      setHgssAdmin(hgss);
    } catch (error) {
      setError({ ...error, updateHGSSAdmin: true });
      throw error;
    } finally {
      setLoading({ ...loading, updateHGSSAdmin: false });
    }
  };

  const updateApplicationHGSSAdditionalIds = async (
    applicationId: number,
    payload: UpdateApplicationHGSSAdditionalIdRequest,
  ) => {
    try {
      setLoading({ ...loading, updateApplicationHGSSAdditionalIds: true });
      setError({ ...error, updateApplicationHGSSAdditionalIds: false });
      const additionalIds =
        await ComboHGSSAdditionalIdService.updateApplicationHGSSAdditionalIds(
          applicationId,
          payload,
        );
      setAdditionalIds(additionalIds);
    } catch (error) {
      setError({ ...error, updateApplicationHGSSAdditionalIds: true });
      throw error;
    } finally {
      setLoading({ ...loading, updateApplicationHGSSAdditionalIds: false });
    }
  };

  const updateApplicationHGSSPersonalGuarantees = async (
    applicationId: number,
    payload: UpdateApplicationHGSSPersonalGuaranteeRequest,
  ) => {
    try {
      setLoading({ ...loading, updateApplicationHGSSPersonalGuarantees: true });
      setError({ ...error, updateApplicationHGSSPersonalGuarantees: false });
      const personalGuarantees =
        await ComboHGSSPersonalGuaranteeService.updateApplicationHGSSPersonalGuarantees(
          applicationId,
          payload,
        );
      setPersonalGuarantees(personalGuarantees);
    } catch (error) {
      setError({ ...error, updateApplicationHGSSPersonalGuarantees: true });
      throw error;
    } finally {
      setLoading({
        ...loading,
        updateApplicationHGSSPersonalGuarantees: false,
      });
    }
  };

  const updateApplicationHGSSDirectDebit = async (
    applicationId: number,
    payload: UpdateApplicationHGSSDirectDebitRequest,
  ) => {
    try {
      setLoading({ ...loading, updateApplicationHGSSDirectDebit: true });
      setError({ ...error, updateApplicationHGSSDirectDebit: false });
      const directDebitContacts =
        await HGSSDirectDebitService.updateApplicationHGSSDirectDebit(
          applicationId,
          payload,
        );
      setDirectDebitContacts(directDebitContacts);
    } catch (error) {
      setError({ ...error, updateApplicationHGSSDirectDebit: true });
      throw error;
    } finally {
      setLoading({
        ...loading,
        updateApplicationHGSSDirectDebit: false,
      });
    }
  };

  return (
    <ComboApplicationManagementContext.Provider
      value={{
        initialised: true,
        loading,
        error,
        milestones,
        hgssAdmin,
        directDebitContacts,
        additionalIds,
        personalGuarantees,
        loadMilestones,
        loadHGSSAdmin,
        loadApplicationHGSSAdditionalIds,
        loadApplicationHGSSPersonalGuarantees,
        loadApplicationHGSSDirectDebit,
        updateTask,
        updateFlag,
        updateKeyDate,
        updateHGSSAdmin,
        updateApplicationHGSSAdditionalIds,
        updateApplicationHGSSPersonalGuarantees,
        updateApplicationHGSSDirectDebit,
      }}
    >
      {children}
    </ComboApplicationManagementContext.Provider>
  );
};
