import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as Msal from 'msal';
import Sidebar, { SidebarLink } from 'components/common/Sidebar/Sidebar';
import ConfirmationDialog from 'components/common/Dialogs/ConfirmationDialog';
import { consume } from 'contexts/GlobalContext';
import AuthService from 'services/AuthService';
import AuthHelper from 'utils/AuthHelper';
import WindowHelper from 'utils/WindowHelper';
import permissionsHelper from 'utils/PermissionsHelper';
import config from 'utils/ActiveDirectoryHelper';

const msalInstance = new Msal.UserAgentApplication(config);

export const SidebarWrapper: React.FC = (props) => {
  const { width } = WindowHelper.useWindowSize();
  const {
    authenticated,
    permissions,
    setAuthenticated,
    successLogout,
    setSuccessLogout,
  } = consume();
  const [showLogoutConfirmation, setShowLogoutConfirmation] =
    useState<boolean>(false);

  const links: SidebarLink[] = [];

  if (permissions !== null) {
    if (permissions.enquiries.includes('viewList')) {
      links.push({
        icon: 'properties',
        title: 'Enquiries',
        url: '/enquiries?page=1',
        applicationType: 'direct',
      });
    }

    if (permissions.smartDispenseEnquiries.includes('viewList')) {
      links.push({
        icon: 'properties',
        title: 'Enquiries',
        url: '/smart-dispense/enquiries?page=1',
        applicationType: 'smart-dispense',
      });
    }

    const applicationsSubLinks = [];
    const smartDispenseSubLinks = [];

    if (permissions.prospect.includes('viewList')) {
      applicationsSubLinks.push(
        {
          applicationType: 'direct',
          title: 'Prospect',
          url: '/applications?page=1&statusKey=approved',
        },
        {
          applicationType: 'direct',
          title: 'Pending',
          url: '/applications?page=1&statusKey=pending',
        },
      );
    }

    if (permissions.smartDispenseProspect.includes('viewList')) {
      smartDispenseSubLinks.push(
        {
          applicationType: 'smart-dispense',
          title: 'Prospect',
          url: '/smart-dispense/applications?page=1&status=prospect',
        },
        {
          applicationType: 'smart-dispense',
          title: 'Pending',
          url: '/smart-dispense/applications?page=1&status=pending',
        },
      );
    }

    if (permissions.applications.includes('viewList')) {
      applicationsSubLinks.push({
        applicationType: 'direct',
        title: 'Onboarding',
        url: '/applications?page=1&statusKey=boarding',
      });
    }

    if (permissions.smartDispenseApplications.includes('viewList')) {
      smartDispenseSubLinks.push({
        applicationType: 'smart-dispense',
        title: 'Onboarding',
        url: '/smart-dispense/applications?page=1&status=onboarding',
      });
    }

    if (
      permissions.applications.includes('viewList') ||
      permissions.prospect.includes('viewList')
    ) {
      links.push({
        icon: 'saved',
        title: 'Applications',
        url: '/applications',
        subLinks: applicationsSubLinks,
        applicationType: 'direct',
      });
    }

    if (
      permissions.smartDispenseApplications.includes('viewList') ||
      permissions.smartDispenseProspect.includes('viewList')
    ) {
      links.push({
        icon: 'saved',
        title: 'Applications',
        url: '/smart-dispense/applications',
        subLinks: smartDispenseSubLinks,
        applicationType: 'smart-dispense',
      });
    }
  }

  return authenticated && permissions ? (
    <Sidebar
      links={links}
      expanded={!width || width >= 1280}
      onLogout={() => {
        setShowLogoutConfirmation(true);
      }}
    >
      <>
        {props.children}
        {successLogout && <Redirect to="/" />}
        <ConfirmationDialog
          show={showLogoutConfirmation}
          title="Confirm Log Out"
          text="Are you sure you want to log out? Any unsaved progress will be lost"
          onConfirmSuccessMessage="Successfully logged out!"
          onClose={() => {
            setShowLogoutConfirmation(false);
          }}
          onConfirm={async () => {
            try {
              if (AuthHelper.getAuthType() === 'active_directory') {
                msalInstance.logout();
              } else {
                await AuthService.logout();
              }
            } catch (error) {
              // Do nothing with the error
            } finally {
              AuthHelper.removeAuth();
              permissionsHelper.removePermissions();
              setSuccessLogout(true);
              setAuthenticated(false);
            }
          }}
        />
      </>
    </Sidebar>
  ) : (
    <>{props.children}</>
  );
};
