import HeinekenDirectRole from 'models/HeinekenDirectRole';
import Axios from 'services/Client';

const HeinekenDirectRoleService = {
  listHeinekenDirectRoles: async (): Promise<HeinekenDirectRole[]> => {
    const response = await Axios.get<HeinekenDirectRole[]>(
      `/heineken-direct-roles`,
    );
    return response.data;
  },
};

export default HeinekenDirectRoleService;
