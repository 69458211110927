import Axios from '../Client';
export default class TBarStylesService {
  public static async listTBarStyles(tapCount: number): Promise<any[]> {
    const response = await Axios.get<any[]>(`/smart-dispense/styles?tapsCount=${tapCount}`);
    return response.data;
  }

  public static async listTBarStyleOptions(id: number, tapCount: number): Promise<any[]> {
    const response = await Axios.get<any[]>(`/smart-dispense/styles/${id}/options?tapsCount=${tapCount}`);
    return response.data;
  }
}