import Axios from 'services/Client';
import Persona from 'models/Persona';

const PersonaService =  {
  listPersonas: async (): Promise<Persona[]> => {
    const response = await Axios.get<Persona[]>('/personas');
    return response.data;
  },
};

export default PersonaService; 