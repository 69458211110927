import Axios from '../Client';
import Status from 'models/Status';

const StatusService = {
  listStatuses: async (): Promise<Status[]> => {
    const response = await Axios.get<Status[]>('/statuses');
    return response.data;
  },
};

export default StatusService;