import Axios from 'services/Client';
import LegalStatus from 'models/LegalStatus';

const LegalStatusService =  {
  listLegalStatuses: async (): Promise<LegalStatus[]> => {
    const response = await Axios.get<LegalStatus[]>('/legal-statuses');
    return response.data;
  },
};

export default LegalStatusService;
