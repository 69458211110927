import Axios from '../Client';
import { TapBrand } from 'models/TapBrand';

const TapBrandsService =  {
  loadBrandsList: async (): Promise<TapBrand[]> => {
    const response = await Axios.get<TapBrand[]>(
      `/smart-dispense/brands`,
    );
    return response.data;
  },
};

export default TapBrandsService;
