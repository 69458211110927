import React from 'react';
import { Position, Tooltip as BPTooltip } from '@blueprintjs/core';

interface Props {
  content: string;
  position?: Position;
}

const Tooltip: React.FC<Props> = (props) => {
  const { content, position, children } = props;
  const setPosition = position || Position.TOP;
  return (
    <BPTooltip content={content} position={setPosition}>
      {children}
    </BPTooltip>
  );
};

export default Tooltip;
