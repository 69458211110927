import Axios from 'services/Client';
import ApplicationMilestones from 'models/ApplicationMilestones';
import UpdateApplicationTaskRequest from './requests/UpdateApplicationTaskRequest';
import UpdateApplicationFlagRequest from './requests/UpdateApplicationFlagRequest';
import UpdateApplicationKeyDateRequest from './requests/UpdateApplicationKeyDateRequest';

interface GetMilestonesResponse {
  milestones: ApplicationMilestones;
}

const ComboApplicationManagementService =  {
  getApplicationMilestones: async (
    applicationId: number,
  ): Promise<ApplicationMilestones> => {
    const response = await Axios.get<GetMilestonesResponse>(
      `/combo/${applicationId}/milestones`,
    );
    return response.data.milestones;
  },
  updateApplicationTask: async (
    applicationId: number,
    taskId: number,
    payload: UpdateApplicationTaskRequest,
  ): Promise<ApplicationMilestones> => {
    const response = await Axios.put<GetMilestonesResponse>(
      `/combo/${applicationId}/tasks/${taskId}`,
      payload,
    );
    return response.data.milestones;
  },

  updateApplicationFlag: async (
    applicationId: number,
    flagId: number,
    payload: UpdateApplicationFlagRequest,
  ): Promise<ApplicationMilestones> => {
    const response = await Axios.put<GetMilestonesResponse>(
      `/combo/${applicationId}/flags/${flagId}`,
      payload,
    );
    return response.data.milestones;
  },

  updateApplicationKeyDate: async (
    applicationId: number,
    keyDateId: number,
    payload: UpdateApplicationKeyDateRequest,
  ): Promise<ApplicationMilestones> => {
    const response = await Axios.put<GetMilestonesResponse>(
      `/combo/${applicationId}/key-dates/${keyDateId}`,
      payload,
    );
    return response.data.milestones;
  },
};

export default ComboApplicationManagementService;