import Axios from 'services/Client';
import ApplicationHGSSPersonalGuarantee from 'models/ApplicationHGSSPersonalGuarantee';
import UpdateApplicationHGSSPersonalGuaranteeRequest from './requests/UpdateApplicationHGSSPersonalGuaranteeRequest';
import SkipPersonalGuaranteeSignature from './requests/SkipPersonalGuaranteeSignature'
import Application from 'models/Application';

const HGSSPersonalGuaranteeService =  {
  loadApplicationHGSSPersonalGuarantees: async (
    applicationId: number,
  ): Promise<ApplicationHGSSPersonalGuarantee[]> => {
    const response = await Axios.get<ApplicationHGSSPersonalGuarantee[]>(
      `/applications/${applicationId}/personal_guarantee`,
    );
    return response.data;
  },
  updateApplicationHGSSPersonalGuarantees: async (
    applicationId: number,
    payload: UpdateApplicationHGSSPersonalGuaranteeRequest,
  ): Promise<ApplicationHGSSPersonalGuarantee[]> => {
    const response = await Axios.post<ApplicationHGSSPersonalGuarantee[]>(
      `/applications/${applicationId}/personal_guarantee`,
      payload,
    );
    return response.data;
  },
  loadApplicationHGSSPersonalGuaranteeDocument: async (
    applicationId: number,
    applicantId: number,
  ): Promise<string> => {
    const response = await Axios.get<{ url: string }>(
      `/applications/${applicationId}/personal_guarantee/${applicantId}`,
    );
    return response.data.url;
  },
  skipPersonalGuaranteeSignature: async (
    applicationId: number,
    payload: SkipPersonalGuaranteeSignature,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${applicationId}/personal_guarantee`,
      payload,
    );
    return response.data;
  },
};

export default HGSSPersonalGuaranteeService;
