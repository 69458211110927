import React, { useState, createContext } from 'react';
import axios, { CancelTokenSource } from 'axios';
import SDApplication from 'models/SDApplication';
import KeyContact from 'models/KeyContact';
import Region from 'models/Region';
import { Task } from 'models/Task';
import Pagination from 'models/Pagination';
import QueryParams from 'models/QueryParams';
import SDApplicationService from 'services/SDApplicationService';
import RegionService from 'services/RegionService';
import KeyContactService from 'services/KeyContactService';
import { TaskService } from 'services/TasksService';
import CreateNewSDApplicationRequest from 'services/SDApplicationService/requests/CreateNewSDApplicationRequest';

interface SDApplicationContextInterface {
  initialised: boolean;
  error: Error;
  loading: Loading;
  sdApplications: Pagination<SDApplication>;
  keyContacts: KeyContact[];
  tasks: Task[];
  regions: Region[];
  page: number;
  setPage: (page: number) => void;
  assigneeFilter: KeyContact | null;
  setAssigneeFilter: (val: KeyContact | null) => void;
  taskFilter: {}[] | null;
  setTaskFilter: (val: {}[] | null) => void;
  flagsChecked: boolean;
  setFlagsChecked: (val: boolean) => void;
  sortValue: string;
  setSortValue: (val: string) => void;
  searchValue: string;
  setSearchValue: (val: string) => void;
  currentSDApplication: SDApplication | null;
  setCurrentSDApplication: (application: SDApplication | null) => void;
  initialisePageData: (params?: QueryParams) => void;
  loadKeyContacts: () => void;
  loadTasks: () => void;
  loadRegions: () => void;
  createNewSDApplication: (
    payload: CreateNewSDApplicationRequest,
  ) => Promise<SDApplication | undefined> | null;
}

interface Error {
  sdApplications: boolean;
  keyContacts: boolean;
  loadKeyContacts: boolean;
  tasks: boolean;
  loadTasks: boolean;
  loadRegions: boolean;
  createNewSDApplication: boolean;
}

interface Loading {
  sdApplications: boolean;
  keyContacts: boolean;
  loadKeyContacts: boolean;
  tasks: boolean;
  loadTasks: boolean;
  loadRegions: boolean;
  createNewSDApplication: boolean;
}

const defaultPagination: Pagination<SDApplication> = {
  currentPage: 1,
  totalRecords: 1,
  itemsPerPage: 1,
  data: [],
};

const defaultPerPage = 15;

const SDApplicationContext = createContext<SDApplicationContextInterface>({
  initialised: false,
  error: {
    sdApplications: false,
    keyContacts: false,
    loadKeyContacts: false,
    loadRegions: false,
    tasks: false,
    loadTasks: false,
    createNewSDApplication: false,
  },
  loading: {
    sdApplications: false,
    keyContacts: false,
    loadKeyContacts: false,
    tasks: false,
    loadTasks: false,
    loadRegions: false,
    createNewSDApplication: false,
  },
  sdApplications: defaultPagination,
  keyContacts: [],
  tasks: [],
  regions: [],
  assigneeFilter: null,
  setAssigneeFilter: () => null,
  taskFilter: null,
  setTaskFilter: () => null,
  page: 1,
  setPage: (page: number) => null,
  flagsChecked: false,
  setFlagsChecked: (val: boolean) => null,
  sortValue: '',
  setSortValue: () => null,
  searchValue: '',
  setSearchValue: () => null,
  currentSDApplication: null,
  setCurrentSDApplication: (application: SDApplication | null) => null,
  initialisePageData: () => null,
  loadKeyContacts: () => null,
  loadTasks: () => null,
  loadRegions: () => null,
  createNewSDApplication: (payload: CreateNewSDApplicationRequest) => null,
});

export const Consume: () => SDApplicationContextInterface = () => {
  const consumer = React.useContext(SDApplicationContext);
  if (consumer.initialised === false) {
    throw new Error('SDApplicationContextProvider not initialised');
  }
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [error, setError] = useState<Error>({
    sdApplications: false,
    keyContacts: false,
    loadKeyContacts: false,
    tasks: false,
    loadTasks: false,
    loadRegions: false,
    createNewSDApplication: false,
  });
  const [loading, setLoading] = useState<Loading>({
    sdApplications: false,
    keyContacts: false,
    loadKeyContacts: false,
    tasks: false,
    loadTasks: false,
    loadRegions: false,
    createNewSDApplication: false,
  });
  const [sdApplications, setsdApplications] = useState<
    Pagination<SDApplication>
  >(defaultPagination);
  const [page, setPage] = useState<number>(sdApplications.currentPage);
  const [
    currentSDApplication,
    setCurrentSDApplication,
  ] = useState<SDApplication | null>(null);
  const [keyContacts, setKeyContacts] = useState<KeyContact[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [assigneeFilter, setAssigneeFilter] = useState<KeyContact | null>(null);
  const [taskFilter, setTaskFilter] = useState<{}[] | null>(null);
  const [flagsChecked, setFlagsChecked] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [
    currentRequest,
    setCurrentRequest,
  ] = useState<CancelTokenSource | null>(null);

  const initialisePageData = async (params?: QueryParams) => {
    let stateParams: QueryParams = {
      itemsPerPage: defaultPerPage,
      page: page,
    };

    if (sortValue) stateParams.sort = sortValue;
    if (searchValue) stateParams.search = searchValue;
    if (flagsChecked) stateParams.hasFlags = 1;
    if (assigneeFilter) stateParams.assigneeId = assigneeFilter.id;
    if (taskFilter) stateParams.taskFilter = taskFilter;
    if (params) stateParams = { ...stateParams, ...params };
    try {
      setLoading({ ...loading, sdApplications: true, keyContacts: true });
      if (currentRequest) {
        currentRequest.cancel();
      }
      const cr = axios.CancelToken.source();
      setCurrentRequest(cr);
      if (keyContacts.length > 0) {
        const sdApplications = await SDApplicationService.paginateApplications(
          stateParams,
          {
            cancelToken: cr.token,
          },
        );
        setsdApplications(sdApplications);
      } else {
        const [sdApplications, keyContacts, tasks] = (await axios.all<
          Pagination<SDApplication> | KeyContact[] | Task[]
        >([
          SDApplicationService.paginateApplications(stateParams, {
            cancelToken: cr.token,
          }),
          KeyContactService.listKeyContacts(),
          TaskService.listTasks(),
        ])) as [Pagination<SDApplication>, KeyContact[], Task[]];

        setsdApplications(sdApplications);
        setKeyContacts(keyContacts);
        setTasks(tasks);
      }
      setError({ ...error, sdApplications: false, keyContacts: false });
      setLoading({ ...loading, sdApplications: false, keyContacts: false });
    } catch (e) {
      // ignore cancelled requests
      if (!axios.isCancel(e)) {
        setError({ ...error, sdApplications: true, keyContacts: true });
        setLoading({ ...loading, sdApplications: false, keyContacts: false });
      }
      // throw e
    }
  };

  const loadKeyContacts = async () => {
    try {
      setLoading({ ...loading, loadKeyContacts: true });
      setError({ ...error, loadKeyContacts: false });
      const keyContacts = await KeyContactService.listKeyContacts();
      setKeyContacts(keyContacts);
    } catch (e) {
      setError({ ...error, loadKeyContacts: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadKeyContacts: false });
    }
  };

  const loadRegions = async () => {
    try {
      setLoading({ ...loading, loadRegions: true });
      setError({ ...error, loadRegions: false });
      const regions = await RegionService.listRegions();
      setRegions(regions);
    } catch (e) {
      setError({ ...error, loadRegions: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadRegions: false });
    }
  };

  const createNewSDApplication = async (
    payload: CreateNewSDApplicationRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, createNewSDApplication: false });
      setLoading({ ...loading, createNewSDApplication: true });
      const application = await SDApplicationService.createNewSDApplication(
        payload,
      );
      setCurrentSDApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createNewSDApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, createNewSDApplication: false });
    }
  };

  const loadTasks = async () => {
    try {
      setLoading({ ...loading, loadTasks: true });
      setError({ ...error, loadTasks: false });
      const tasks = await TaskService.listTasks();
      setTasks(tasks);
    } catch (e) {
      setError({ ...error, loadTasks: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadTasks: false });
    }
  };

  return (
    <SDApplicationContext.Provider
      value={{
        initialised: true,
        error,
        loading,
        sdApplications,
        page,
        setPage,
        keyContacts,
        regions,
        flagsChecked,
        setFlagsChecked,
        assigneeFilter,
        setAssigneeFilter,
        taskFilter,
        setTaskFilter,
        sortValue,
        setSortValue,
        searchValue,
        setSearchValue,
        currentSDApplication,
        setCurrentSDApplication,
        initialisePageData,
        loadKeyContacts,
        loadRegions,
        createNewSDApplication,
        tasks,
        loadTasks,
      }}
    >
      {children}
    </SDApplicationContext.Provider>
  );
};
