import Axios from 'services/Client';
import ApplicationHGSSPersonalGuarantee from 'models/ApplicationHGSSPersonalGuarantee';
import UpdateApplicationHGSSPersonalGuaranteeRequest from './requests/UpdateApplicationHGSSPersonalGuaranteeRequest';
import SkipPersonalGuaranteeSignature from './requests/SkipPersonalGuaranteeSignature';
import ComboApplication from 'models/ComboApplication';

const ComboHGSSPersonalGuaranteeService =  {
  loadApplicationHGSSPersonalGuarantees: async (
    applicationId: number,
  ): Promise<ApplicationHGSSPersonalGuarantee[]> => {
    const response = await Axios.get<ApplicationHGSSPersonalGuarantee[]>(
      `/combo/${applicationId}/personal_guarantee`,
    );
    return response.data;
  },
  updateApplicationHGSSPersonalGuarantees: async (
    applicationId: number,
    payload: UpdateApplicationHGSSPersonalGuaranteeRequest,
  ): Promise<ApplicationHGSSPersonalGuarantee[]> => {
    const response = await Axios.post<ApplicationHGSSPersonalGuarantee[]>(
      `/combo/${applicationId}/personal_guarantee`,
      payload,
    );
    return response.data;
  },
  loadApplicationHGSSPersonalGuaranteeDocument: async (
    applicationId: number,
    applicantId: number,
  ): Promise<string> => {
    const response = await Axios.get<{ url: string }>(
      `/combo/${applicationId}/personal_guarantee/${applicantId}`,
    );
    return response.data.url;
  },
  skipPersonalGuaranteeSignature: async (
    applicationId: number,
    payload: SkipPersonalGuaranteeSignature,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `/combo/${applicationId}/personal_guarantee`,
      payload,
    );
    return response.data;
  },
};

export default ComboHGSSPersonalGuaranteeService;