import Axios from 'services/Client';
import OutletEstimatedSpend from 'models/OutletEstimatedSpend';

const OutletEstimatedSpendService = {
  listOutletEstimatedSpends: async (): Promise<OutletEstimatedSpend[]> => {
    const response = await Axios.get<OutletEstimatedSpend[]>(
      '/outlet-estimated-spend',
    );
    return response.data;
  },
};

export default OutletEstimatedSpendService;