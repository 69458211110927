import { AxiosRequestConfig } from 'axios';
import UpdateSDEnquiryRequest from 'services/SDEnquiryServices/requests/UpdateSDEnquiryRequest'
import AssignSDEnquiryRequest from 'services/SDEnquiryServices/requests/AssignSDEnquiryRequest'
import StatusReasonRequest from 'services/SDEnquiryServices/requests/StatusReasonRequest'
import Axios from '../Client';
import SDEnquiry from 'models/SDEnquiry';
import Pagination from 'models/Pagination';
import StringHelper from 'utils/StringHelper';
import QueryParams from 'models/QueryParams';
import Comment from 'models/Comment';
import Mention from 'models/Mention';
import CreateSDEnquiryNoteRequest from './requests/CreateSDEnquiryNoteRequest';

const SDEnquiryService = {
  paginateEnquiries: async (
    params: QueryParams,
    config?: AxiosRequestConfig,
  ): Promise<Pagination<SDEnquiry>> => {
    const stringified = StringHelper.formatQueryParams(params);
    const response = await Axios.get<Pagination<SDEnquiry>>(
      `/smart-dispense/enquiries${stringified === '' ? '' : '?' + stringified}`,
      config,
    );
    return response.data;
  },

  findEnquiry: async (id: number): Promise<SDEnquiry> => {
    const response = await Axios.get<SDEnquiry>(
      `/smart-dispense/enquiries/${id}`,
    );
    return response.data;
  },

  updateEnquiry: async (
    id: number,
    payload: UpdateSDEnquiryRequest,
  ): Promise<SDEnquiry> => {
    const response = await Axios.put<SDEnquiry>(
      `/smart-dispense/enquiries/${id}`,
      payload,
    );
    return response.data;
  },

  loadEnquiryComments: async (id: number): Promise<Comment[]> => {
    const response = await Axios.get<Comment[]>(
      `/smart-dispense/enquiries/${id}/notes`,
    );
    return response.data;
  },

  loadEnquiryMentionUsers: async (id: number): Promise<Mention[]> => {
    const response = await Axios.get<Mention[]>(
      `/smart-dispense/enquiries/${id}/mentions`,
    );
    return response.data;
  },

  createEnquiryNote: async (
    id: number,
    payload: CreateSDEnquiryNoteRequest,
  ): Promise<SDEnquiry> => {
    const response = await Axios.post<SDEnquiry>(
      `/smart-dispense/enquiries/${id}/notes`,
      payload,
    );
    return response.data;
  },

  assignEnquiry: async (
    id: number,
    payload: AssignSDEnquiryRequest,
  ): Promise<SDEnquiry> => {
    const response = await Axios.put<SDEnquiry>(
      `/smart-dispense/enquiries/${id}/sales-reps`,
      payload,
    );
    return response.data;
  },

  approveEnquiry: async (id: number): Promise<SDEnquiry> => {
    const response = await Axios.post<SDEnquiry>(
      `/smart-dispense/enquiries/${id}/approve`,
    );
    return response.data;
  },

  rejectEnquiry: async (
    id: number,
    payload: StatusReasonRequest,
  ): Promise<SDEnquiry> => {
    const response = await Axios.post<SDEnquiry>(
      `smart-dispense/enquiries/${id}/reject`,
      payload,
    );
    return response.data;
  },
};

export default SDEnquiryService;