import Axios from 'services/Client';
import ApplicationHGSSPersonalGuarantee from 'models/ApplicationHGSSPersonalGuarantee';
import UpdateSDApplicationHGSSPersonalGuaranteeRequest from './requests/UpdateSDApplicationHGSSPersonalGuaranteeRequest/UpdateSDApplicationHGSSPersonalGuaranteeRequest';
import SkipPersonalGuaranteeSignature from './requests/SkipPersonalGuaranteeSignature';
import SDApplication from 'models/SDApplication';

const SDHGSSPersonalGuaranteeService = {
  loadSDApplicationHGSSPersonalGuarantees: async (
    applicationId: number,
  ): Promise<ApplicationHGSSPersonalGuarantee[]> => {
    const response = await Axios.get<ApplicationHGSSPersonalGuarantee[]>(
      `/smart-dispense/${applicationId}/personal_guarantee`,
    );
    return response.data;
  },
  updateSDApplicationHGSSPersonalGuarantees: async (
    applicationId: number,
    payload: UpdateSDApplicationHGSSPersonalGuaranteeRequest,
  ): Promise<ApplicationHGSSPersonalGuarantee[]> => {
    const response = await Axios.post<ApplicationHGSSPersonalGuarantee[]>(
      `/smart-dispense/${applicationId}/personal_guarantee`,
      payload,
    );
    return response.data;
  },
  loadSDApplicationHGSSPersonalGuaranteeDocument: async (
    applicationId: number,
    applicantId: number,
  ): Promise<string> => {
    const response = await Axios.get<{ url: string }>(
      `/smart-dispense/${applicationId}/personal_guarantee/${applicantId}`,
    );
    return response.data.url;
  },
  skipPersonalGuaranteeSignature: async (
    applicationId: number,
    payload: SkipPersonalGuaranteeSignature,
  ): Promise<SDApplication> => {
    const response = await Axios.put<SDApplication>(
      `/smart-dispense/${applicationId}/personal_guarantee`,
      payload,
    );
    return response.data;
  },
};

export default SDHGSSPersonalGuaranteeService;