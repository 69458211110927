import React, { createContext, useState } from 'react';
import ComboApplication from 'models/ComboApplication';
import ComboApplicationService from 'services/ComboApplicationService';
import KeyContact from 'models/KeyContact';
import SDApplication from 'models/SDApplication';
import Application from 'models/Application';
import KeyContactService from 'services/KeyContactService';
import UpdateApplicationRequest from 'services/ComboApplicationService/requests/UpdateApplicationRequest';
import UpdateSalesRepsRequest from 'services/ComboApplicationService/requests/UpdateSalesRepsRequest';
import RegionService from 'services/RegionService';
import LegalStatusService from 'services/LegalStatusService';
import OutletSegmentService from 'services/OutletSegmentService';
import Region from 'models/Region';
import axios from 'axios';
import LegalStatus from 'models/LegalStatus';
import OutletSegment from 'models/OutletSegment';
import Contact from 'models/Contact';
import Comment from 'models/Comment';
import Mention from 'models/Mention';
import ComboSigningService from 'services/ComboSigningService';
import UpdateOutletDetailsRequest from 'services/ComboApplicationService/requests/UpdateOutletDetailsRequest';
import UpdateOutletSalesRequest from 'services/ComboApplicationService/requests/UpdateOutletSalesRequest';
import UpdateBillingDetailsRequest from 'services/ComboApplicationService/requests/UpdateBillingDetailsRequest';
import UpdateOrderDetailsRequest from 'services/ComboApplicationService/requests/UpdateOrderDetailsRequest';
import CreateNoteRequest from 'services/ComboApplicationService/requests/CreateNoteRequest';
import AccountNumbersRequest from 'services/ComboApplicationService/requests/AccountNumbersRequest';
import Cluster from 'models/Cluster';
import Classification from 'models/Classification';
import PricingSector from 'models/PricingSector';
import ClusterService from 'services/ClusterService';
import ClassificationService from 'services/ClassificationService';
import PricingSectorService from 'services/PricingSectorService';
import UpdateAdminDetailsRequest from 'services/ComboApplicationService/requests/UpdateAdminDetailsRequest';
import UpdateAdditionalInfoRequest from 'services/ComboApplicationService/requests/UpdateAdditionalInfoRequest';
import UpdateOffersRequest from 'services/ComboApplicationService/requests/UpdateOffersRequest';
import ApplicantRequest from 'services/ComboApplicationService/requests/ApplicantRequest';
import InnserveEmailsService from 'services/InnserveEmailsService';
import HeinekenDirectRole from 'models/HeinekenDirectRole';
import HeinekenDirectRoleService from 'services/HeinekenDirectRoleService';
import OutletVolume from 'models/OutletVolume';
import OutletEstimatedSpend from 'models/OutletEstimatedSpend';
import OutletVolumeService from 'services/OutletVolumeService';
import OutletEstimatedSpendService from 'services/OutletEstimatedSpendService';
import CreateHeinekenDirectRequest from 'services/ComboApplicationService/requests/CreateHeinekenDirectRequest';
import SubmitApplicationRequest from 'services/ComboApplicationService/requests/SubmitApplicationRequest';
import DeleteAppicationRequest from 'services/ComboApplicationService/requests/DeleteApplicationRequest';
import StatusReason from 'models/StatusReason';
import InnserveEmail from 'models/InnserveEmail';
import StatusReasonService from 'services/StatusReasonService';
import Persona from 'models/Persona';
import PersonaService from 'services/PersonaService';
import InnovativeSolution from 'models/InnovativeSolution';
import InnovativeSolutionService from 'services/InnovativeSolutionService';
import ToggleApplicationRequest from 'services/ComboApplicationService/requests/ToggleApplicationRequest';
import InnserveDetailsRequest from 'services/ComboApplicationService/requests/InnserveDetailsRequest';
import CompleteApplicationRequest from 'services/ComboApplicationService/requests/CompleteApplicationRequest';
import CreateBarRequest from 'services/ComboApplicationService/requests/CreateBarRequest';
import UpdateContractInfoRequest from 'services/ComboApplicationService/requests/UpdateContractInfoRequest';
import ComboHGSSPersonalGuaranteeService from 'services/ComboHGSSPersonalGuaranteeService';
import SkipPersonalGuaranteeSignature from 'services/ComboHGSSPersonalGuaranteeService/requests/SkipPersonalGuaranteeSignature';
import SkipDirectDebitSignature from 'services/ComboHGSSDirectDebitService/requests/SkipDirectDebitSignature';
import { HGSSDirectDebitService } from 'services/ComboHGSSDirectDebitService';
import { ComboContractService } from 'services/ComboContractService';

interface ComboApplicationContextInterface {
  initialised: boolean;
  error: NetworkState;
  loading: NetworkState;
  keyContacts: KeyContact[];
  currentApplication: ComboApplication | null;
  applicationComments: Comment[];
  innserveEmails: InnserveEmail[];
  applicationMentionUsers: Mention[];
  abortReasons: StatusReason[];
  regions: Region[];
  legalStatuses: LegalStatus[];
  outletSegments: OutletSegment[];
  contactDetails: Contact[];
  clusters: Cluster[];
  classifications: Classification[];
  pricingSectors: PricingSector[];
  heinekenDirectRoles: HeinekenDirectRole[];
  outletVolumes: OutletVolume[];
  outletEstimatedSpends: OutletEstimatedSpend[];
  personas: Persona[];
  innovativeSolutions: InnovativeSolution[];
  loadStatusReasons: (status: string) => void;
  loadOutletVolume: () => void;
  loadOutletEstimatedSpend: () => void;
  loadHeinekenDirectRoles: () => void;
  loadAdminStaticLists: () => void;
  loadKeyContacts: () => void;
  loadContactDetails: (id: number) => void;
  loadApplicationComments: (id: number) => Promise<Comment[]> | null;
  loadApplicationMentionUsers: (id: number) => Promise<Mention[]> | null;
  setCurrentApplication: (application: ComboApplication | null) => void;
  createNote: (
    id: number,
    payload: CreateNoteRequest,
  ) => Promise<ComboApplication | undefined> | null;
  createHeinekenDirect: (
    id: number,
    payload: CreateHeinekenDirectRequest,
  ) => Promise<ComboApplication | undefined> | null;
  findApplication: (id: number) => Promise<ComboApplication | undefined> | null;
  updateApplication: (
    id: number,
    payload: UpdateApplicationRequest,
  ) => Promise<ComboApplication | undefined> | null;
  updateSalesReps: (
    id: number,
    payload: UpdateSalesRepsRequest,
  ) => Promise<ComboApplication | undefined> | null;
  loadStaticLists: () => void;
  updateOutletDetails: (
    applicationId: number,
    payload: UpdateOutletDetailsRequest,
  ) => void;
  updateOutletSales: (
    applicationId: number,
    payload: UpdateOutletSalesRequest,
  ) => Promise<ComboApplication | undefined> | null;
  updateBillingDetails: (
    id: number,
    payload: UpdateBillingDetailsRequest,
  ) => Promise<ComboApplication | undefined> | null;
  updateOrderDetails: (
    id: number,
    payload: UpdateOrderDetailsRequest,
  ) => Promise<ComboApplication | undefined> | null;
  updateAdminDetails: (
    id: number,
    payload: UpdateAdminDetailsRequest,
  ) => Promise<ComboApplication | undefined> | null;
  updateAdditionalInfo: (
    id: number,
    payload: UpdateAdditionalInfoRequest,
  ) => Promise<ComboApplication | undefined> | null;
  updateOffers: (
    id: number,
    payload: UpdateOffersRequest,
  ) => Promise<ComboApplication | undefined> | null;
  updateHeinekenDirect: (
    applicationId: number,
    heinekenDirectId: number,
    payload: CreateHeinekenDirectRequest,
  ) => Promise<ComboApplication | undefined> | null;
  createApplicant: (
    id: number,
    payload: ApplicantRequest,
  ) => Promise<ComboApplication> | null;
  deleteApplicant: (
    applicationId: number,
    applicantId: number,
  ) => Promise<ComboApplication> | null;
  deleteHeinekenDirect: (
    applicationId: number,
    heinekenDirectId: number,
  ) => Promise<ComboApplication> | null;
  updateApplicant: (
    applicationId: number,
    applicantId: number,
    payload: ApplicantRequest,
  ) => Promise<ComboApplication> | null;
  submitApplication: (
    applicationId: number,
    payload: SubmitApplicationRequest,
  ) => Promise<ComboApplication> | null;
  requestAccountNumbers: (
    applicationId: number,
    payload: AccountNumbersRequest,
  ) => Promise<ComboApplication> | null;
  completeApplication: (
    applicationId: number,
    payload: CompleteApplicationRequest,
  ) => Promise<ComboApplication> | null;
  reopenApplication: (
    applicationId: number,
  ) => Promise<ComboApplication> | null;
  deleteApplication: (
    applicationId: number,
    payload: DeleteAppicationRequest,
  ) => Promise<ComboApplication | undefined> | null;
  toggleApplication: (
    applicationId: number,
    payload: ToggleApplicationRequest,
    //TODO: Add combo type and remove SD
  ) => Promise<SDApplication | Application | undefined> | null;
  endpointUrl: string;
  setEndpointUrl: (val: string) => void;
  updateInnserveDetails: (
    applicationId: number,
    payload: InnserveDetailsRequest,
  ) => Promise<ComboApplication> | null;
  createSigningRequest: (
    applicationId: number,
    applicantId: number,
  ) => Promise<ComboApplication> | null;
  deleteBar: (
    applicationId: number,
    applicantId: number,
  ) => Promise<ComboApplication> | null;
  createBar: (
    id: number,
    payload: CreateBarRequest,
  ) => Promise<ComboApplication> | null;
  updateBar: (
    applicationId: number,
    barId: number,
    payload: CreateBarRequest,
  ) => Promise<ComboApplication> | null;
  updateContractInfo: (
    applicationId: number,
    payload: UpdateContractInfoRequest,
  ) => Promise<ComboApplication> | null;
  skipPersonalGuarantee: (
    applicationId: number,
    payload: SkipPersonalGuaranteeSignature,
  ) => void;
  skipDirectDebit: (
    applicationId: number,
    payload: SkipDirectDebitSignature,
    ) => void;
    requestContractSignature: (
      applicationId: number,
    ) => Promise<ComboApplication | undefined> | null;
}

interface NetworkState {
  findApplication: boolean;
  keyContacts: boolean;
  loadStatusReasons: boolean;
  contactDetails: boolean;
  updateApplication: boolean;
  updateSalesReps: boolean;
  loadStaticLists: boolean;
  loadApplicationComments: boolean;
  loadApplicationMentionUsers: boolean;
  updateOutletDetails: boolean;
  updateOutletSales: boolean;
  updateBillingDetails: boolean;
  updateOrderDetails: boolean;
  createNote: boolean;
  loadAdminStaticLists: boolean;
  loadOutletVolume: boolean;
  loadOutletEstimatedSpend: boolean;
  updateAdminDetails: boolean;
  updateAdditionalInfo: boolean;
  updateOffers: boolean;
  createApplicant: boolean;
  loadHeinekenDirectRoles: boolean;
  createHeinekenDirect: boolean;
  updateHeinekenDirect: boolean;
  deleteHeinekenDirect: boolean;
  deleteApplicant: boolean;
  updateApplicant: boolean;
  submitApplication: boolean;
  requestAccountNumbers: boolean;
  completeApplication: boolean;
  reopenApplication: boolean;
  deleteApplication: boolean;
  toggleApplication: boolean;
  updateInnserveDetails: boolean;
  createSigningRequest: boolean;
  deleteBar: boolean;
  createBar: boolean;
  updateBar: boolean;
  updateContractInfo: boolean;
  skipPersonalGuarantee: boolean;
  skipDirectDebit: boolean;
  requestContractSignature: boolean;
}

const initNetworkState: NetworkState = {
  findApplication: false,
  keyContacts: false,
  loadStatusReasons: false,
  contactDetails: false,
  updateApplication: false,
  updateSalesReps: false,
  loadStaticLists: false,
  loadApplicationComments: false,
  loadApplicationMentionUsers: false,
  updateOutletDetails: false,
  updateOutletSales: false,
  updateBillingDetails: false,
  updateOrderDetails: false,
  createNote: false,
  loadAdminStaticLists: false,
  loadOutletVolume: false,
  loadOutletEstimatedSpend: false,
  updateAdminDetails: false,
  updateAdditionalInfo: false,
  updateOffers: false,
  createApplicant: false,
  loadHeinekenDirectRoles: false,
  createHeinekenDirect: false,
  updateHeinekenDirect: false,
  deleteHeinekenDirect: false,
  deleteApplicant: false,
  updateApplicant: false,
  submitApplication: false,
  requestAccountNumbers: false,
  completeApplication: false,
  reopenApplication: false,
  deleteApplication: false,
  toggleApplication: false,
  updateInnserveDetails: false,
  createSigningRequest: false,
  deleteBar: false,
  createBar: false,
  updateBar: false,
  updateContractInfo: false,
  skipPersonalGuarantee: false,
  skipDirectDebit: false,
  requestContractSignature: false,
};

const ComboApplicationDetailContext =
  createContext<ComboApplicationContextInterface>({
    initialised: false,
    error: initNetworkState,
    loading: { ...initNetworkState, findApplication: true },
    keyContacts: [],
    currentApplication: null,
    innserveEmails: [],
    applicationComments: [],
    applicationMentionUsers: [],
    abortReasons: [],
    regions: [],
    legalStatuses: [],
    outletSegments: [],
    contactDetails: [],
    clusters: [],
    classifications: [],
    pricingSectors: [],
    heinekenDirectRoles: [],
    outletVolumes: [],
    outletEstimatedSpends: [],
    personas: [],
    innovativeSolutions: [],
    loadStatusReasons: (status: string) => null,
    loadHeinekenDirectRoles: () => null,
    loadOutletVolume: () => null,
    loadOutletEstimatedSpend: () => null,
    loadAdminStaticLists: () => null,
    loadKeyContacts: () => null,
    loadContactDetails: (id: number) => null,
    loadApplicationComments: (id: number) => null,
    loadApplicationMentionUsers: (id: number) => null,
    setCurrentApplication: (application: ComboApplication | null) => null,
    createNote: (id: number, payload: CreateNoteRequest) => null,
    createHeinekenDirect: (id: number, payload: CreateHeinekenDirectRequest) =>
      null,
    findApplication: (id: number) => null,
    updateApplication: (id: number, payload: UpdateApplicationRequest) => null,
    updateSalesReps: (id: number, payload: UpdateSalesRepsRequest) => null,
    loadStaticLists: () => null,
    updateOutletDetails: (
      applicationId: number,
      payload: UpdateOutletDetailsRequest,
    ) => null,
    updateOutletSales: (
      applicationId: number,
      payload: UpdateOutletSalesRequest,
    ) => null,
    updateBillingDetails: (id: number, payload: UpdateBillingDetailsRequest) =>
      null,
    updateOrderDetails: (id: number, payload: UpdateOrderDetailsRequest) =>
      null,
    updateAdminDetails: (id: number, payload: UpdateAdminDetailsRequest) =>
      null,
    updateAdditionalInfo: (id: number, payload: UpdateAdditionalInfoRequest) =>
      null,
    updateOffers: (id: number, payload: UpdateOffersRequest) => null,
    updateHeinekenDirect: (
      applicationId: number,
      heinekenDirectId: number,
      payload: CreateHeinekenDirectRequest,
    ) => null,
    createApplicant: (id: number, payload: ApplicantRequest) => null,
    deleteApplicant: (applicationId: number, applicantId: number) => null,
    deleteHeinekenDirect: (applicationId: number, heinekenDirectId: number) =>
      null,
    updateApplicant: (
      applicationId: number,
      applicantId: number,
      payload: ApplicantRequest,
    ) => null,
    submitApplication: (
      applicationId: number,
      payload: SubmitApplicationRequest,
    ) => null,
    requestAccountNumbers: (
      applicationId: number,
      payload: AccountNumbersRequest,
    ) => null,
    completeApplication: (
      applicationId: number,
      payload: CompleteApplicationRequest,
    ) => null,
    reopenApplication: (applicationId: number) => null,
    deleteApplication: (
      applicationId: number,
      payload: DeleteAppicationRequest,
    ) => null,
    toggleApplication: (
      applicationId: number,
      payload: ToggleApplicationRequest,
    ) => null,
    endpointUrl: '',
    setEndpointUrl: () => null,
    updateInnserveDetails: (
      applicationId: number,
      payload: InnserveDetailsRequest,
    ) => null,
    createSigningRequest: (applicationId: number, applicantId: number) => null,
    deleteBar: (applicationId: number, barId: number) => null,
    createBar: (id: number, payload: CreateBarRequest) => null,
    updateBar: (
      applicationId: number,
      barId: number,
      payload: CreateBarRequest,
    ) => null,
    updateContractInfo: (
      applicationId: number,
      payload: UpdateContractInfoRequest,
    ) => null,
    skipPersonalGuarantee: (
      applicationId: number,
      payload: SkipPersonalGuaranteeSignature,
    ) => null,
    skipDirectDebit: (
      applicationId: number,
      payload: SkipDirectDebitSignature,
    ) => null,
    requestContractSignature: (applicationId: number) => null,
  });

export const Consume: () => ComboApplicationContextInterface = () => {
  const consumer = React.useContext(ComboApplicationDetailContext);

  // Condition used to determine if Context Provider has been declared
  if (consumer.initialised === false) {
    throw new Error('ComboApplicationDetailContextProvider not initialised');
  }
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [error, setError] = useState<NetworkState>(initNetworkState);
  const [loading, setLoading] = useState<NetworkState>({
    ...initNetworkState,
    findApplication: true,
  });

  const [currentApplication, setCurrentApplication] =
    useState<ComboApplication | null>(null);

  const [keyContacts, setKeyContacts] = useState<KeyContact[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [outletSegments, setOutletSegments] = useState<OutletSegment[]>([]);
  const [legalStatuses, setLegalStatuses] = useState<LegalStatus[]>([]);
  const [contactDetails, setContactDetails] = useState<Contact[]>([]);
  const [applicationComments, setApplicationComments] = useState<Comment[]>([]);
  const [applicationMentionUsers, setApplicationMentionUsers] = useState<
    Mention[]
  >([]);
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [classifications, setClassifications] = useState<Classification[]>([]);
  const [pricingSectors, setPricingSectors] = useState<PricingSector[]>([]);
  const [heinekenDirectRoles, setHeinekenDirectRoles] = useState<
    HeinekenDirectRole[]
  >([]);
  const [outletVolumes, setOutletVolumes] = useState<OutletVolume[]>([]);
  const [outletEstimatedSpends, setOutletEstimatedSpends] = useState<
    OutletEstimatedSpend[]
  >([]);
  const [abortReasons, setAbortReasons] = useState<StatusReason[]>([]);
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [innovativeSolutions, setInnovativeSolutions] = useState<
    InnovativeSolution[]
  >([]);
  const [endpointUrl, setEndpointUrl] = useState<string>('');
  const [innserveEmails, setInnserveEmails] = useState<InnserveEmail[]>([]);

  const loadStatusReasons = async (status: string) => {
    try {
      setLoading({ ...loading, loadStatusReasons: true });
      const abortReasons = await StatusReasonService.listStatusReasons(status);
      setAbortReasons(abortReasons);
      setError({ ...error, loadStatusReasons: false });
    } catch (e) {
      setError({ ...error, loadStatusReasons: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadStatusReasons: false });
    }
  };

  const loadKeyContacts = async () => {
    try {
      setLoading({ ...loading, keyContacts: true });
      setError({ ...error, keyContacts: false });
      const keyContacts = await KeyContactService.listKeyContacts();
      setKeyContacts(keyContacts);
    } catch (e) {
      setError({ ...error, keyContacts: true });
      throw e;
    } finally {
      setLoading({ ...loading, keyContacts: false });
    }
  };

  const loadContactDetails = async (id: number) => {
    try {
      setError({ ...error, contactDetails: false });
      setLoading({ ...loading, contactDetails: true });
      const contactDetails = await ComboApplicationService.listContactDetails(
        id,
        endpointUrl,
      );
      setContactDetails(contactDetails);
    } catch (e) {
      setError({ ...error, contactDetails: true });
      throw e;
    } finally {
      setLoading({ ...loading, contactDetails: false });
    }
  };

  const loadApplicationComments = async (id: number): Promise<Comment[]> => {
    try {
      setError({ ...error, loadApplicationComments: false });
      setLoading({ ...loading, loadApplicationComments: true });
      const comments = await ComboApplicationService.loadApplicationComments(
        id,
      );
      setApplicationComments(comments);
      return comments;
    } catch (e) {
      setError({ ...error, loadApplicationComments: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadApplicationComments: false });
    }
  };

  const loadApplicationMentionUsers = async (
    id: number,
  ): Promise<Mention[]> => {
    try {
      setError({ ...error, loadApplicationMentionUsers: false });
      setLoading({ ...loading, loadApplicationMentionUsers: true });
      const mentionUsers =
        await ComboApplicationService.loadApplicationMentionUsers(id);
      setApplicationMentionUsers(mentionUsers);
      return mentionUsers;
    } catch (e) {
      setError({ ...error, loadApplicationMentionUsers: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadApplicationMentionUsers: false });
    }
  };

  const loadHeinekenDirectRoles = async () => {
    try {
      setError({ ...error, loadHeinekenDirectRoles: false });
      setLoading({ ...loading, loadHeinekenDirectRoles: true });
      const heinekenDirectRoles =
        await HeinekenDirectRoleService.listHeinekenDirectRoles();
      setHeinekenDirectRoles(heinekenDirectRoles);
    } catch (e) {
      setError({ ...error, loadHeinekenDirectRoles: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadHeinekenDirectRoles: false });
    }
  };

  const findApplication = async (
    id: number,
  ): Promise<ComboApplication | undefined> => {
    try {
      setError({ ...error, findApplication: false });
      setLoading({ ...loading, findApplication: true });
      const application = await ComboApplicationService.findApplication(
        id,
        endpointUrl,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, findApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, findApplication: false });
    }
  };

  const updateApplication = async (
    id: number,
    payload: UpdateApplicationRequest,
  ) => {
    try {
      setError({ ...error, updateApplication: false });
      setLoading({ ...loading, updateApplication: true });
      const application = await ComboApplicationService.updateApplication(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateApplication: false });
    }
  };

  const updateSalesReps = async (
    id: number,
    payload: UpdateSalesRepsRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateSalesReps: false });
      setLoading({ ...loading, updateSalesReps: true });
      const application = await ComboApplicationService.updateSalesReps(
        id,
        payload,
      );
      // If the updated application is the current application, then update local state with new values
      if (
        currentApplication !== null &&
        application.id === currentApplication.id
      ) {
        setCurrentApplication(application);
      }
      return application;
    } catch (e) {
      setError({ ...error, updateSalesReps: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateSalesReps: false });
    }
  };

  const loadStaticLists = async () => {
    try {
      setLoading({ ...loading, loadStaticLists: true });
      const [
        regions,
        legalStatuses,
        outletSegments,
        innovativeSolutions,
        innserveEmails,
      ] = (await axios.all([
        RegionService.listRegions(),
        LegalStatusService.listLegalStatuses(),
        OutletSegmentService.listOutletSegments(),
        InnovativeSolutionService.listInnovativeSolutions(),
        InnserveEmailsService.listInnserveEmails(),
      ])) as [
        Region[],
        LegalStatus[],
        OutletSegment[],
        InnovativeSolution[],
        InnserveEmail[],
      ];
      setRegions(regions);
      setLegalStatuses(legalStatuses);
      setOutletSegments(outletSegments);
      setInnovativeSolutions(innovativeSolutions);
      setInnserveEmails(innserveEmails);
      setError({ ...error, loadStaticLists: false });
    } catch (e) {
      setError({ ...error, loadStaticLists: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadStaticLists: false });
    }
  };

  const loadAdminStaticLists = async () => {
    try {
      setError({ ...error, loadAdminStaticLists: false });
      setLoading({ ...loading, loadAdminStaticLists: true });
      const [clusters, classifications, pricingSectors, personas] =
        (await axios.all([
          ClusterService.listClusters(),
          ClassificationService.listClassifications(),
          PricingSectorService.listPricingSectors(),
          PersonaService.listPersonas(),
        ])) as [Cluster[], Classification[], PricingSector[], Persona[]];
      setClusters(clusters);
      setClassifications(classifications);
      setPricingSectors(pricingSectors);
      setPersonas(personas);
    } catch (e) {
      setError({ ...error, loadAdminStaticLists: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadAdminStaticLists: false });
    }
  };

  const loadOutletVolume = async () => {
    try {
      setLoading({ ...loading, loadOutletVolume: true });
      const outletVolumes = await OutletVolumeService.listOutletVolumes();
      setOutletVolumes(outletVolumes);
      setError({ ...error, loadOutletVolume: false });
    } catch (e) {
      setError({ ...error, loadOutletVolume: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadOutletVolume: false });
    }
  };

  const loadOutletEstimatedSpend = async () => {
    try {
      setLoading({ ...loading, loadOutletEstimatedSpend: true });
      const outletEstimatedSpends =
        await OutletEstimatedSpendService.listOutletEstimatedSpends();
      setOutletEstimatedSpends(outletEstimatedSpends);
      setError({ ...error, loadOutletEstimatedSpend: false });
    } catch (e) {
      setError({ ...error, loadOutletEstimatedSpend: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadOutletEstimatedSpend: false });
    }
  };

  const updateOutletDetails = async (
    applicationId: number,
    payload: UpdateOutletDetailsRequest,
  ) => {
    try {
      setLoading({ ...loading, updateOutletDetails: true });
      const application = await ComboApplicationService.updateOutletDetails(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      setError({ ...error, updateOutletDetails: false });
    } catch (e) {
      setError({ ...error, updateOutletDetails: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateOutletDetails: false });
    }
  };

  const updateOutletSales = async (
    applicationId: number,
    payload: UpdateOutletSalesRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateOutletSales: false });
      setLoading({ ...loading, updateOutletSales: true });
      const application = await ComboApplicationService.updateOutletSales(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateOutletSales: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateOutletSales: false });
    }
  };

  const updateBillingDetails = async (
    id: number,
    payload: UpdateBillingDetailsRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateBillingDetails: false });
      setLoading({ ...loading, updateBillingDetails: true });
      const application = await ComboApplicationService.updateBillingDetails(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateBillingDetails: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateBillingDetails: false });
    }
  };
  const updateOrderDetails = async (
    id: number,
    payload: UpdateOrderDetailsRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateOrderDetails: false });
      setLoading({ ...loading, updateOrderDetails: true });
      const application = await ComboApplicationService.updateOrderDetails(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateOrderDetails: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateOrderDetails: false });
    }
  };

  const updateAdminDetails = async (
    id: number,
    payload: UpdateAdminDetailsRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateAdminDetails: false });
      setLoading({ ...loading, updateAdminDetails: true });
      const application = await ComboApplicationService.updateAdminDetails(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateAdminDetails: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateAdminDetails: false });
    }
  };

  const updateAdditionalInfo = async (
    id: number,
    payload: UpdateAdditionalInfoRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateAdditionalInfo: false });
      setLoading({ ...loading, updateAdditionalInfo: true });
      const application = await ComboApplicationService.updateAdditionalInfo(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateAdditionalInfo: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateAdditionalInfo: false });
    }
  };

  const updateOffers = async (
    id: number,
    payload: UpdateOffersRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateOffers: false });
      setLoading({ ...loading, updateOffers: true });
      const application = await ComboApplicationService.updateOffers(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateOffers: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateOffers: false });
    }
  };

  const updateHeinekenDirect = async (
    applicationId: number,
    heinekenDirectId: number,
    payload: CreateHeinekenDirectRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateHeinekenDirect: false });
      setLoading({ ...error, updateHeinekenDirect: true });
      const application = await ComboApplicationService.updateHeinekenDirect(
        applicationId,
        heinekenDirectId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateHeinekenDirect: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateHeinekenDirect: false });
    }
  };

  const createNote = async (
    id: number,
    payload: CreateNoteRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, createNote: false });
      setLoading({ ...loading, createNote: true });
      await ComboApplicationService.createNote(id, payload);
      const application = await ComboApplicationService.findApplication(
        id,
        endpointUrl,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createNote: true });
      throw e;
    } finally {
      setLoading({ ...loading, createNote: false });
    }
  };

  const createHeinekenDirect = async (
    id: number,
    payload: CreateHeinekenDirectRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, createHeinekenDirect: false });
      setLoading({ ...loading, createHeinekenDirect: true });
      const application = await ComboApplicationService.createHeinekenDirect(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createHeinekenDirect: true });
      throw e;
    } finally {
      setLoading({ ...loading, createHeinekenDirect: false });
    }
  };

  const createApplicant = async (
    id: number,
    payload: ApplicantRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, createApplicant: false });
      setLoading({ ...loading, createApplicant: true });
      const application = await ComboApplicationService.createApplicant(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createApplicant: true });
      throw e;
    } finally {
      setLoading({ ...loading, createApplicant: false });
    }
  };

  const deleteHeinekenDirect = async (
    applicationId: number,
    heinekenDirectId: number,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, deleteHeinekenDirect: false });
      setLoading({ ...loading, deleteHeinekenDirect: true });
      const application = await ComboApplicationService.deleteHeinekenDirect(
        applicationId,
        heinekenDirectId,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, deleteHeinekenDirect: false });
      throw e;
    } finally {
      setLoading({ ...loading, deleteHeinekenDirect: false });
    }
  };

  const deleteApplicant = async (
    applicationId: number,
    applicantId: number,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, deleteApplicant: false });
      setLoading({ ...loading, deleteApplicant: true });
      const application = await ComboApplicationService.deleteApplicant(
        applicationId,
        applicantId,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, deleteApplicant: false });
      throw e;
    } finally {
      setLoading({ ...loading, deleteApplicant: false });
    }
  };

  const updateApplicant = async (
    applicationId: number,
    applicantId: number,
    payload: ApplicantRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateApplicant: false });
      setLoading({ ...loading, updateApplicant: true });
      const application = await ComboApplicationService.updateApplicant(
        applicationId,
        applicantId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateApplicant: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateApplicant: false });
    }
  };

  const submitApplication = async (
    applicationId: number,
    payload: SubmitApplicationRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, submitApplication: false });
      setLoading({ ...loading, submitApplication: true });
      const application = await ComboApplicationService.submitApplication(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, submitApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, submitApplication: false });
    }
  };

  const requestAccountNumbers = async (
    applicationId: number,
    payload: AccountNumbersRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, requestAccountNumbers: false });
      setLoading({ ...loading, requestAccountNumbers: true });
      const application = await ComboApplicationService.requestAccountNumbers(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, requestAccountNumbers: true });
      throw e;
    } finally {
      setLoading({ ...loading, requestAccountNumbers: false });
    }
  };

  const completeApplication = async (
    applicationId: number,
    payload: CompleteApplicationRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, completeApplication: false });
      setLoading({ ...loading, completeApplication: true });
      const application = await ComboApplicationService.completeApplication(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, completeApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, completeApplication: false });
    }
  };

  const reopenApplication = async (
    applicationId: number,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, reopenApplication: false });
      setLoading({ ...loading, reopenApplication: true });
      const application = await ComboApplicationService.reopenApplication(
        applicationId,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, reopenApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, reopenApplication: false });
    }
  };

  const deleteApplication = async (
    applicationId: number,
    payload: DeleteAppicationRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, deleteApplication: false });
      setLoading({ ...loading, deleteApplication: true });
      const application = await ComboApplicationService.deleteApplication(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, deleteApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, deleteApplication: false });
    }
  };

  const toggleApplication = async (
    applicationId: number,
    payload: ToggleApplicationRequest,
  ): Promise<SDApplication | Application> => {
    try {
      setError({ ...error, toggleApplication: false });
      setLoading({ ...loading, toggleApplication: true });
      const application = await ComboApplicationService.toggleApplication(
        applicationId,
        payload,
      );
      return application;
    } catch (e) {
      setError({ ...error, toggleApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, toggleApplication: false });
    }
  };

  const updateInnserveDetails = async (
    applicationId: number,
    payload: InnserveDetailsRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateInnserveDetails: false });
      setLoading({ ...loading, updateInnserveDetails: true });
      const application = await ComboApplicationService.updateInnserveDetails(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateInnserveDetails: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateInnserveDetails: false });
    }
  };

  const updateContractInfo = async (
    applicationId: number,
    payload: UpdateContractInfoRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateContractInfo: false });
      setLoading({ ...loading, updateContractInfo: true });
      const application = await ComboApplicationService.updateContractInfo(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateContractInfo: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateContractInfo: false });
    }
  };

  const createSigningRequest = async (
    applicationId: number,
    applicantId: number,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, createSigningRequest: false });
      setLoading({ ...loading, createSigningRequest: true });
      const application = await ComboSigningService.createSigningRequest(
        applicationId,
        applicantId,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createSigningRequest: true });
      throw e;
    } finally {
      setLoading({ ...loading, createSigningRequest: false });
    }
  };

  const deleteBar = async (
    applicationId: number,
    barId: number,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, deleteBar: false });
      setLoading({ ...loading, deleteBar: true });
      const application = await ComboApplicationService.deleteBar(
        applicationId,
        barId,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, deleteApplicant: false });
      throw e;
    } finally {
      setLoading({ ...loading, deleteApplicant: false });
    }
  };

  const createBar = async (
    id: number,
    payload: CreateBarRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, createBar: false });
      setLoading({ ...loading, createBar: true });
      const application = await ComboApplicationService.createBar(id, payload);
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createBar: true });
      throw e;
    } finally {
      setLoading({ ...loading, createBar: false });
    }
  };

  const updateBar = async (
    applicationId: number,
    barId: number,
    payload: CreateBarRequest,
  ): Promise<ComboApplication> => {
    try {
      setError({ ...error, updateBar: false });
      setLoading({ ...loading, updateBar: true });
      const application = await ComboApplicationService.updateBar(
        applicationId,
        barId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateBar: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateBar: false });
    }
  };

  const skipPersonalGuarantee = async (
    applicationId: number,
    payload: SkipPersonalGuaranteeSignature,
  ) => {
    try {
      setLoading({ ...loading, skipPersonalGuarantee: true });
      setError({ ...error, skipPersonalGuarantee: false });
      const application =
        await ComboHGSSPersonalGuaranteeService.skipPersonalGuaranteeSignature(
          applicationId,
          payload,
        );
      setCurrentApplication(application);
      return application;
    } catch (error) {
      setError({ ...error, skipPersonalGuarantee: true });
      throw error;
    } finally {
      setLoading({
        ...loading,
        skipPersonalGuarantee: false,
      });
    }
  };

  const skipDirectDebit = async (
    applicationId: number,
    payload: SkipDirectDebitSignature,
  ) => {
    try {
      setLoading({ ...loading, skipDirectDebit: true });
      setError({ ...error, skipDirectDebit: false });
      const application = await HGSSDirectDebitService.skipDirectDebitSignature(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (error) {
      setError({ ...error, skipDirectDebit: true });
      throw error;
    } finally {
      setLoading({
        ...loading,
        skipDirectDebit: false,
      });
    }
  };

  const requestContractSignature = async (
    applicationId: number,
  ): Promise<ComboApplication> => {
    try {
      setLoading({ ...loading, requestContractSignature: true });
      setError({ ...error, requestContractSignature: false });
      const application = await ComboContractService.requestContractSignature(
        applicationId,
      );
      setCurrentApplication(application);
      return application;
    } catch (error) {
      setError({ ...error, requestContractSignature: true });
      throw error;
    } finally {
      setLoading({
        ...loading,
        requestContractSignature: false,
      });
    }
  };


  return (
    <ComboApplicationDetailContext.Provider
      value={{
        initialised: true,
        error,
        loading,
        keyContacts,
        contactDetails,
        currentApplication,
        applicationComments,
        applicationMentionUsers,
        abortReasons,
        loadStatusReasons,
        regions,
        legalStatuses,
        outletSegments,
        innovativeSolutions,
        clusters,
        classifications,
        pricingSectors,
        heinekenDirectRoles,
        setCurrentApplication,
        outletVolumes,
        outletEstimatedSpends,
        personas,
        loadOutletVolume,
        loadOutletEstimatedSpend,
        loadKeyContacts,
        loadContactDetails,
        loadApplicationComments,
        loadApplicationMentionUsers,
        findApplication,
        updateApplication,
        updateSalesReps,
        loadStaticLists,
        updateOutletDetails,
        updateOutletSales,
        updateBillingDetails,
        updateOrderDetails,
        updateHeinekenDirect,
        createNote,
        loadAdminStaticLists,
        updateAdminDetails,
        updateAdditionalInfo,
        updateOffers,
        createApplicant,
        loadHeinekenDirectRoles,
        createHeinekenDirect,
        deleteHeinekenDirect,
        deleteApplicant,
        updateApplicant,
        submitApplication,
        requestAccountNumbers,
        completeApplication,
        reopenApplication,
        deleteApplication,
        toggleApplication,
        endpointUrl,
        setEndpointUrl,
        updateInnserveDetails,
        createSigningRequest,
        deleteBar,
        createBar,
        updateBar,
        innserveEmails,
        updateContractInfo,
        skipPersonalGuarantee,
        skipDirectDebit,
        requestContractSignature
      }}
    >
      {children}
    </ComboApplicationDetailContext.Provider>
  );
};
