import React, { useState, createContext } from 'react';
import SDApplicationManagementService from 'services/SDApplicationManagementService';
import SDHGSSAdminService from 'services/SDHGSSAdminService';
import SDHGSSAdditionalIdService from 'services/SDHGSSAdditionalIdService';
import SDHGSSPersonalGuaranteeService from 'services/SDHGSSPersonalGuaranteeService';
import ApplicationMilestones from 'models/ApplicationMilestones';
import ApplicationHGSSAdmin from 'models/ApplicationHGSSAdmin';
import ApplicationHGSSAdditionalId from 'models/ApplicationHGSSAdditionalId';
import { ApplicationHGSSDirectDebit } from 'models/ApplicationHGSSDirectDebit';
import ApplicationHGSSPersonalGuarantee from 'models/ApplicationHGSSPersonalGuarantee';
import { UpdateApplicationHGSSDirectDebitRequest } from 'services/SDHGSSDirectDebitService/requests/UpdateApplicationHGSSDirectDebitRequest';
import UpdateSDApplicationTaskRequest from 'services/SDApplicationManagementService/requests/UpdateSDApplicationTaskRequest';
import UpdateSDApplicationFlagRequest from 'services/SDApplicationManagementService/requests/UpdateSDApplicationFlagRequest';
import UpdateSDApplicationKeyDateRequest from 'services/SDApplicationManagementService/requests/UpdateSDApplicationKeyDateRequest';
import UpdateSDApplicationHGSSAdditionalIdRequest from 'services/SDHGSSAdditionalIdService/requests/UpdateSDApplicationHGSSAdditionalIdRequest';
import UpdateSDApplicationHGSSPersonalGuaranteeRequest from 'services/SDHGSSPersonalGuaranteeService/requests/UpdateSDApplicationHGSSPersonalGuaranteeRequest';
import { HGSSDirectDebitService } from 'services/SDHGSSDirectDebitService';

interface SDApplicationManagementInterface {
  initialised: boolean;
  error: NetworkState;
  loading: NetworkState;
  milestones: ApplicationMilestones | null;
  directDebitContacts: ApplicationHGSSDirectDebit[] | null;
  hgssAdmin: ApplicationHGSSAdmin | null;
  additionalIds: ApplicationHGSSAdditionalId[] | null;
  personalGuarantees: ApplicationHGSSPersonalGuarantee[] | null;
  loadMilestones: (applicationId: number) => void;
  loadHGSSAdmin: (applicationId: number) => void;
  loadApplicationHGSSAdditionalIds: (applicationId: number) => void;
  loadApplicationHGSSPersonalGuarantees: (applicationId: number) => void;
  loadApplicationHGSSDirectDebit: (applicationId: number) => void;
  updateTask: (
    applicationId: number,
    taskId: number,
    payload: UpdateSDApplicationTaskRequest,
  ) => void;
  updateFlag: (
    applicationId: number,
    flagId: number,
    payload: UpdateSDApplicationFlagRequest,
  ) => void;
  updateKeyDate: (
    applicationId: number,
    keyDateId: number,
    payload: UpdateSDApplicationKeyDateRequest,
  ) => void;
  updateHGSSAdmin: (
    applicationId: number,
    payload: ApplicationHGSSAdmin,
  ) => void;
  updateApplicationHGSSAdditionalIds: (
    applicationId: number,
    payload: UpdateSDApplicationHGSSAdditionalIdRequest,
  ) => void;
  updateApplicationHGSSPersonalGuarantees: (
    applicationId: number,
    payload: UpdateSDApplicationHGSSPersonalGuaranteeRequest,
  ) => void;
  updateApplicationHGSSDirectDebit: (
    applicationId: number,
    payload: UpdateApplicationHGSSDirectDebitRequest,
  ) => void;
}

interface NetworkState {
  loadMilestones: boolean;
  loadHGSSAdmin: boolean;
  loadApplicationHGSSAdditionalIds: boolean;
  loadApplicationHGSSPersonalGuarantees: boolean;
  loadApplicationHGSSDirectDebit: boolean;
  updateTask: boolean;
  updateFlag: boolean;
  updateKeyDate: boolean;
  updateHGSSAdmin: boolean;
  updateApplicationHGSSAdditionalIds: boolean;
  updateApplicationHGSSPersonalGuarantees: boolean;
  updateApplicationHGSSDirectDebit: boolean;
}

const initNetworkState: NetworkState = {
  loadMilestones: false,
  loadHGSSAdmin: false,
  loadApplicationHGSSAdditionalIds: false,
  loadApplicationHGSSDirectDebit: false,
  loadApplicationHGSSPersonalGuarantees: false,
  updateTask: false,
  updateFlag: false,
  updateKeyDate: false,
  updateHGSSAdmin: false,
  updateApplicationHGSSAdditionalIds: false,
  updateApplicationHGSSPersonalGuarantees: false,
  updateApplicationHGSSDirectDebit: false,
};

const SDApplicationManagementContext =
  createContext<SDApplicationManagementInterface>({
    initialised: false,
    loading: initNetworkState,
    error: initNetworkState,
    milestones: null,
    hgssAdmin: null,
    directDebitContacts: null,
    additionalIds: null,
    personalGuarantees: null,
    loadMilestones: (applicationId: number) => null,
    loadHGSSAdmin: (applicationId: number) => null,
    loadApplicationHGSSAdditionalIds: (applicationId: number) => null,
    loadApplicationHGSSPersonalGuarantees: (applicationId: number) => null,
    loadApplicationHGSSDirectDebit: (applicationId: number) => null,
    updateTask: (
      applicationId: number,
      taskId: number,
      payload: UpdateSDApplicationTaskRequest,
    ) => null,
    updateFlag: (
      applicationId: number,
      flagId: number,
      payload: UpdateSDApplicationFlagRequest,
    ) => null,
    updateKeyDate: (
      applicationId: number,
      keyDateId: number,
      payload: UpdateSDApplicationKeyDateRequest,
    ) => null,
    updateHGSSAdmin: (applicationId: number, payload: ApplicationHGSSAdmin) =>
      null,
    updateApplicationHGSSAdditionalIds: (
      applicationId: number,
      payload: UpdateSDApplicationHGSSAdditionalIdRequest,
    ) => null,
    updateApplicationHGSSPersonalGuarantees: (
      applicationId: number,
      payload: UpdateSDApplicationHGSSPersonalGuaranteeRequest,
    ) => null,
    updateApplicationHGSSDirectDebit: (
      applicationId: number,
      payload: UpdateApplicationHGSSDirectDebitRequest,
    ) => null,
  });

export const Consume: () => SDApplicationManagementInterface = () => {
  const consumer = React.useContext(SDApplicationManagementContext);
  if (consumer.initialised === false) {
    throw new Error('SDApplicationManagementContext not initialised');
  }
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [error, setError] = useState<NetworkState>(initNetworkState);
  const [loading, setLoading] = useState<NetworkState>(initNetworkState);
  const [milestones, setMilestones] = useState<ApplicationMilestones | null>(
    null,
  );
  const [hgssAdmin, setHgssAdmin] = useState<ApplicationHGSSAdmin | null>(null);
  const [additionalIds, setAdditionalIds] = useState<
    ApplicationHGSSAdditionalId[] | null
  >(null);
  const [personalGuarantees, setPersonalGuarantees] = useState<
    ApplicationHGSSPersonalGuarantee[] | null
  >(null);
  const [directDebitContacts, setDirectDebitContacts] = useState<
    ApplicationHGSSDirectDebit[] | null
  >(null);

  const loadMilestones = async (applicationId: number) => {
    try {
      setLoading({ ...loading, loadMilestones: true });
      setError({ ...error, loadMilestones: false });
      const milestones =
        await SDApplicationManagementService.getApplicationMilestones(
          applicationId,
        );
      setMilestones(milestones);
    } catch (error) {
      setError({ ...error, loadMilestones: true });
      throw error;
    } finally {
      setLoading({ ...loading, loadMilestones: false });
    }
  };

  const loadHGSSAdmin = async (applicationId: number) => {
    try {
      setLoading({ ...loading, loadHGSSAdmin: true });
      setError({ ...error, loadHGSSAdmin: false });
      const hgss = await SDHGSSAdminService.getHGSSAdmin(applicationId);
      setHgssAdmin(hgss);
    } catch (error) {
      setError({ ...error, loadHGSSAdmin: true });
      throw error;
    } finally {
      setLoading({ ...loading, loadHGSSAdmin: false });
    }
  };

  const loadApplicationHGSSAdditionalIds = async (applicationId: number) => {
    try {
      setLoading({ ...loading, loadApplicationHGSSAdditionalIds: true });
      setError({ ...error, loadApplicationHGSSAdditionalIds: false });
      const additionalIds =
        await SDHGSSAdditionalIdService.loadSDApplicationHGSSAdditionalIds(
          applicationId,
        );
      setAdditionalIds(additionalIds);
    } catch (error) {
      setError({ ...error, loadApplicationHGSSAdditionalIds: true });
      throw error;
    } finally {
      setLoading({ ...loading, loadApplicationHGSSAdditionalIds: false });
    }
  };

  const loadApplicationHGSSPersonalGuarantees = async (
    applicationId: number,
  ) => {
    try {
      setLoading({ ...loading, loadApplicationHGSSPersonalGuarantees: true });
      setError({ ...error, loadApplicationHGSSPersonalGuarantees: false });
      const personalGuarantees =
        await SDHGSSPersonalGuaranteeService.loadSDApplicationHGSSPersonalGuarantees(
          applicationId,
        );
      setPersonalGuarantees(personalGuarantees);
    } catch (error) {
      setError({ ...error, loadApplicationHGSSPersonalGuarantees: true });
      throw error;
    } finally {
      setLoading({ ...loading, loadApplicationHGSSPersonalGuarantees: false });
    }
  };

  const loadApplicationHGSSDirectDebit = async (applicationId: number) => {
    try {
      setLoading({ ...loading, loadApplicationHGSSDirectDebit: true });
      setError({ ...error, loadApplicationHGSSDirectDebit: false });
      const directDebitContacts =
        await HGSSDirectDebitService.loadApplicationHGSSDirectDebit(
          applicationId,
        );
      setDirectDebitContacts(directDebitContacts);
    } catch (error) {
      setError({ ...error, loadApplicationHGSSDirectDebit: true });
      throw error;
    } finally {
      setLoading({ ...loading, loadApplicationHGSSDirectDebit: false });
    }
  };

  const updateTask = async (
    applicationId: number,
    taskId: number,
    payload: UpdateSDApplicationTaskRequest,
  ) => {
    try {
      setLoading({ ...loading, updateTask: true });
      setError({ ...error, updateTask: false });
      const milestones =
        await SDApplicationManagementService.updateApplicationTask(
          applicationId,
          taskId,
          payload,
        );
      setMilestones(milestones);
    } catch (error) {
      setError({ ...error, updateTask: true });
      throw error;
    } finally {
      setLoading({ ...loading, updateTask: false });
    }
  };

  const updateFlag = async (
    applicationId: number,
    flagId: number,
    payload: UpdateSDApplicationFlagRequest,
  ) => {
    try {
      setLoading({ ...loading, updateFlag: true });
      setError({ ...error, updateFlag: false });
      const milestones =
        await SDApplicationManagementService.updateApplicationFlag(
          applicationId,
          flagId,
          payload,
        );
      setMilestones(milestones);
    } catch (error) {
      setError({ ...error, updateFlag: true });
      throw error;
    } finally {
      setLoading({ ...loading, updateFlag: false });
    }
  };

  const updateKeyDate = async (
    applicationId: number,
    keyDateId: number,
    payload: UpdateSDApplicationKeyDateRequest,
  ) => {
    try {
      setLoading({ ...loading, updateKeyDate: true });
      setError({ ...error, updateKeyDate: false });
      const milestones =
        await SDApplicationManagementService.updateApplicationKeyDate(
          applicationId,
          keyDateId,
          payload,
        );
      setMilestones(milestones);
    } catch (error) {
      setError({ ...error, updateKeyDate: true });
      throw error;
    } finally {
      setLoading({ ...loading, updateKeyDate: false });
    }
  };

  const updateHGSSAdmin = async (
    applicationId: number,
    payload: ApplicationHGSSAdmin,
  ) => {
    try {
      setLoading({ ...loading, updateKeyDate: true });
      setError({ ...error, updateKeyDate: false });
      const hgss = await SDHGSSAdminService.updateHGSSAdmin(
        applicationId,
        payload,
      );
      setHgssAdmin(hgss);
    } catch (error) {
      setError({ ...error, updateHGSSAdmin: true });
      throw error;
    } finally {
      setLoading({ ...loading, updateHGSSAdmin: false });
    }
  };

  const updateApplicationHGSSAdditionalIds = async (
    applicationId: number,
    payload: UpdateSDApplicationHGSSAdditionalIdRequest,
  ) => {
    try {
      setLoading({ ...loading, updateApplicationHGSSAdditionalIds: true });
      setError({ ...error, updateApplicationHGSSAdditionalIds: false });
      const additionalIds =
        await SDHGSSAdditionalIdService.updateSDApplicationHGSSAdditionalIds(
          applicationId,
          payload,
        );
      setAdditionalIds(additionalIds);
    } catch (error) {
      setError({ ...error, updateApplicationHGSSAdditionalIds: true });
      throw error;
    } finally {
      setLoading({ ...loading, updateApplicationHGSSAdditionalIds: false });
    }
  };

  const updateApplicationHGSSPersonalGuarantees = async (
    applicationId: number,
    payload: UpdateSDApplicationHGSSPersonalGuaranteeRequest,
  ) => {
    try {
      setLoading({ ...loading, updateApplicationHGSSPersonalGuarantees: true });
      setError({ ...error, updateApplicationHGSSPersonalGuarantees: false });
      const personalGuarantees =
        await SDHGSSPersonalGuaranteeService.updateSDApplicationHGSSPersonalGuarantees(
          applicationId,
          payload,
        );
      setPersonalGuarantees(personalGuarantees);
    } catch (error) {
      setError({ ...error, updateApplicationHGSSPersonalGuarantees: true });
      throw error;
    } finally {
      setLoading({
        ...loading,
        updateApplicationHGSSPersonalGuarantees: false,
      });
    }
  };

  const updateApplicationHGSSDirectDebit = async (
    applicationId: number,
    payload: UpdateApplicationHGSSDirectDebitRequest,
  ) => {
    try {
      setLoading({ ...loading, updateApplicationHGSSDirectDebit: true });
      setError({ ...error, updateApplicationHGSSDirectDebit: false });
      const directDebitContacts =
        await HGSSDirectDebitService.updateApplicationHGSSDirectDebit(
          applicationId,
          payload,
        );
      setDirectDebitContacts(directDebitContacts);
    } catch (error) {
      setError({ ...error, updateApplicationHGSSDirectDebit: true });
      throw error;
    } finally {
      setLoading({
        ...loading,
        updateApplicationHGSSDirectDebit: false,
      });
    }
  };

  return (
    <SDApplicationManagementContext.Provider
      value={{
        initialised: true,
        loading,
        error,
        milestones,
        hgssAdmin,
        additionalIds,
        directDebitContacts,
        personalGuarantees,
        loadMilestones,
        loadHGSSAdmin,
        loadApplicationHGSSAdditionalIds,
        loadApplicationHGSSDirectDebit,
        loadApplicationHGSSPersonalGuarantees,
        updateTask,
        updateFlag,
        updateKeyDate,
        updateHGSSAdmin,
        updateApplicationHGSSAdditionalIds,
        updateApplicationHGSSPersonalGuarantees,
        updateApplicationHGSSDirectDebit,
      }}
    >
      {children}
    </SDApplicationManagementContext.Provider>
  );
};
