import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { IconName } from '@blueprintjs/icons';
import { Icon } from '@blueprintjs/core';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Tooltip from 'components/common/Tooltip';
import ApplicationsSidebarMenu from 'components/Applications/ApplicationsSidebarMenu';
import { consume } from 'contexts/GlobalContext';
import PermissionHelper from 'utils/PermissionsHelper';
import { UrlHelper } from 'utils/UrlHelper';
import logo from 'assets/img/heineken-logo-white.png';

const SidebarContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  .sidebar {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 100vh;
    position: fixed;
    z-index: 40;
    background-color: ${theme.background.sidebar};
    .top {
      flex-grow: 1;
      .logo-container {
        padding: 1.5rem;
        img {
          width: 110px;
        }
      }
      .links-container {
        display: flex;
        flex-direction: column;
        .link-item {
          padding: 2rem 0 5px 1.5rem;
          border-bottom: 2px solid #086837;
          color: white;
          font-size: 18px;
        }
        a {
          padding: 1rem 1.5rem;
          color: white;
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: normal;
          letter-spacing: 1.14px;
          .bp3-icon {
            margin-right: 0.75rem;
          }
          &:hover {
            text-decoration: none;
            background-color: rgba(0, 157, 0, 0.5);
          }
          &.active {
            background-color: ${theme.cta.active};
          }
          &.disabled {
            cursor: default;
            pointer-events: none;
            &:hover {
              background-color: transparent;
            }
          }
          &:focus {
            outline: 0;
          }
          &.active-sublink {
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
      .sublink-tabs {
        a {
          padding-left: 4rem;
        }
      }
    }
    .bottom {
      padding: 1.25rem 0;
      .logout-button {
        padding: 1rem 1.5rem;
        margin: 0;
        border: 0;
        background: none;
        color: white;
        cursor: pointer;
        width: 100%;
        text-align: left;
        .bp3-icon {
          margin-right: 0.75rem;
        }
        &:focus {
          outline: 0;
        }
        &:hover {
          background-color: rgba(0, 157, 0, 0.5);
        }
      }
    }
  }
  .page-content {
    position: absolute;
    left: 280px;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: ${theme.background.default};
  }
  &.minimised {
    .sidebar {
      width: 68px;
      overflow: visible;
      .top {
        .logo-container {
          margin-bottom: 100px;
          img {
            transform: rotate(-90deg);
            margin-left: -50px;
            margin-bottom: -55px;
          }
        }
        .links-container {
          .link-item {
            color: white;
            padding-left: 7px;
            font-size: 14px;
            border-bottom: none;
          }
          a {
            .bp3-icon {
              margin: 0;
            }
          }
        }
      }
      .bottom {
        .logout-button {
          .bp3-icon {
            margin: 0;
          }
        }
      }
    }
    .page-content {
      left: 68px;
    }
  }
`;
interface Sublink {
  applicationType: string;
  title: string;
  url: string;
}
export interface SidebarLink {
  title: string;
  url: string;
  icon: IconName;
  subLinks?: Sublink[];
  applicationType: string;
}

interface Props {
  links: SidebarLink[];
  expanded: boolean;
  onLogout: () => void;
}

const Sidebar: React.FC<Props & RouteComponentProps> = ({
  links,
  children,
  location,
  expanded,
  onLogout,
}) => {
  const isActive = (link: SidebarLink): boolean => {
    const url = link.url.split('?').shift() ?? '';
    return location.pathname.startsWith(url);
  };

  const isSublinkActive = (sublink: {
    title: string;
    url: string;
    applicationType: string;
  }): boolean => {
    const paramValue = UrlHelper.paramValue(location.search);
    return (
      sublink.url.includes(paramValue) &&
      sublink.url.startsWith(location.pathname)
    );
  };

  const { permissions } = consume();

  const [showDirectSection, setShowDirectSection] = React.useState(false);
  const [showSDSection, setShowSDSection] = React.useState(false);

  React.useEffect(() => {
    if (
      PermissionHelper.hasPermission(
        permissions,
        'smartDispenseProspect',
        'viewList',
      ) ||
      PermissionHelper.hasPermission(
        permissions,
        'smartDispenseEnquiries',
        'viewList',
      ) ||
      PermissionHelper.hasPermission(
        permissions,
        'smartDispenseApplications',
        'viewList',
      )
    ) {
      setShowSDSection(true);
    }
    if (
      PermissionHelper.hasPermission(permissions, 'prospect', 'viewList') ||
      PermissionHelper.hasPermission(permissions, 'enquiries', 'viewList') ||
      PermissionHelper.hasPermission(permissions, 'applications', 'viewList')
    ) {
      setShowDirectSection(true);
    }
  }, [permissions]);

  const renderExpandedMenuLinks = (
    expanded: boolean,
    links: SidebarLink[],
    app: string,
  ) => {
    return expanded ? (
      <>
        <span className="link-item">
          {app === 'smart-dispense' ? 'Smart Dispense' : 'Direct'}
        </span>
        {links.map((l, i) => (
          <React.Fragment key={i}>
            {l.applicationType === app && (
              <>
                <Link
                  data-testid={'link'}
                  to={l.url}
                  className={`${l.subLinks ? 'disabled' : ''} ${
                    isActive(l) ? 'active' : ''
                  } `}
                  key={i}
                >
                  <Icon icon={l.icon} iconSize={20} />
                  {l.title}
                </Link>
                {l.subLinks && (
                  <div className="sublink-tabs">
                    {l.subLinks.map((sublink, index) => (
                      <Link
                        to={sublink.url}
                        className={
                          isSublinkActive(sublink) ? 'active-sublink' : ''
                        }
                        key={index}
                      >
                        {sublink.title}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </>
    ) : (
      <>
        <span className="link-item">
          {app === 'smart-dispense' ? 'Smart Dispense' : 'Direct'}
        </span>
        {links.map(
          (l, i) =>
            l.applicationType === app && (
              <Tooltip content={l.title} position="right" key={i}>
                {l.subLinks ? (
                  <ApplicationsSidebarMenu
                    url={l.url}
                    icon={l.icon}
                    sublinks={l.subLinks}
                  />
                ) : (
                  <Link to={l.url} className={isActive(l) ? 'active' : ''}>
                    <Icon icon={l.icon} iconSize={20} />
                  </Link>
                )}
              </Tooltip>
            ),
        )}
      </>
    );
  };

  return (
    <SidebarContainer
      className={`sidebar-container ${!expanded ? 'minimised' : ''}`}
    >
      <div className="sidebar">
        <div className="top">
          <div className="logo-container">
            <img src={logo} alt="sidebar-logo" />
          </div>
          <div className="links-container">
            {showDirectSection &&
              renderExpandedMenuLinks(expanded, links, 'direct')}
            {showSDSection &&
              renderExpandedMenuLinks(expanded, links, 'smart-dispense')}
          </div>
        </div>
        <div className="bottom">
          {expanded ? (
            <button className="logout-button" onClick={() => onLogout()}>
              <Icon iconSize={20} icon="log-out" /> Log Out
            </button>
          ) : (
            <Tooltip content="Log Out" position="right">
              <button className="logout-button" onClick={() => onLogout()}>
                <Icon iconSize={20} icon="log-out" />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="page-content">{children}</div>
    </SidebarContainer>
  );
};

export default withRouter(Sidebar);
