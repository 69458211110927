import React, { useState, ReactNode } from 'react';
import { Dialog, Classes } from '@blueprintjs/core';
import Button from 'components/common/Button';
import showToast from 'components/common/Toast';

interface Props {
  show: boolean;
  title: string;
  text: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirmSuccessMessage?: string;
  onConfirmFailedMessage?: string;
  children?: ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  onCancel?: () => void;
}

const ConfirmationDialog: React.FC<Props> = (props) => {
  const {
    show,
    title,
    text,
    confirmButtonText,
    cancelButtonText,
    onConfirmSuccessMessage,
    onConfirmFailedMessage,
    onConfirm,
    onClose,
    onCancel,
    children,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Dialog
      className="confirmation-dialog"
      isOpen={show}
      title={title}
      onClose={onClose || onCancel}
      canOutsideClickClose={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>{text}</p>
        {children}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {onCancel ? (
            <Button type="button" onClick={() => onCancel()}>
              {cancelButtonText || 'Cancel'}
            </Button>
          ) : (
            <Button type="button" onClick={() => onClose()}>
              {cancelButtonText || 'Cancel'}
            </Button>
          )}
          <Button
            loading={loading}
            type="button"
            appearance="primary"
            icon="thumbs-up"
            onClick={async () => {
              try {
                setLoading(true);
                await onConfirm();
                onConfirmSuccessMessage &&
                  showToast({
                    message: onConfirmSuccessMessage,
                  });
                onClose();
              } catch (error) {
                showToast({
                  message:
                    onConfirmFailedMessage ||
                    'Something went wrong! Please try again later',
                  type: 'error',
                });
              } finally {
                setLoading(false);
              }
            }}
          >
            {confirmButtonText || 'Confirm'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
