import Axios from '../Client';
import { Task } from 'models/Task';

export const TaskService =  {
  listTasks: async (): Promise<Task[]> => {
    const response = await Axios.get<Task[]>(`/tasks`);
    return response.data;
  },
};

