import Axios from 'services/Client';
import ApplicationHGSSAdmin from 'models/ApplicationHGSSAdmin';

const ComboHGSSAdminService = {
  getHGSSAdmin: async (
    applicationId: number,
  ): Promise<ApplicationHGSSAdmin> => {
    const response = await Axios.get<ApplicationHGSSAdmin>(
      `/combo/${applicationId}/hgss-admin`,
    );
    return response.data;
  },
  updateHGSSAdmin: async (
    applicationId: number | null,
    payload: ApplicationHGSSAdmin,
  ): Promise<ApplicationHGSSAdmin> => {
    const response = await Axios.put<ApplicationHGSSAdmin>(
      `/combo/${applicationId}/hgss-admin`,
      payload,
    );
    return response.data;
  },
};

export default ComboHGSSAdminService;
