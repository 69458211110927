import React, { useState, createContext } from 'react';
import axios, { CancelTokenSource } from 'axios';
import KeyContact from 'models/KeyContact';
import Enquiry from 'models/Enquiry';
import Pagination from 'models/Pagination';
import StatusReason from 'models/StatusReason';
import QueryParams from 'models/QueryParams';
import Status from 'models/Status';
import KeyContactService from 'services/KeyContactService';
import { EnquiryService } from 'services/EnquiryService';
import { AssignEnquiryRequest } from 'services/EnquiryService/requests/AssignEnquiryRequest';
import { StatusReasonRequest } from 'services/EnquiryService/requests/StatusReasonRequest';
import StatusReasonService from 'services/StatusReasonService';
import StatusService from 'services/StatusService';

const defaultPerPage = 15;

interface EnquiryContextInterface {
  initialised: boolean;
  error: Error;
  loading: Loading;
  enquiries: Pagination<Enquiry>;
  searchValue: string;
  setSearchValue: (val: string) => void;
  sortValue: string;
  setSortValue: (val: string) => void;
  assigneeFilter: KeyContact | null;
  setAssigneeFilter: (val: KeyContact | null) => void;
  statusFilter: Status[] | null;
  setStatusFilter: (val: Status[] | null) => void;
  keyContacts: KeyContact[];
  page: number;
  setPage: (page: number) => void;
  onHoldReasons: StatusReason[];
  rejectReasons: StatusReason[];
  statuses: Status[];
  initialisePageData: (params?: QueryParams) => void;
  loadEnquiryContextData: (params?: QueryParams) => void;
  loadKeyContacts: () => void;
  loadOnHoldReasons: (status: string) => void;
  loadRejectReasons: () => void;
  approveEnquiry: (id: number) => Promise<Enquiry> | null;
  assignEnquiry: (
    id: number,
    payload?: AssignEnquiryRequest,
  ) => Promise<Enquiry | null>;
  onHoldEnquiry: (
    id: number,
    payload?: StatusReasonRequest,
  ) => Promise<Enquiry | null>;
  rejectEnquiry: (
    id: number,
    payload?: StatusReasonRequest,
  ) => Promise<Enquiry | null>;
}

interface Loading {
  enquiries: boolean;
  approveEnquiry: boolean;
  keyContacts: boolean;
  onHoldReasons: boolean;
  rejectReasons: boolean;
  assignEnquiry: boolean;
  onHoldEnquiry: boolean;
  rejectEnquiry: boolean;
  statuses: boolean;
}

interface Error {
  enquiries: boolean;
  approveEnquiry: boolean;
  keyContacts: boolean;
  onHoldReasons: boolean;
  rejectReasons: boolean;
  assignEnquiry: boolean;
  onHoldEnquiry: boolean;
  rejectEnquiry: boolean;
  statuses: boolean;
}

const defaultPagination: Pagination<Enquiry> = {
  currentPage: 1,
  totalRecords: 1,
  itemsPerPage: 1,
  data: [],
};

const EnquiryContext = createContext<EnquiryContextInterface>({
  initialised: false,
  error: {
    enquiries: false,
    approveEnquiry: false,
    keyContacts: false,
    onHoldReasons: false,
    rejectReasons: false,
    assignEnquiry: false,
    onHoldEnquiry: false,
    rejectEnquiry: false,
    statuses: false,
  },
  loading: {
    enquiries: false,
    approveEnquiry: false,
    keyContacts: false,
    onHoldReasons: false,
    rejectReasons: false,
    assignEnquiry: false,
    onHoldEnquiry: false,
    rejectEnquiry: false,
    statuses: false,
  },
  enquiries: defaultPagination,
  searchValue: '',
  setSearchValue: () => null,
  sortValue: '',
  setSortValue: () => null,
  assigneeFilter: null,
  setAssigneeFilter: () => null,
  statusFilter: null,
  setStatusFilter: () => null,
  keyContacts: [],
  page: 1,
  setPage: (page: number) => null,
  onHoldReasons: [],
  rejectReasons: [],
  statuses: [],
  initialisePageData: () => null,
  loadEnquiryContextData: () => null,
  loadKeyContacts: () => null,
  loadOnHoldReasons: (status: string) => null,
  loadRejectReasons: () => null,
  approveEnquiry: (id: number) => null,
  assignEnquiry: async (id: number, payload?: AssignEnquiryRequest) => null,
  onHoldEnquiry: async (id: number, payload?: StatusReasonRequest) => null,
  rejectEnquiry: async (id: number, payload?: StatusReasonRequest) => null,
});

export const Consume: () => EnquiryContextInterface = () => {
  const consumer = React.useContext(EnquiryContext);

  // Condition used to determine if Context Provider has been declared
  if (consumer.initialised === false) {
    throw new Error('EnquiryContextProvider not initialised');
  }
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [error, setError] = useState<Error>({
    enquiries: false,
    approveEnquiry: false,
    keyContacts: false,
    onHoldReasons: false,
    rejectReasons: false,
    assignEnquiry: false,
    onHoldEnquiry: false,
    rejectEnquiry: false,
    statuses: false,
  });
  const [loading, setLoading] = useState<Loading>({
    enquiries: false,
    approveEnquiry: false,
    keyContacts: false,
    onHoldReasons: true,
    rejectReasons: true,
    assignEnquiry: false,
    onHoldEnquiry: false,
    rejectEnquiry: false,
    statuses: false,
  });
  const [enquiries, setEnquiries] =
    useState<Pagination<Enquiry>>(defaultPagination);
  const [keyContacts, setKeyContacts] = useState<KeyContact[]>([]);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [page, setPage] = useState<number>(enquiries.currentPage);
  const [onHoldReasons, setOnHoldReasons] = useState<StatusReason[]>([]);
  const [rejectReasons, setRejectReasons] = useState<StatusReason[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [assigneeFilter, setAssigneeFilter] = useState<KeyContact | null>(null);
  const [statusFilter, setStatusFilter] = useState<Status[] | null>(null);
  const [sortValue, setSortValue] = useState<string>('submitted_desc');
  const [currentRequest, setCurrentRequest] =
    useState<CancelTokenSource | null>(null);

  const loadOnHoldReasons = async (status: string) => {
    try {
      setError({ ...error, onHoldReasons: false });
      setLoading({ ...loading, onHoldReasons: true });
      const onHoldReasons = await StatusReasonService.listStatusReasons(status);
      setOnHoldReasons(onHoldReasons);
    } catch (e) {
      setError({ ...error, onHoldReasons: true });
    } finally {
      setLoading({ ...loading, onHoldReasons: false });
    }
  };

  const loadRejectReasons = async () => {
    try {
      setError({ ...error, rejectReasons: false });
      setLoading({ ...loading, rejectReasons: true });
      const rejectReasons = await StatusReasonService.listStatusReasons(
        'rejected',
      );
      setRejectReasons(rejectReasons);
    } catch (e) {
      setError({ ...error, rejectReasons: true });
    } finally {
      setLoading({ ...loading, rejectReasons: false });
    }
  };

  const loadKeyContacts = async () => {
    try {
      setLoading({ ...loading, keyContacts: true });
      const keyContacts = await KeyContactService.listKeyContacts();
      setKeyContacts(keyContacts);
      setError({ ...error, keyContacts: false });
    } catch {
      setError({ ...error, keyContacts: true });
    } finally {
      setLoading({ ...loading, keyContacts: false });
    }
  };

  const loadEnquiryContextData = async (params?: QueryParams) => {
    let stateParams: QueryParams = {
      itemsPerPage: defaultPerPage,
      page: page,
    };

    if (searchValue) stateParams.search = searchValue;
    if (sortValue) stateParams.sort = sortValue;
    if (assigneeFilter) stateParams.assigneeId = assigneeFilter.id;
    if (statusFilter)
      stateParams.statusIds = statusFilter.map((s) => String(s.id)).join(',');
    if (params) stateParams = { ...stateParams, ...params };
    try {
      if (currentRequest) {
        currentRequest.cancel();
      }
      const cr = axios.CancelToken.source();
      setCurrentRequest(cr);
      setLoading({ ...loading, enquiries: true });
      const enquiries = await EnquiryService.paginateEnquiries(stateParams, {
        cancelToken: cr.token,
      });
      setEnquiries(enquiries);
      setError({ ...error, enquiries: false });
      setLoading({ ...loading, enquiries: false });
    } catch (e) {
      // ignore cancelled requests
      if (!axios.isCancel(e)) {
        setError({ ...error, enquiries: true });
        setLoading({ ...loading, enquiries: false });
      }

      // throw e
    }
  };

  const approveEnquiry = async (id: number): Promise<Enquiry> => {
    try {
      setLoading({ ...loading, approveEnquiry: true });
      const enquiry = await EnquiryService.approveEnquiry(id);
      setError({ ...error, approveEnquiry: false });
      return enquiry;
    } catch (e) {
      setError({ ...error, approveEnquiry: true });
      throw e;
    } finally {
      setLoading({ ...loading, approveEnquiry: false });
    }
  };

  const assignEnquiry = async (
    id: number,
    payload?: AssignEnquiryRequest,
  ): Promise<Enquiry | null> => {
    if (!payload) return null;
    try {
      setLoading({ ...loading, assignEnquiry: true });
      const enquiry = await EnquiryService.assignEnquiry(id, payload);

      //if updated enquiry exists in the list, then update with new status
      updateLocalEnquiryList(enquiry);
      setError({ ...error, assignEnquiry: false });
      return enquiry;
    } catch (e) {
      setError({ ...error, assignEnquiry: true });
      throw e;
    } finally {
      setLoading({ ...loading, assignEnquiry: false });
    }
  };

  const onHoldEnquiry = async (
    id: number,
    payload?: StatusReasonRequest,
  ): Promise<Enquiry | null> => {
    if (!payload) return null;
    try {
      setError({ ...error, onHoldEnquiry: false });
      setLoading({ ...loading, onHoldEnquiry: true });
      const enquiry = await EnquiryService.onHoldEnquiry(id, payload);

      //if updated enquiry exists in the list, then update with new status
      updateLocalEnquiryList(enquiry);
      return enquiry;
    } catch (e) {
      setError({ ...error, onHoldEnquiry: true });
      throw e;
    } finally {
      setLoading({ ...loading, onHoldEnquiry: false });
    }
  };

  const rejectEnquiry = async (
    id: number,
    payload?: StatusReasonRequest,
  ): Promise<Enquiry | null> => {
    if (!payload) return null;
    try {
      setError({ ...error, rejectEnquiry: false });
      setLoading({ ...loading, rejectEnquiry: true });
      const enquiry = await EnquiryService.rejectEnquiry(id, payload);
      return enquiry;
    } catch (e) {
      setError({ ...error, rejectEnquiry: true });
      throw e;
    } finally {
      setLoading({ ...loading, rejectEnquiry: false });
    }
  };

  const initialisePageData = async (params?: QueryParams) => {
    let stateParams: QueryParams = {
      itemsPerPage: defaultPerPage,
      page: page,
    };

    if (searchValue) stateParams.search = searchValue;
    if (sortValue) stateParams.sort = sortValue;
    if (assigneeFilter) stateParams.assigneeId = assigneeFilter.id;
    if (statusFilter)
      stateParams.statusIds = statusFilter.map((s) => String(s.id)).join(',');
    if (params) stateParams = { ...stateParams, ...params };
    try {
      setLoading({ ...loading, statuses: true, keyContacts: true });
      if (keyContacts.length > 0) {
        const enquiries = await EnquiryService.paginateEnquiries(stateParams);
        setEnquiries(enquiries);
      } else {
        const [statuses, keyContacts, enquiries] = (await axios.all<
          Status[] | KeyContact[] | Pagination<Enquiry>
        >([
          StatusService.listStatuses(),
          KeyContactService.listKeyContacts(),
          EnquiryService.paginateEnquiries(stateParams),
        ])) as [Status[], KeyContact[], Pagination<Enquiry>];
        setStatuses(statuses);
        setKeyContacts(keyContacts);
        setEnquiries(enquiries);
      }
      setError({
        ...error,
        statuses: false,
        keyContacts: false,
        enquiries: false,
      });
    } catch (e) {
      setError({
        ...error,
        statuses: true,
        keyContacts: true,
        enquiries: true,
      });
      // throw e
    } finally {
      setLoading({
        ...loading,
        statuses: false,
        keyContacts: false,
        enquiries: false,
      });
    }
  };

  const updateLocalEnquiryList = (enquiry: Enquiry) => {
    const index = enquiries.data.findIndex((e) => e.id === enquiry.id);
    if (index !== -1) {
      const tempEnquiries = { ...enquiries };
      tempEnquiries.data[index] = enquiry;
      setEnquiries(tempEnquiries);
    }
  };

  return (
    <EnquiryContext.Provider
      value={{
        initialised: true,
        error,
        loading,
        enquiries,
        searchValue,
        setSearchValue,
        sortValue,
        setSortValue,
        page,
        setPage,
        keyContacts,
        onHoldReasons,
        rejectReasons,
        statuses,
        assigneeFilter,
        setAssigneeFilter,
        statusFilter,
        setStatusFilter,
        initialisePageData,
        loadEnquiryContextData,
        loadKeyContacts,
        loadOnHoldReasons,
        loadRejectReasons,
        approveEnquiry,
        assignEnquiry,
        onHoldEnquiry,
        rejectEnquiry,
      }}
    >
      {children}
    </EnquiryContext.Provider>
  );
};
