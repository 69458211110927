import React, { createContext, useState } from 'react';
import Enquiry from 'models/Enquiry';
import Comment from 'models/Comment';
import Mention from 'models/Mention';
import { EnquiryService } from 'services/EnquiryService';
import { CreateEnquiryNoteRequest } from 'services/EnquiryService/requests/CreateEnquiryNoteRequest';

interface EnquiryDetailContextInterface {
  initialised: boolean;
  error: Error;
  loading: Loading;
  enquiryComments: Comment[];
  enquiryMentionUsers: Mention[];
  currentEnquiry: Enquiry | null;
  setCurrentEnquiry: (enquiry: Enquiry | null) => void;
  findEnquiry: (id: number) => Promise<Enquiry | undefined> | null;
  loadEnquiryComments: (id: number) => Promise<Comment[]> | null;
  loadEnquiryMentionUsers: (id: number) => Promise<Mention[]> | null;
  createEnquiryNote: (
    id: number,
    payload: CreateEnquiryNoteRequest,
  ) => Promise<Enquiry | undefined> | null;
}

interface Loading {
  findEnquiry: boolean;
  loadEnquiryComments: boolean;
  loadEnquiryMentionUsers: boolean;
  createEnquiryNote: boolean;
}

interface Error {
  findEnquiry: boolean;
  loadEnquiryComments: boolean;
  loadEnquiryMentionUsers: boolean;
  createEnquiryNote: boolean;
}

const EnquiryDetailContext = createContext<EnquiryDetailContextInterface>({
  initialised: false,
  error: {
    findEnquiry: false,
    loadEnquiryComments: false,
    loadEnquiryMentionUsers: false,
    createEnquiryNote: false,
  },
  loading: {
    findEnquiry: true,
    loadEnquiryComments: true,
    loadEnquiryMentionUsers: true,
    createEnquiryNote: false,
  },
  enquiryComments: [],
  enquiryMentionUsers: [],
  currentEnquiry: null,
  setCurrentEnquiry: (enquiry: Enquiry | null) => null,
  findEnquiry: (id: number) => null,
  loadEnquiryComments: (id: number) => null,
  loadEnquiryMentionUsers: (id: number) => null,
  createEnquiryNote: (id: number, payload: CreateEnquiryNoteRequest) => null,
});

export const Consume: () => EnquiryDetailContextInterface = () => {
  const consumer = React.useContext(EnquiryDetailContext);

  // Condition used to determine if Context Provider has been declared
  if (consumer.initialised === false) {
    throw new Error('EnquiryContextProvider not initialised');
  }
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [error, setError] = useState<Error>({
    findEnquiry: false,
    loadEnquiryComments: false,
    loadEnquiryMentionUsers: false,
    createEnquiryNote: false,
  });
  const [loading, setLoading] = useState<Loading>({
    findEnquiry: true,
    loadEnquiryComments: false,
    loadEnquiryMentionUsers: false,
    createEnquiryNote: false,
  });

  const [currentEnquiry, setCurrentEnquiry] = useState<Enquiry | null>(null);
  const [enquiryComments, setEnquiryComments] = useState<Comment[]>([]);
  const [enquiryMentionUsers, setEnquiryMentionUsers] = useState<Mention[]>([]);

  const findEnquiry = async (id: number): Promise<Enquiry | undefined> => {
    try {
      setError({ ...error, findEnquiry: false });
      setLoading({ ...loading, findEnquiry: true });
      const enquiry = await EnquiryService.findEnquiry(id);
      setCurrentEnquiry(enquiry);
      return enquiry;
    } catch (e) {
      setError({ ...error, findEnquiry: true });
      throw e;
    } finally {
      setLoading({ ...loading, findEnquiry: false });
    }
  };

  const loadEnquiryComments = async (id: number): Promise<Comment[]> => {
    try {
      setError({ ...error, loadEnquiryComments: false });
      setLoading({ ...loading, loadEnquiryComments: true });
      const comments = await EnquiryService.loadEnquiryComments(id);
      setEnquiryComments(comments);
      return comments;
    } catch (e) {
      setError({ ...error, loadEnquiryComments: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadEnquiryComments: false });
    }
  };

  const loadEnquiryMentionUsers = async (id: number): Promise<Mention[]> => {
    try {
      setError({ ...error, loadEnquiryMentionUsers: false });
      setLoading({ ...loading, loadEnquiryMentionUsers: true });
      const mentions = await EnquiryService.loadEnquiryMentionUsers(id);
      setEnquiryMentionUsers(mentions);
      return mentions;
    } catch (e) {
      setError({ ...error, loadEnquiryMentionUsers: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadEnquiryMentionUsers: false });
    }
  };

  const createEnquiryNote = async (
    id: number,
    payload: CreateEnquiryNoteRequest,
  ): Promise<Enquiry> => {
    try {
      setError({ ...error, createEnquiryNote: false });
      setLoading({ ...loading, createEnquiryNote: true });
      const enquiry = await EnquiryService.createEnquiryNote(id, payload);
      setCurrentEnquiry(enquiry);
      return enquiry;
    } catch (e) {
      setError({ ...error, createEnquiryNote: true });
      throw e;
    } finally {
      setLoading({ ...loading, createEnquiryNote: false });
    }
  };

  return (
    <EnquiryDetailContext.Provider
      value={{
        initialised: true,
        error,
        loading,
        enquiryComments,
        enquiryMentionUsers,
        currentEnquiry,
        setCurrentEnquiry,
        findEnquiry,
        loadEnquiryComments,
        loadEnquiryMentionUsers,
        createEnquiryNote,
      }}
    >
      {children}
    </EnquiryDetailContext.Provider>
  );
};
