import 'core-js';
import 'react-app-polyfill/ie11';
import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'stylesheets/app.scss';
import App from 'components/App';
import * as serviceWorker from './serviceWorker';
// tslint:disable-next-line:no-var-requires
require('dom4'); // only import actual dom4 if we're in the browser (not server-compatible)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
