import Axios from 'services/Client';
import OutletVolume from 'models/OutletVolume';

const OutletVolumeService = {
  listOutletVolumes: async (): Promise<OutletVolume[]> => {
    const response = await Axios.get<OutletVolume[]>('/outlet-volumes');
    return response.data;
  },
};

export default OutletVolumeService;