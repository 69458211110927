import Axios from 'services/Client';
import ApplicationHGSSAdditionalId from 'models/ApplicationHGSSAdditionalId';
import UpdateSDApplicationHGSSAdditionalIdRequest from './requests/UpdateSDApplicationHGSSAdditionalIdRequest';

const SDHGSSAdditionalIdService = {
  loadSDApplicationHGSSAdditionalIds: async (
    applicationId: number,
  ): Promise<ApplicationHGSSAdditionalId[]> => {
    const response = await Axios.get<ApplicationHGSSAdditionalId[]>(
      `/smart-dispense/${applicationId}/additional_id`,
    );
    return response.data;
  },
  updateSDApplicationHGSSAdditionalIds: async (
    applicationId: number,
    payload: UpdateSDApplicationHGSSAdditionalIdRequest,
  ): Promise<ApplicationHGSSAdditionalId[]> => {
    const response = await Axios.post<ApplicationHGSSAdditionalId[]>(
      `/smart-dispense/${applicationId}/additional_id`,
      payload,
    );
    return response.data;
  },
  loadSDApplicationHGSSAdditionalIdDocument: async (
    applicationId: number,
    applicantId: number,
  ): Promise<string> => {
    const response = await Axios.get<{ url: string }>(
      `/smart-dispense/${applicationId}/additional_id/${applicantId}`,
    );
    return response.data.url;
  },
};

export default SDHGSSAdditionalIdService;