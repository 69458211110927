import React, { useState, createContext } from 'react';
import axios from 'axios';
import Bar from 'models/Bar';
import ExistingBarTap from 'models/CreateBar/ExistingBarTap';
import SmartDispenseSolution from 'models/CreateBar/SmartDispenseSolution';
import MonitoredLines from 'models/CreateBar/MonitoredLines';
import CellarCooling from 'models/CreateBar/CellarCooling';
import FreezerStyle from 'models/CreateBar/FreezerStyle';
import { TapBrand } from 'models/TapBrand';
import NewBarTap from 'models/CreateBar/NewBarTap';
import TBarStyles from 'models/CreateBar/TBarStyles';
import GasType from 'models/CreateBar/GasType';
import SmartDispenseSolutionsList from 'services/CreateBarOptionsServices/SmartDispenseSolutionsService';
import CellarCoolingOptions from 'services/CreateBarOptionsServices/CellarCoolingService';
import FreezerStyleList from 'services/CreateBarOptionsServices/FreezerStyleService';
import GasService from 'services/CreateBarOptionsServices/GasService';
import TBarStylesService from 'services/TBarStylesService';
import MonitoredLinesOptions from 'services/CreateBarOptionsServices/MonitoredLinesService';
import TapBrandsService from 'services/TapBrandsService';

interface BarLayoutContextInterface {
  initialised: boolean;
  barLayoutError: Error;
  barLayoutLoading: Loading;
  defaultTapsNumber: number;
  setExistingTaps: (bar: Bar) => void;
  setNewBarTaps: (bar: Bar) => void;
  existingTapsList: (ExistingBarTap | any)[];
  newBarTapsList: (NewBarTap | any)[];
  smartDispenseSolutionsList: SmartDispenseSolution[];
  monitoredLinesOptions: MonitoredLines[];
  cellarCoolingOptions: CellarCooling[];
  freezerStyleList: FreezerStyle[];
  gasTypeList: GasType[];
  getFormOptions: () => void;
  openedTap: number;
  setOpenedTap: (arg: number) => void;
  updateExistingLayoutTap: (arg: ExistingBarTap, index: number) => void;
  deleteExistingLayoutTap: (index: number) => void;
  deleteNewBarTap: (index: number) => void;
  resetTaps: () => void;
  updateNewBarTap: (arg: NewBarTap, index: number) => void;
  getTBarStylesList: (tapCount: number) => Promise<TBarStyles[]> | null;
  getTBarStyleOptionsList: (
    id: number,
    tapCount: number,
  ) => Promise<any> | null;
  loadTapBrands: () => void;
  tapBrands: TapBrand[];
}

interface Error {
  applications: boolean;
  getExistingTapsList: boolean;
  getFormOptions: boolean;
  updateExistingLayoutTap: boolean;
  deleteExistingLayoutTap: boolean;
  setNewBarTaps: boolean;
  deleteNewBarTap: boolean;
  updateNewBarTap: boolean;
  loadTapBrands: boolean;
  getTBarStylesList: boolean;
}

interface Loading {
  applications: boolean;
  getExistingTapsList: boolean;
  getFormOptions: boolean;
  updateExistingLayoutTap: boolean;
  deleteExistingLayoutTap: boolean;
  setNewBarTaps: boolean;
  deleteNewBarTap: boolean;
  updateNewBarTap: boolean;
  getTBarStylesList: boolean;
  loadTapBrands: boolean;
}

const DEFAULT_TAPS_NUMBER = 50;
const defaultTapsState = new Array(DEFAULT_TAPS_NUMBER).fill({});

const BarLayoutContext = createContext<BarLayoutContextInterface>({
  initialised: false,
  barLayoutError: {
    applications: false,
    getExistingTapsList: false,
    getFormOptions: false,
    updateExistingLayoutTap: false,
    deleteExistingLayoutTap: false,
    setNewBarTaps: false,
    deleteNewBarTap: false,
    updateNewBarTap: false,
    getTBarStylesList: false,
    loadTapBrands: false,
  },
  barLayoutLoading: {
    applications: false,
    getExistingTapsList: false,
    getFormOptions: false,
    updateExistingLayoutTap: false,
    deleteExistingLayoutTap: false,
    setNewBarTaps: false,
    deleteNewBarTap: false,
    updateNewBarTap: false,
    getTBarStylesList: false,
    loadTapBrands: false,
  },
  defaultTapsNumber: DEFAULT_TAPS_NUMBER,
  setExistingTaps: (bar: Bar) => null,
  setNewBarTaps: (bar: Bar) => null,
  newBarTapsList: [{}],
  existingTapsList: [{}],
  smartDispenseSolutionsList: [],
  monitoredLinesOptions: [],
  cellarCoolingOptions: [],
  freezerStyleList: [],
  gasTypeList: [],
  getFormOptions: () => null,
  openedTap: 0,
  setOpenedTap: () => null,
  updateExistingLayoutTap: () => null,
  deleteExistingLayoutTap: () => null,
  resetTaps: () => null,
  deleteNewBarTap: () => null,
  updateNewBarTap: () => null,
  getTBarStylesList: (tapCount: number) => null,
  getTBarStyleOptionsList: (id: number, tapCount: number) => null,
  loadTapBrands: () => null,
  tapBrands: [],
});

export const Consume: () => BarLayoutContextInterface = () => {
  const consumer = React.useContext(BarLayoutContext);
  if (consumer.initialised === false) {
    throw new Error('BarLayoutProvider not initialised');
  }
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [barLayoutError, setBarLayoutError] = useState<Error>({
    applications: false,
    getExistingTapsList: false,
    getFormOptions: false,
    updateExistingLayoutTap: false,
    deleteExistingLayoutTap: false,
    setNewBarTaps: false,
    deleteNewBarTap: false,
    updateNewBarTap: false,
    getTBarStylesList: false,
    loadTapBrands: false,
  });
  const [barLayoutLoading, setBarLayoutLoading] = useState<Loading>({
    applications: false,
    getExistingTapsList: false,
    getFormOptions: false,
    updateExistingLayoutTap: false,
    deleteExistingLayoutTap: false,
    setNewBarTaps: false,
    deleteNewBarTap: false,
    updateNewBarTap: false,
    getTBarStylesList: false,
    loadTapBrands: false,
  });
  const [smartDispenseSolutionsList, setSmartDispenseSolutionsList] = useState<
    SmartDispenseSolution[]
  >([]);
  const [monitoredLinesOptions, setMonitoredLinesOptions] = useState<
    SmartDispenseSolution[]
  >([]);
  const [cellarCoolingOptions, setCellarCoolingOptions] = useState<
    CellarCooling[]
  >([]);
  const [freezerStyleList, setFreezerStyleList] = useState<CellarCooling[]>([]);
  const [gasTypeList, setGasTypeList] = useState<GasType[]>([]);
  const [existingTapsList, setExistingTapsList] =
    useState<{}[]>(defaultTapsState);
  const [newBarTapsList, setNewBarTapsList] = useState<{}[]>(defaultTapsState);
  const [openedTap, setOpenedTap] = useState(0);
  const [tapBrands, setTapBrands] = useState<TapBrand[]>([]);

  const getFormOptions = async () => {
    try {
      setBarLayoutLoading({ ...barLayoutLoading, getFormOptions: true });
      const [
        smartDispenseSolutionsList,
        monitoredLinesOptions,
        cellarCoolingOptions,
        freezerStyleList,
        gasTypeList,
      ] = (await axios.all([
        SmartDispenseSolutionsList.listSmartDispenseSolutions(),
        MonitoredLinesOptions.listMonitoredLines(),
        CellarCoolingOptions.listCellarCoolings(),
        FreezerStyleList.freezerStyleList(),
        GasService.listGasTypes(),
      ])) as [
        SmartDispenseSolution[],
        MonitoredLines[],
        CellarCooling[],
        FreezerStyle[],
        GasType[],
      ];
      setSmartDispenseSolutionsList(smartDispenseSolutionsList);
      setMonitoredLinesOptions(monitoredLinesOptions);
      setCellarCoolingOptions(cellarCoolingOptions);
      setFreezerStyleList(freezerStyleList);
      setGasTypeList(gasTypeList);
    } catch (e) {
      setBarLayoutError({ ...barLayoutError, getFormOptions: true });
      throw e;
    } finally {
      setBarLayoutLoading({ ...barLayoutLoading, getFormOptions: false });
    }
  };

  const setExistingTaps = (bar: Bar) => {
    try {
      setBarLayoutError({ ...barLayoutError, getExistingTapsList: false });
      setBarLayoutLoading({ ...barLayoutLoading, getExistingTapsList: true });
      const taps = new Array(DEFAULT_TAPS_NUMBER).fill({}).map((t, i) => {
        if (bar.existingLayout[i]) {
          bar.existingLayout[i].brandId = bar.existingLayout[i].id;
          bar.existingLayout[i].brandName = bar.existingLayout[i].name;
          delete bar.existingLayout[i].position;
          delete bar.existingLayout[i].id;
          delete bar.existingLayout[i].name;
        }

        return bar.existingLayout[i] ? bar.existingLayout[i] : {};
      });
      setExistingTapsList(taps);
    } catch (e) {
      setBarLayoutError({ ...barLayoutError, getExistingTapsList: true });
      throw e;
    } finally {
      setBarLayoutLoading({ ...barLayoutLoading, getExistingTapsList: false });
    }
  };

  const updateExistingLayoutTap = (arg: ExistingBarTap, index: number) => {
    try {
      setBarLayoutError({ ...barLayoutError, updateExistingLayoutTap: false });
      setBarLayoutLoading({
        ...barLayoutLoading,
        updateExistingLayoutTap: true,
      });
      const taps = existingTapsList.map((tap, i) => {
        return i === index ? arg : tap;
      });
      setExistingTapsList(taps);
    } catch (e) {
      setBarLayoutError({ ...barLayoutError, updateExistingLayoutTap: true });
      throw e;
    } finally {
      setBarLayoutLoading({
        ...barLayoutLoading,
        updateExistingLayoutTap: false,
      });
    }
  };

  const deleteExistingLayoutTap = (index: number) => {
    try {
      setBarLayoutError({ ...barLayoutError, deleteExistingLayoutTap: false });
      setBarLayoutLoading({
        ...barLayoutLoading,
        deleteExistingLayoutTap: true,
      });
      const taps = existingTapsList.map((tap, i) => {
        return i === index ? {} : tap;
      });
      setExistingTapsList(taps);
    } catch (e) {
      setBarLayoutError({ ...barLayoutError, deleteExistingLayoutTap: true });
      throw e;
    } finally {
      setBarLayoutLoading({
        ...barLayoutLoading,
        deleteExistingLayoutTap: false,
      });
    }
  };

  const resetTaps = () => {
    setExistingTapsList(defaultTapsState);
    setNewBarTapsList(defaultTapsState);
  };

  const setNewBarTaps = async (bar: Bar) => {
    try {
      setBarLayoutError({ ...barLayoutError, setNewBarTaps: false });
      setBarLayoutLoading({ ...barLayoutLoading, setNewBarTaps: true });
      const taps = new Array(DEFAULT_TAPS_NUMBER).fill({}).map((t, i) => {
        if (bar.newLayout[i]) {
          bar.newLayout[i].brandId = bar.newLayout[i].id;
          bar.newLayout[i].brandName = bar.newLayout[i].name;
          delete bar.newLayout[i].id;
          delete bar.newLayout[i].name;
          delete bar.newLayout[i].position;
        }
        return bar.newLayout[i] ? bar.newLayout[i] : {};
      });
      setNewBarTapsList(taps);
    } catch (e) {
      setBarLayoutError({ ...barLayoutError, setNewBarTaps: true });
      throw e;
    } finally {
      setBarLayoutLoading({ ...barLayoutLoading, setNewBarTaps: false });
    }
  };

  const updateNewBarTap = (arg: NewBarTap, index: number) => {
    try {
      setBarLayoutError({ ...barLayoutError, updateNewBarTap: false });
      setBarLayoutLoading({ ...barLayoutLoading, updateNewBarTap: true });
      const taps = newBarTapsList.map((tap, i) => {
        return i === index ? arg : tap;
      });
      const tapsCount = taps.filter(
        (value: any) => Object.keys(value).length !== 0,
      );
      setNewBarTapsList(taps);
      return tapsCount;
    } catch (e) {
      setBarLayoutError({ ...barLayoutError, updateNewBarTap: true });
      throw e;
    } finally {
      setBarLayoutLoading({ ...barLayoutLoading, updateNewBarTap: false });
    }
  };

  const deleteNewBarTap = (index: number) => {
    try {
      setBarLayoutError({ ...barLayoutError, deleteNewBarTap: false });
      setBarLayoutLoading({ ...barLayoutLoading, deleteNewBarTap: true });
      const taps = newBarTapsList.map((tap, i) => {
        return i === index ? {} : tap;
      });
      setNewBarTapsList(taps);
      return taps.filter((value: any) => Object.keys(value).length !== 0);
    } catch (e) {
      setBarLayoutError({ ...barLayoutError, deleteNewBarTap: true });
      throw e;
    } finally {
      setBarLayoutLoading({ ...barLayoutLoading, deleteNewBarTap: false });
    }
  };

  const getTBarStylesList = async (tapCount: number): Promise<TBarStyles[]> => {
    try {
      setBarLayoutError({ ...barLayoutError, getTBarStylesList: false });
      setBarLayoutLoading({ ...barLayoutLoading, getTBarStylesList: true });
      const barStyles = await TBarStylesService.listTBarStyles(tapCount);
      return barStyles;
    } catch (e) {
      setBarLayoutError({ ...barLayoutError, getTBarStylesList: true });
      throw e;
    } finally {
      setBarLayoutLoading({ ...barLayoutLoading, getTBarStylesList: false });
    }
  };

  const getTBarStyleOptionsList = async (id: number, tapCount: number) => {
    try {
      setBarLayoutError({ ...barLayoutError, getTBarStylesList: false });
      setBarLayoutLoading({ ...barLayoutLoading, getTBarStylesList: true });
      const barStyleOptions: any = await TBarStylesService.listTBarStyleOptions(
        id,
        tapCount,
      );
      return barStyleOptions;
    } catch (e) {
      setBarLayoutError({ ...barLayoutError, getTBarStylesList: true });
      throw e;
    } finally {
      setBarLayoutLoading({ ...barLayoutLoading, getTBarStylesList: false });
    }
  };

  const loadTapBrands = async () => {
    try {
      setBarLayoutError({ ...barLayoutError, loadTapBrands: true });
      setBarLayoutLoading({ ...barLayoutLoading, loadTapBrands: false });
      const brands = await TapBrandsService.loadBrandsList();
      setTapBrands(brands);
    } catch (e) {
      setBarLayoutError({ ...barLayoutError, loadTapBrands: true });
      throw e;
    } finally {
      setBarLayoutLoading({ ...barLayoutLoading, loadTapBrands: false });
    }
  };

  return (
    <BarLayoutContext.Provider
      value={{
        initialised: true,
        barLayoutError,
        barLayoutLoading,
        defaultTapsNumber: DEFAULT_TAPS_NUMBER,
        setExistingTaps,
        existingTapsList,
        smartDispenseSolutionsList,
        getFormOptions,
        monitoredLinesOptions,
        cellarCoolingOptions,
        freezerStyleList,
        gasTypeList,
        openedTap,
        setOpenedTap,
        updateExistingLayoutTap,
        deleteExistingLayoutTap,
        resetTaps,
        setNewBarTaps,
        newBarTapsList,
        deleteNewBarTap,
        updateNewBarTap,
        getTBarStylesList,
        getTBarStyleOptionsList,
        loadTapBrands,
        tapBrands,
      }}
    >
      {children}
    </BarLayoutContext.Provider>
  );
};
