import React, { useState, createContext } from 'react';
import axios, { CancelTokenSource } from 'axios';
import Application from 'models/Application';
import KeyContact from 'models/KeyContact';
import Region from 'models/Region';
import { Task } from 'models/Task';
import Pagination from 'models/Pagination';
import QueryParams from 'models/QueryParams';
import ApplicationService from 'services/ApplicationService';
import RegionService from 'services/RegionService';
import KeyContactService from 'services/KeyContactService';
import { TaskService } from 'services/TasksService';
import CreateNewApplicationRequest from 'services/ApplicationService/requests/CreateNewApplicationRequest';

interface ApplicationContextInterface {
  initialised: boolean;
  error: Error;
  loading: Loading;
  applications: Pagination<Application>;
  keyContacts: KeyContact[];
  regions: Region[];
  tasks: Task[];
  loadTasks: () => void;
  page: number;
  setPage: (page: number) => void;
  taskFilter: {}[] | null;
  setTaskFilter: (val: {}[] | null) => void;
  assigneeFilter: KeyContact | null;
  setAssigneeFilter: (val: KeyContact | null) => void;
  flagsChecked: boolean;
  setFlagsChecked: (val: boolean) => void;
  sortValue: string;
  setSortValue: (val: string) => void;
  searchValue: string;
  setSearchValue: (val: string) => void;
  currentApplication: Application | null;
  setCurrentApplication: (application: Application | null) => void;
  initialisePageData: (params?: QueryParams) => void;
  loadKeyContacts: () => void;
  loadRegions: () => void;
  createNewApplication: (
    payload: CreateNewApplicationRequest,
  ) => Promise<Application | undefined> | null;
}

interface Error {
  applications: boolean;
  keyContacts: boolean;
  loadKeyContacts: boolean;
  loadRegions: boolean;
  createNewApplication: boolean;
  tasks: boolean;
  loadTasks: boolean;
}

interface Loading {
  applications: boolean;
  keyContacts: boolean;
  loadKeyContacts: boolean;
  loadRegions: boolean;
  createNewApplication: boolean;
  tasks: boolean;
  loadTasks: boolean;
}

const defaultPagination: Pagination<Application> = {
  currentPage: 1,
  totalRecords: 1,
  itemsPerPage: 1,
  data: [],
};

const defaultPerPage = 15;

const ApplicationContext = createContext<ApplicationContextInterface>({
  initialised: false,
  error: {
    applications: false,
    keyContacts: false,
    loadKeyContacts: false,
    loadRegions: false,
    createNewApplication: false,
    tasks: false,
    loadTasks: false,
  },
  loading: {
    applications: false,
    keyContacts: false,
    loadKeyContacts: false,
    loadRegions: false,
    createNewApplication: false,
    tasks: false,
    loadTasks: false,
  },
  applications: defaultPagination,
  keyContacts: [],
  regions: [],
  tasks: [],
  assigneeFilter: null,
  setAssigneeFilter: () => null,
  page: 1,
  taskFilter: null,
  setTaskFilter: () => null,
  setPage: (page: number) => null,
  flagsChecked: false,
  setFlagsChecked: (val: boolean) => null,
  sortValue: '',
  setSortValue: () => null,
  searchValue: '',
  setSearchValue: () => null,
  currentApplication: null,
  setCurrentApplication: (application: Application | null) => null,
  initialisePageData: () => null,
  loadKeyContacts: () => null,
  loadRegions: () => null,
  createNewApplication: (payload: CreateNewApplicationRequest) => null,
  loadTasks: () => null,
});

export const Consume: () => ApplicationContextInterface = () => {
  const consumer = React.useContext(ApplicationContext);
  if (consumer.initialised === false) {
    throw new Error('ApplicationContextProvider not initialised');
  }
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [error, setError] = useState<Error>({
    applications: false,
    keyContacts: false,
    loadKeyContacts: false,
    loadRegions: false,
    createNewApplication: false,
    tasks: false,
    loadTasks: false,
  });
  const [loading, setLoading] = useState<Loading>({
    applications: false,
    keyContacts: false,
    loadKeyContacts: false,
    loadRegions: false,
    createNewApplication: false,
    tasks: false,
    loadTasks: false,
  });
  const [applications, setApplications] =
    useState<Pagination<Application>>(defaultPagination);
  const [page, setPage] = useState<number>(applications.currentPage);
  const [currentApplication, setCurrentApplication] =
    useState<Application | null>(null);
  const [keyContacts, setKeyContacts] = useState<KeyContact[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskFilter, setTaskFilter] = useState<{}[] | null>(null);
  const [assigneeFilter, setAssigneeFilter] = useState<KeyContact | null>(null);
  const [flagsChecked, setFlagsChecked] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentRequest, setCurrentRequest] =
    useState<CancelTokenSource | null>(null);

  const initialisePageData = async (params?: QueryParams) => {
    let stateParams: QueryParams = {
      itemsPerPage: defaultPerPage,
      page: page,
    };

    if (sortValue) stateParams.sort = sortValue;
    if (searchValue) stateParams.search = searchValue;
    if (flagsChecked) stateParams.hasFlags = 1;
    if (assigneeFilter) stateParams.assigneeId = assigneeFilter.id;
    if (taskFilter) stateParams.taskFilter = taskFilter;
    if (params) stateParams = { ...stateParams, ...params };
    try {
      setLoading({ ...loading, applications: true, keyContacts: true });
      if (currentRequest) {
        currentRequest.cancel();
      }
      const cr = axios.CancelToken.source();
      setCurrentRequest(cr);
      if (keyContacts.length > 0) {
        const applications = await ApplicationService.paginateApplications(
          stateParams,
          {
            cancelToken: cr.token,
          },
        );
        setApplications(applications);
      } else {
        const [applications, keyContacts, tasks] = (await axios.all<
          Pagination<Application> | KeyContact[]| Task[]
        >([
          ApplicationService.paginateApplications(stateParams, {
            cancelToken: cr.token,
          }),
          KeyContactService.listKeyContacts(),
          TaskService.listTasks(),
        ])) as [Pagination<Application>, KeyContact[], Task[]];

        setApplications(applications);
        setKeyContacts(keyContacts);
        setTasks(tasks);
      }
      setError({ ...error, applications: false, keyContacts: false });
      setLoading({ ...loading, applications: false, keyContacts: false });
    } catch (e) {
      // ignore cancelled requests
      if (!axios.isCancel(e)) {
        setError({ ...error, applications: true, keyContacts: true });
        setLoading({ ...loading, applications: false, keyContacts: false });
      }
      // throw e
    }
  };

  const loadKeyContacts = async () => {
    try {
      setLoading({ ...loading, loadKeyContacts: true });
      setError({ ...error, loadKeyContacts: false });
      const keyContacts = await KeyContactService.listKeyContacts();
      setKeyContacts(keyContacts);
    } catch (e) {
      setError({ ...error, loadKeyContacts: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadKeyContacts: false });
    }
  };

  const loadRegions = async () => {
    try {
      setLoading({ ...loading, loadRegions: true });
      setError({ ...error, loadRegions: false });
      const regions = await RegionService.listRegions();
      setRegions(regions);
    } catch (e) {
      setError({ ...error, loadRegions: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadRegions: false });
    }
  };

  const createNewApplication = async (
    payload: CreateNewApplicationRequest,
  ): Promise<Application> => {
    try {
      setError({ ...error, createNewApplication: false });
      setLoading({ ...loading, createNewApplication: true });
      const application = await ApplicationService.createNewApplication(
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createNewApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, createNewApplication: false });
    }
  };

  const loadTasks = async () => {
    try {
      setLoading({ ...loading, loadTasks: true });
      setError({ ...error, loadTasks: false });
      const tasks = await TaskService.listTasks();
      setTasks(tasks);
    } catch (e) {
      setError({ ...error, loadTasks: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadTasks: false });
    }
  };

  return (
    <ApplicationContext.Provider
      value={{
        initialised: true,
        error,
        loading,
        applications,
        page,
        setPage,
        keyContacts,
        regions,
        taskFilter,
        setTaskFilter,
        flagsChecked,
        setFlagsChecked,
        assigneeFilter,
        setAssigneeFilter,
        sortValue,
        setSortValue,
        searchValue,
        setSearchValue,
        currentApplication,
        setCurrentApplication,
        initialisePageData,
        loadKeyContacts,
        loadRegions,
        createNewApplication,
        tasks,
        loadTasks,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
