import QueryParams from 'models/QueryParams';

const StringHelper =  {
  toKebabCase: (value: string): string => {
    const parts = value.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
    );

    if (parts) {
      return parts.map((x) => x.toLowerCase()).join('-');
    } else {
      throw new Error('Formatting error');
    }
  },

  formatQueryParams: (params: QueryParams): string => {
    const formattedParams: QueryParams = Object.keys(params).reduce(
      (acc, k) => {
        if (params[k] !== undefined && params[k] !== '' && params[k] !== null)
          acc[k] = String(params[k]);
        return acc;
      },
      {} as QueryParams,
    );

    return Object.keys(formattedParams)
      .map((key) => `${key}=${formattedParams[key]}`)
      .join('&');
  },
};

export default StringHelper;