import Axios from 'services/Client';
import InnserveEmail from 'models/InnserveEmail';

const InnserveEmails = {
  listInnserveEmails: async (): Promise<InnserveEmail[]> => {
    const response = await Axios.get<InnserveEmail[]>('/inserve-emails');
    return response.data;
  },
};

export default InnserveEmails;