import Axios from 'services/Client';
import { ApplicationHGSSDirectDebit } from 'models/ApplicationHGSSDirectDebit';
import { UpdateApplicationHGSSDirectDebitRequest } from './requests/UpdateApplicationHGSSDirectDebitRequest';
import SkipDirectDebitSignature from './requests/SkipDirectDebitSignature';
import ComboApplication from 'models/ComboApplication';

export const HGSSDirectDebitService = {
  loadApplicationHGSSDirectDebit: async (
    applicationId: number,
  ): Promise<ApplicationHGSSDirectDebit[]> => {
    const response = await Axios.get<ApplicationHGSSDirectDebit[]>(
      `/combo/${applicationId}/direct-debit`,
    );
    return response.data;
  },
  updateApplicationHGSSDirectDebit: async (
    applicationId: number,
    payload: UpdateApplicationHGSSDirectDebitRequest,
  ): Promise<ApplicationHGSSDirectDebit[]> => {
    const response = await Axios.post<ApplicationHGSSDirectDebit[]>(
      `/combo/${applicationId}/direct-debit`,
      payload,
    );
    return response.data;
  },
  loadApplicationHGSSDirectDebitDocument: async (
    applicationId: number,
    contactId: number,
  ): Promise<string> => {
    const response = await Axios.get<{ url: string }>(
      `/combo/${applicationId}/direct-debit/${contactId}`,
    );
    return response.data.url;
  },
  skipDirectDebitSignature: async (
    applicationId: number,
    payload: SkipDirectDebitSignature,
  ): Promise<ComboApplication> => {
    const response = await Axios.put<ComboApplication>(
      `/combo/${applicationId}/direct-debit`,
      payload,
    );
    return response.data;
  },
};
