import Axios from '../Client';
import User from 'models/User';

interface Request {
  email: string;
  password: string;
}
interface tokenRequest {
  token: string;
}

interface Response {
  token: string;
  user: User;
}

const AuthService =  {
  async login(request: Request | tokenRequest): Promise<Response> {
    const response = await Axios.post<Response>('/auth', request);
    return response.data;
  },
  async logout(): Promise<any> {
    const response = await Axios.delete('/auth');
    return response.data;
  },
  async resetPasswordRequest(request: { email: string }): Promise<any> {
    const response = await Axios.post('/auth/forgotten-password', request);
    return response.data;
  },
  async resetPassword(
    token: string,
    request: { password: string; password_confirmation: string },
  ): Promise<any> {
    const response = await Axios.post(
      `/auth/reset-password?token=${token}`,
      request,
    );
    return response.data;
  },
};

export default AuthService;