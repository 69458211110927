import SmartDispenseSolution from 'models/CreateBar/SmartDispenseSolution';
import Axios from '../../Client';

export default class SmartDispenseSolutionsList {
  public static async listSmartDispenseSolutions(): Promise<
    SmartDispenseSolution[]
  > {
    const response = await Axios.get<SmartDispenseSolution[]>(
      '/smart-dispense/solutions',
    );
    return response.data;
  }
}
