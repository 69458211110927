import React, { createContext, useState } from 'react';
import axios from 'axios';
import SDApplication from 'models/SDApplication';
import KeyContact from 'models/KeyContact';
import Region from 'models/Region';
import LegalStatus from 'models/LegalStatus';
import Comment from 'models/Comment';
import Mention from 'models/Mention';
import OutletSegment from 'models/OutletSegment';
import PricingSector from 'models/PricingSector';
import Application from 'models/Application';
import { Wholesaler } from 'models/Wholesaler';
import HeinekenDirectRole from 'models/HeinekenDirectRole';
import Contact from 'models/Contact';
import StatusReason from 'models/StatusReason';
import InnserveEmail from 'models/InnserveEmail';
import SDApplicationService from 'services/SDApplicationService';
import UpdateSDApplicationRequest from 'services/SDApplicationService/requests/UpdateSDApplicationRequest';
import UpdateSDSalesRepsRequest from 'services/SDApplicationService/requests/UpdateSDSalesRepsRequest';
import CreateNoteRequest from 'services/ApplicationService/requests/CreateNoteRequest';
import KeyContactService from 'services/KeyContactService';
import OutletSegmentService from 'services/OutletSegmentService';
import RegionService from 'services/RegionService';
import LegalStatusService from 'services/LegalStatusService';
import UpdateSDOutletDetailsRequest from 'services/SDApplicationService/requests/UpdateSDOutletDetailsRequest';
import UpdateSDLegalEntityAddressRequest from 'services/SDApplicationService/requests/UpdateSDLegalEntityAddressRequest';
import SDApplicantRequest from 'services/SDApplicationService/requests/SDApplicantRequest';
import SDSigningService from 'services/SDSigningService';
import AccountNumbersRequest from 'services/SDApplicationService/requests/AccountNumbersRequest';
import CreateBarRequest from 'services/SDApplicationService/requests/CreateBarRequest';
import InnserveDetailsRequest from 'services/SDApplicationService/requests/InnserveDetailsRequest';
import PricingSectorService from 'services/PricingSectorService';
import InnserveEmailsService from 'services/InnserveEmailsService';
import UpdateSDAdminDetailsRequest from 'services/SDApplicationService/requests/SDAdminDetails';
import StatusReasonService from 'services/StatusReasonService';
import DeleteApplicationRequest from 'services/ApplicationService/requests/DeleteApplicationRequest';
import SubmitSDApplicationRequest from 'services/SDApplicationService/requests/SubmitSDApplicationRequest';
import ApproveSDApplicationRequest from 'services/SDApplicationService/requests/ApproveSDApplicationRequest';
import HeinekenDirectRoleService from 'services/HeinekenDirectRoleService';
import CreateHeinekenDirectRequest from 'services/ApplicationService/requests/CreateHeinekenDirectRequest';
import CompleteSDApplicationRequest from 'services/SDApplicationService/requests/CompleteSDApplicationRequest';
import ToggleApplicationRequest from 'services/SDApplicationService/requests/ToggleApplicationRequest';
import UpdateContractInfoRequest from 'services/SDApplicationService/requests/UpdateContractInfoRequest';
import SDHGSSPersonalGuaranteeService from 'services/SDHGSSPersonalGuaranteeService';
import { HGSSDirectDebitService } from 'services/SDHGSSDirectDebitService/';
import SkipPersonalGuaranteeSignature from 'services/SDHGSSPersonalGuaranteeService/requests/SkipPersonalGuaranteeSignature';
import SkipDirectDebitSignature from 'services/SDHGSSDirectDebitService/requests/SkipDirectDebitSignature';
import { SDContractService } from 'services/SDContractService';

interface SDApplicationDetailContextInterface {
  initialised: boolean;
  error: NetworkState;
  loading: NetworkState;
  currentApplication: SDApplication | null;
  applicationComments: Comment[];
  applicationMentionUsers: Mention[];
  heinekenDirectRoles: HeinekenDirectRole[];
  contactDetails: Contact[];
  innserveEmails: InnserveEmail[];
  setCurrentApplication: (application: SDApplication | null) => void;
  findApplication: (id: number) => Promise<SDApplication | undefined> | null;
  updateApplication: (
    id: number,
    payload: UpdateSDApplicationRequest,
  ) => Promise<SDApplication | undefined> | null;
  updateSalesReps: (
    id: number,
    payload: UpdateSDSalesRepsRequest,
  ) => Promise<SDApplication | undefined> | null;
  keyContacts: KeyContact[];
  loadKeyContacts: () => void;
  wholesalers: Wholesaler[];
  loadWholesalers: () => void;
  regions: Region[];
  outletSegments: OutletSegment[];
  legalStatuses: LegalStatus[];
  updateSDOutletDetails: (
    applicationId: number,
    payload: UpdateSDOutletDetailsRequest,
  ) => void;
  updateSDLegalEntityAddress: (
    applicationId: number,
    payload: UpdateSDLegalEntityAddressRequest,
  ) => void;
  loadStaticLists: () => void;
  loadApplicationMentionUsers: (id: number) => Promise<Mention[]> | null;
  loadApplicationComments: (id: number) => Promise<Comment[]> | null;
  createNote: (
    id: number,
    payload: CreateNoteRequest,
  ) => Promise<SDApplication | undefined> | null;
  createApplicant: (
    id: number,
    payload: SDApplicantRequest,
  ) => Promise<SDApplication> | null;
  updateApplicant: (
    applicationId: number,
    applicantId: number,
    payload: SDApplicantRequest,
  ) => Promise<SDApplication> | null;
  deleteApplicant: (
    applicationId: number,
    applicantId: number,
  ) => Promise<SDApplication> | null;
  createBar: (
    id: number,
    payload: CreateBarRequest,
  ) => Promise<SDApplication> | null;
  deleteBar: (
    applicationId: number,
    applicantId: number,
  ) => Promise<SDApplication> | null;
  updateBar: (
    applicationId: number,
    barId: number,
    payload: CreateBarRequest,
  ) => Promise<SDApplication> | null;
  updateInnserveDetails: (
    applicationId: number,
    payload: InnserveDetailsRequest,
  ) => Promise<SDApplication> | null;
  pricingSectors: PricingSector[];
  updateAdminDetails: (
    id: number,
    payload: UpdateSDAdminDetailsRequest,
  ) => Promise<SDApplication | undefined> | null;
  skipPersonalGuarantee: (
    applicationId: number,
    payload: SkipPersonalGuaranteeSignature,
  ) => void;
  skipDirectDebit: (
    applicationId: number,
    payload: SkipDirectDebitSignature,
  ) => void;
  createSigningRequest: (
    applicationId: number,
    applicantId: number,
  ) => Promise<SDApplication> | null;
  abortReasons: StatusReason[];
  loadStatusReasons: (status: string) => void;
  deleteApplication: (
    applicationId: number,
    payload: DeleteApplicationRequest,
  ) => Promise<SDApplication | undefined> | null;
  submitApplication: (
    applicationId: number,
    payload: SubmitSDApplicationRequest,
  ) => Promise<SDApplication> | null;
  approveApplication: (
    applicationId: number,
    payload: ApproveSDApplicationRequest,
  ) => Promise<SDApplication> | null;
  loadHeinekenDirectRoles: () => void;
  loadContactDetails: (id: number) => void;
  createHeinekenDirect: (
    id: number,
    payload: CreateHeinekenDirectRequest,
  ) => Promise<SDApplication | undefined> | null;
  updateHeinekenDirect: (
    applicationId: number,
    heinekenDirectId: number,
    payload: CreateHeinekenDirectRequest,
  ) => Promise<SDApplication | undefined> | null;
  deleteHeinekenDirect: (
    applicationId: number,
    heinekenDirectId: number,
  ) => Promise<SDApplication> | null;
  reopenApplication: (applicationId: number) => Promise<SDApplication> | null;
  completeApplication: (
    applicationId: number,
    payload: CompleteSDApplicationRequest,
  ) => Promise<SDApplication> | null;
  toggleApplication: (
    applicationId: number,
    payload: ToggleApplicationRequest,
    //TODO: Add combo type and remove SD
  ) => Promise<SDApplication | Application | undefined> | null;
  requestAccountNumbers: (
    applicationId: number,
    payload: AccountNumbersRequest,
  ) => Promise<SDApplication> | null;
  updateContractInfo: (
    applicationId: number,
    payload: UpdateContractInfoRequest,
  ) => Promise<SDApplication> | null;
  requestContractSignature: (
    applicationId: number,
  ) => Promise<SDApplication | undefined> | null;
}

interface NetworkState {
  findApplication: boolean;
  updateApplication: boolean;
  updateSalesReps: boolean;
  keyContacts: boolean;
  wholesaler: boolean;
  updateSDOutletDetails: boolean;
  updateSDLegalEntityAddress: boolean;
  loadStaticLists: boolean;
  contactDetails: boolean;
  loadApplicationComments: boolean;
  loadApplicationMentionUsers: boolean;
  createNote: boolean;
  createApplicant: boolean;
  updateApplicant: boolean;
  deleteApplicant: boolean;
  createBar: boolean;
  deleteBar: boolean;
  updateBar: boolean;
  updateInnserveDetails: boolean;
  updateAdminDetails: boolean;
  skipPersonalGuarantee: boolean;
  skipDirectDebit: boolean;
  createSigningRequest: boolean;
  loadStatusReasons: boolean;
  deleteApplication: boolean;
  submitApplication: boolean;
  approveApplication: boolean;
  loadHeinekenDirectRoles: boolean;
  createHeinekenDirect: boolean;
  updateHeinekenDirect: boolean;
  deleteHeinekenDirect: boolean;
  reopenApplication: boolean;
  completeApplication: boolean;
  toggleApplication: boolean;
  requestAccountNumbers: boolean;
  updateContractInfo: boolean;
  requestContractSignature: boolean;
}

const initNetworkState: NetworkState = {
  findApplication: false,
  updateApplication: false,
  updateSalesReps: false,
  keyContacts: false,
  wholesaler: false,
  updateSDOutletDetails: false,
  updateSDLegalEntityAddress: false,
  loadStaticLists: false,
  contactDetails: false,
  loadApplicationComments: false,
  loadApplicationMentionUsers: false,
  createNote: false,
  createApplicant: false,
  updateApplicant: false,
  deleteApplicant: false,
  createBar: false,
  deleteBar: false,
  updateBar: false,
  updateInnserveDetails: false,
  updateAdminDetails: false,
  skipPersonalGuarantee: false,
  skipDirectDebit: false,
  createSigningRequest: false,
  loadStatusReasons: false,
  deleteApplication: false,
  submitApplication: false,
  approveApplication: false,
  loadHeinekenDirectRoles: false,
  createHeinekenDirect: false,
  updateHeinekenDirect: false,
  deleteHeinekenDirect: false,
  reopenApplication: false,
  completeApplication: false,
  toggleApplication: false,
  requestAccountNumbers: false,
  updateContractInfo: false,
  requestContractSignature: false,
};

const SDApplicationDetailContext =
  createContext<SDApplicationDetailContextInterface>({
    initialised: false,
    error: initNetworkState,
    loading: { ...initNetworkState, findApplication: true },
    currentApplication: null,
    applicationComments: [],
    applicationMentionUsers: [],
    heinekenDirectRoles: [],
    contactDetails: [],
    innserveEmails: [],
    setCurrentApplication: (application: SDApplication | null) => null,
    findApplication: (id: number) => null,
    loadContactDetails: (id: number) => null,
    updateApplication: (id: number, payload: UpdateSDApplicationRequest) =>
      null,
    updateSalesReps: (id: number, payload: UpdateSDSalesRepsRequest) => null,
    keyContacts: [],
    loadKeyContacts: () => null,
    wholesalers: [],
    loadWholesalers: () => null,
    regions: [],
    outletSegments: [],
    legalStatuses: [],
    updateSDOutletDetails: (
      applicationId: number,
      payload: UpdateSDOutletDetailsRequest,
    ) => null,
    updateSDLegalEntityAddress: (
      applicationId: number,
      payload: UpdateSDLegalEntityAddressRequest,
    ) => null,
    loadStaticLists: () => null,
    loadApplicationComments: (id: number) => null,
    loadApplicationMentionUsers: (id: number) => null,
    createNote: (id: number, payload: CreateNoteRequest) => null,
    createApplicant: (id: number, payload: SDApplicantRequest) => null,
    updateApplicant: (
      applicationId: number,
      applicantId: number,
      payload: SDApplicantRequest,
    ) => null,
    deleteApplicant: (applicationId: number, applicantId: number) => null,
    createBar: (id: number, payload: CreateBarRequest) => null,
    deleteBar: (applicationId: number, barId: number) => null,
    updateBar: (
      applicationId: number,
      barId: number,
      payload: CreateBarRequest,
    ) => null,
    updateInnserveDetails: (
      applicationId: number,
      payload: InnserveDetailsRequest,
    ) => null,
    pricingSectors: [],
    updateAdminDetails: (id: number, payload: UpdateSDAdminDetailsRequest) =>
      null,
    skipPersonalGuarantee: (
      applicationId: number,
      payload: SkipPersonalGuaranteeSignature,
    ) => null,
    skipDirectDebit: (
      applicationId: number,
      payload: SkipDirectDebitSignature,
    ) => null,
    createSigningRequest: (applicationId: number, applicantId: number) => null,
    abortReasons: [],
    loadStatusReasons: (status: string) => null,
    deleteApplication: (
      applicationId: number,
      payload: DeleteApplicationRequest,
    ) => null,
    submitApplication: (
      applicationId: number,
      payload: SubmitSDApplicationRequest,
    ) => null,
    approveApplication: (
      applicationId: number,
      payload: ApproveSDApplicationRequest,
    ) => null,
    loadHeinekenDirectRoles: () => null,
    createHeinekenDirect: (id: number, payload: CreateHeinekenDirectRequest) =>
      null,
    updateHeinekenDirect: (
      applicationId: number,
      heinekenDirectId: number,
      payload: CreateHeinekenDirectRequest,
    ) => null,
    deleteHeinekenDirect: (applicationId: number, heinekenDirectId: number) =>
      null,
    reopenApplication: (applicationId: number) => null,
    completeApplication: (
      applicationId: number,
      payload: CompleteSDApplicationRequest,
    ) => null,
    toggleApplication: (
      applicationId: number,
      payload: ToggleApplicationRequest,
    ) => null,
    requestAccountNumbers: (
      applicationId: number,
      payload: AccountNumbersRequest,
    ) => null,
    updateContractInfo: (
      applicationId: number,
      payload: UpdateContractInfoRequest,
    ) => null,
    requestContractSignature: (applicationId: number) => null,
  });

export const Consume: () => SDApplicationDetailContextInterface = () => {
  const consumer = React.useContext(SDApplicationDetailContext);

  // Condition used to determine if Context Provider has been declared
  if (consumer.initialised === false) {
    throw new Error('SDApplicationDetailContextProvider not initialised');
  }
  return consumer;
};

export const Provider: React.FC = ({ children }) => {
  const [error, setError] = useState<NetworkState>(initNetworkState);
  const [loading, setLoading] = useState<NetworkState>({
    ...initNetworkState,
    findApplication: true,
  });
  const [currentApplication, setCurrentApplication] =
    useState<SDApplication | null>(null);
  const [keyContacts, setKeyContacts] = useState<KeyContact[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [outletSegments, setOutletSegments] = useState<OutletSegment[]>([]);
  const [legalStatuses, setLegalStatuses] = useState<LegalStatus[]>([]);
  const [applicationComments, setApplicationComments] = useState<Comment[]>([]);
  const [applicationMentionUsers, setApplicationMentionUsers] = useState<
    Mention[]
  >([]);
  const [pricingSectors, setPricingSectors] = useState<PricingSector[]>([]);
  const [abortReasons, setAbortReasons] = useState<StatusReason[]>([]);
  const [heinekenDirectRoles, setHeinekenDirectRoles] = useState<
    HeinekenDirectRole[]
  >([]);
  const [contactDetails, setContactDetails] = useState<Contact[]>([]);
  const [innserveEmails, setInnserveEmails] = useState<InnserveEmail[]>([]);
  const [wholesalers, setWholesalers] = useState<Wholesaler[]>([]);

  const findApplication = async (
    id: number,
  ): Promise<SDApplication | undefined> => {
    try {
      setError({ ...error, findApplication: false });
      setLoading({ ...loading, findApplication: true });
      const application = await SDApplicationService.findApplication(id);
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, findApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, findApplication: false });
    }
  };

  const updateApplication = async (
    id: number,
    payload: UpdateSDApplicationRequest,
  ) => {
    try {
      setError({ ...error, updateApplication: false });
      setLoading({ ...loading, updateApplication: true });
      const application = await SDApplicationService.updateApplication(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateApplication: false });
    }
  };

  const updateSalesReps = async (
    id: number,
    payload: UpdateSDSalesRepsRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, updateSalesReps: false });
      setLoading({ ...loading, updateSalesReps: true });
      const application = await SDApplicationService.updateSalesReps(
        id,
        payload,
      );
      if (
        currentApplication !== null &&
        application.id === currentApplication.id
      ) {
        setCurrentApplication(application);
      }
      return application;
    } catch (e) {
      setError({ ...error, updateSalesReps: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateSalesReps: false });
    }
  };

  const loadKeyContacts = async () => {
    try {
      setLoading({ ...loading, keyContacts: true });
      setError({ ...error, keyContacts: false });
      const keyContacts = await KeyContactService.listKeyContacts();
      setKeyContacts(keyContacts);
    } catch (e) {
      setError({ ...error, keyContacts: true });
      throw e;
    } finally {
      setLoading({ ...loading, keyContacts: false });
    }
  };

  const skipPersonalGuarantee = async (
    applicationId: number,
    payload: SkipPersonalGuaranteeSignature,
  ) => {
    try {
      setLoading({ ...loading, skipPersonalGuarantee: true });
      setError({ ...error, skipPersonalGuarantee: false });
      const application =
        await SDHGSSPersonalGuaranteeService.skipPersonalGuaranteeSignature(
          applicationId,
          payload,
        );
      setCurrentApplication(application);
      return application;
    } catch (error) {
      setError({ ...error, skipPersonalGuarantee: true });
      throw error;
    } finally {
      setLoading({
        ...loading,
        skipPersonalGuarantee: false,
      });
    }
  };

  const skipDirectDebit = async (
    applicationId: number,
    payload: SkipDirectDebitSignature,
  ) => {
    try {
      setLoading({ ...loading, skipDirectDebit: true });
      setError({ ...error, skipDirectDebit: false });
      const application = await HGSSDirectDebitService.skipDirectDebitSignature(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (error) {
      setError({ ...error, skipDirectDebit: true });
      throw error;
    } finally {
      setLoading({
        ...loading,
        skipDirectDebit: false,
      });
    }
  };

  const updateSDOutletDetails = async (
    applicationId: number,
    payload: UpdateSDOutletDetailsRequest,
  ) => {
    try {
      setLoading({ ...loading, updateSDOutletDetails: true });
      const application = await SDApplicationService.updateSDOutletDetails(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      setError({ ...error, updateSDOutletDetails: false });
    } catch (e) {
      setError({ ...error, updateSDOutletDetails: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateSDOutletDetails: false });
    }
  };

  const updateSDLegalEntityAddress = async (
    applicationId: number,
    payload: UpdateSDLegalEntityAddressRequest,
  ) => {
    try {
      setLoading({ ...loading, updateSDLegalEntityAddress: true });
      const application = await SDApplicationService.updateSDLegalEntityAddress(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      setError({ ...error, updateSDLegalEntityAddress: false });
    } catch (e) {
      setError({ ...error, updateSDLegalEntityAddress: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateSDLegalEntityAddress: false });
    }
  };

  const loadStaticLists = async () => {
    try {
      setLoading({ ...loading, loadStaticLists: true });
      const [
        regions,
        legalStatuses,
        outletSegments,
        pricingSectors,
        innserveEmails,
      ] = (await axios.all([
        RegionService.listRegions(),
        LegalStatusService.listLegalStatuses(),
        OutletSegmentService.listOutletSegments(),
        PricingSectorService.listPricingSectors(),
        InnserveEmailsService.listInnserveEmails(),
      ])) as [
        Region[],
        LegalStatus[],
        OutletSegment[],
        PricingSector[],
        InnserveEmail[],
      ];
      setRegions(regions);
      setLegalStatuses(legalStatuses);
      setOutletSegments(outletSegments);
      setPricingSectors(pricingSectors);
      setInnserveEmails(innserveEmails);
      setError({ ...error, loadStaticLists: false });
    } catch (e) {
      setError({ ...error, loadStaticLists: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadStaticLists: false });
    }
  };

  const loadApplicationComments = async (id: number): Promise<Comment[]> => {
    try {
      setError({ ...error, loadApplicationComments: false });
      setLoading({ ...loading, loadApplicationComments: true });
      const comments = await SDApplicationService.loadApplicationComments(id);
      setApplicationComments(comments);
      return comments;
    } catch (e) {
      setError({ ...error, loadApplicationComments: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadApplicationComments: false });
    }
  };

  const loadApplicationMentionUsers = async (
    id: number,
  ): Promise<Mention[]> => {
    try {
      setError({ ...error, loadApplicationMentionUsers: false });
      setLoading({ ...loading, loadApplicationMentionUsers: true });
      const mentionUsers =
        await SDApplicationService.loadApplicationMentionUsers(id);
      setApplicationMentionUsers(mentionUsers);
      return mentionUsers;
    } catch (e) {
      setError({ ...error, loadApplicationMentionUsers: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadApplicationMentionUsers: false });
    }
  };

  const createNote = async (
    id: number,
    payload: CreateNoteRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, createNote: false });
      setLoading({ ...loading, createNote: true });
      await SDApplicationService.createNote(id, payload);
      const application = await SDApplicationService.findApplication(id);
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createNote: true });
      throw e;
    } finally {
      setLoading({ ...loading, createNote: false });
    }
  };

  const createApplicant = async (
    id: number,
    payload: SDApplicantRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, createApplicant: false });
      setLoading({ ...loading, createApplicant: true });
      const application = await SDApplicationService.createApplicant(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createApplicant: true });
      throw e;
    } finally {
      setLoading({ ...loading, createApplicant: false });
    }
  };

  const toggleApplication = async (
    applicationId: number,
    payload: ToggleApplicationRequest,
  ): Promise<SDApplication | Application> => {
    try {
      setError({ ...error, toggleApplication: false });
      setLoading({ ...loading, toggleApplication: true });
      const application = await SDApplicationService.toggleApplication(
        applicationId,
        payload,
      );
      return application;
    } catch (e) {
      setError({ ...error, toggleApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, toggleApplication: false });
    }
  };

  const updateApplicant = async (
    applicationId: number,
    applicantId: number,
    payload: SDApplicantRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, updateApplicant: false });
      setLoading({ ...loading, updateApplicant: true });
      const application = await SDApplicationService.updateApplicant(
        applicationId,
        applicantId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateApplicant: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateApplicant: false });
    }
  };

  const deleteApplicant = async (
    applicationId: number,
    applicantId: number,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, deleteApplicant: false });
      setLoading({ ...loading, deleteApplicant: true });
      const application = await SDApplicationService.deleteApplicant(
        applicationId,
        applicantId,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, deleteApplicant: false });
      throw e;
    } finally {
      setLoading({ ...loading, deleteApplicant: false });
    }
  };

  const createBar = async (
    id: number,
    payload: CreateBarRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, createBar: false });
      setLoading({ ...loading, createBar: true });
      const application = await SDApplicationService.createBar(id, payload);
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createBar: true });
      throw e;
    } finally {
      setLoading({ ...loading, createBar: false });
    }
  };

  const updateBar = async (
    applicationId: number,
    barId: number,
    payload: CreateBarRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, updateBar: false });
      setLoading({ ...loading, updateBar: true });
      const application = await SDApplicationService.updateBar(
        applicationId,
        barId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateBar: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateBar: false });
    }
  };

  const deleteBar = async (
    applicationId: number,
    barId: number,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, deleteBar: false });
      setLoading({ ...loading, deleteBar: true });
      const application = await SDApplicationService.deleteBar(
        applicationId,
        barId,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, deleteApplicant: false });
      throw e;
    } finally {
      setLoading({ ...loading, deleteApplicant: false });
    }
  };

  const updateInnserveDetails = async (
    applicationId: number,
    payload: InnserveDetailsRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, updateInnserveDetails: false });
      setLoading({ ...loading, updateInnserveDetails: true });
      const application = await SDApplicationService.updateInnserveDetails(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateInnserveDetails: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateInnserveDetails: false });
    }
  };

  const updateContractInfo = async (
    applicationId: number,
    payload: UpdateContractInfoRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, updateContractInfo: false });
      setLoading({ ...loading, updateContractInfo: true });
      const application = await SDApplicationService.updateContractInfo(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateContractInfo: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateContractInfo: false });
    }
  };

  const updateAdminDetails = async (
    id: number,
    payload: UpdateSDAdminDetailsRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, updateAdminDetails: false });
      setLoading({ ...loading, updateAdminDetails: true });
      const application = await SDApplicationService.updateAdminDetails(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateAdminDetails: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateAdminDetails: false });
    }
  };

  const createSigningRequest = async (
    applicationId: number,
    applicantId: number,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, createSigningRequest: false });
      setLoading({ ...loading, createSigningRequest: true });
      const application = await SDSigningService.createSigningRequest(
        applicationId,
        applicantId,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createSigningRequest: true });
      throw e;
    } finally {
      setLoading({ ...loading, createSigningRequest: false });
    }
  };

  const loadStatusReasons = async (status: string) => {
    try {
      setLoading({ ...loading, loadStatusReasons: true });
      const abortReasons = await StatusReasonService.listStatusReasons(status);
      setAbortReasons(abortReasons);
      setError({ ...error, loadStatusReasons: false });
    } catch (e) {
      setError({ ...error, loadStatusReasons: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadStatusReasons: false });
    }
  };

  const deleteApplication = async (
    applicationId: number,
    payload: DeleteApplicationRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, deleteApplication: false });
      setLoading({ ...loading, deleteApplication: true });
      const application = await SDApplicationService.deleteApplication(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, deleteApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, deleteApplication: false });
    }
  };

  const loadHeinekenDirectRoles = async () => {
    try {
      setError({ ...error, loadHeinekenDirectRoles: false });
      setLoading({ ...loading, loadHeinekenDirectRoles: true });
      const heinekenDirectRoles =
        await HeinekenDirectRoleService.listHeinekenDirectRoles();
      setHeinekenDirectRoles(heinekenDirectRoles);
    } catch (e) {
      setError({ ...error, loadHeinekenDirectRoles: true });
      throw e;
    } finally {
      setLoading({ ...loading, loadHeinekenDirectRoles: false });
    }
  };

  const loadContactDetails = async (id: number) => {
    try {
      setError({ ...error, contactDetails: false });
      setLoading({ ...loading, contactDetails: true });
      const contactDetails = await SDApplicationService.listContactDetails(id);
      setContactDetails(contactDetails);
    } catch (e) {
      setError({ ...error, contactDetails: true });
      throw e;
    } finally {
      setLoading({ ...loading, contactDetails: false });
    }
  };

  const updateHeinekenDirect = async (
    applicationId: number,
    heinekenDirectId: number,
    payload: CreateHeinekenDirectRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, updateHeinekenDirect: false });
      setLoading({ ...error, updateHeinekenDirect: true });
      const application = await SDApplicationService.updateHeinekenDirect(
        applicationId,
        heinekenDirectId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, updateHeinekenDirect: true });
      throw e;
    } finally {
      setLoading({ ...loading, updateHeinekenDirect: false });
    }
  };

  const createHeinekenDirect = async (
    id: number,
    payload: CreateHeinekenDirectRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, createHeinekenDirect: false });
      setLoading({ ...loading, createHeinekenDirect: true });
      const application = await SDApplicationService.createHeinekenDirect(
        id,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createHeinekenDirect: true });
      throw e;
    } finally {
      setLoading({ ...loading, createHeinekenDirect: false });
    }
  };

  const submitApplication = async (
    applicationId: number,
    payload: SubmitSDApplicationRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, submitApplication: false });
      setLoading({ ...loading, submitApplication: true });
      const application = await SDApplicationService.submitApplication(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, submitApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, submitApplication: false });
    }
  };

  const approveApplication = async (
    applicationId: number,
    payload: ApproveSDApplicationRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, approveApplication: false });
      setLoading({ ...loading, approveApplication: true });
      const application = await SDApplicationService.approveApplication(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, createHeinekenDirect: true });
      throw e;
    } finally {
      setLoading({ ...loading, createHeinekenDirect: false });
    }
  };

  const deleteHeinekenDirect = async (
    applicationId: number,
    heinekenDirectId: number,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, deleteHeinekenDirect: false });
      setLoading({ ...loading, deleteHeinekenDirect: true });
      const application = await SDApplicationService.deleteHeinekenDirect(
        applicationId,
        heinekenDirectId,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, deleteHeinekenDirect: false });
      throw e;
    } finally {
      setLoading({ ...loading, deleteHeinekenDirect: false });
    }
  };

  const reopenApplication = async (
    applicationId: number,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, reopenApplication: false });
      setLoading({ ...loading, reopenApplication: true });
      const application = await SDApplicationService.reopenApplication(
        applicationId,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, reopenApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, reopenApplication: false });
    }
  };

  const completeApplication = async (
    applicationId: number,
    payload: CompleteSDApplicationRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, completeApplication: false });
      setLoading({ ...loading, completeApplication: true });
      const application = await SDApplicationService.completeApplication(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, completeApplication: true });
      throw e;
    } finally {
      setLoading({ ...loading, completeApplication: false });
    }
  };

  const requestAccountNumbers = async (
    applicationId: number,
    payload: AccountNumbersRequest,
  ): Promise<SDApplication> => {
    try {
      setError({ ...error, requestAccountNumbers: false });
      setLoading({ ...loading, requestAccountNumbers: true });
      const application = await SDApplicationService.requestAccountNumbers(
        applicationId,
        payload,
      );
      setCurrentApplication(application);
      return application;
    } catch (e) {
      setError({ ...error, requestAccountNumbers: true });
      throw e;
    } finally {
      setLoading({ ...loading, requestAccountNumbers: false });
    }
  };

  const loadWholesalers = async () => {
    try {
      setLoading({ ...loading, wholesaler: true });
      setError({ ...error, wholesaler: false });
      const wholesalers = await SDApplicationService.loadWholesalers();
      setWholesalers(wholesalers);
    } catch (e) {
      setError({ ...error, wholesaler: true });
      throw e;
    } finally {
      setLoading({ ...loading, wholesaler: false });
    }
  };

  const requestContractSignature = async (
    applicationId: number,
  ): Promise<SDApplication> => {
    try {
      setLoading({ ...loading, requestContractSignature: true });
      setError({ ...error, requestContractSignature: false });
      const application = await SDContractService.requestContractSignature(
        applicationId,
      );
      setCurrentApplication(application);
      return application;
    } catch (error) {
      setError({ ...error, requestContractSignature: true });
      throw error;
    } finally {
      setLoading({
        ...loading,
        requestContractSignature: false,
      });
    }
  };

  return (
    <SDApplicationDetailContext.Provider
      value={{
        initialised: true,
        error,
        loading,
        currentApplication,
        setCurrentApplication,
        findApplication,
        applicationMentionUsers,
        updateApplication,
        updateSalesReps,
        keyContacts,
        loadKeyContacts,
        regions,
        outletSegments,
        legalStatuses,
        heinekenDirectRoles,
        contactDetails,
        updateSDOutletDetails,
        updateSDLegalEntityAddress,
        loadStaticLists,
        loadApplicationComments,
        loadApplicationMentionUsers,
        loadHeinekenDirectRoles,
        loadContactDetails,
        applicationComments,
        createNote,
        createApplicant,
        updateApplicant,
        deleteApplicant,
        createBar,
        deleteBar,
        updateBar,
        updateInnserveDetails,
        pricingSectors,
        updateAdminDetails,
        createSigningRequest,
        abortReasons,
        loadStatusReasons,
        deleteApplication,
        submitApplication,
        approveApplication,
        updateHeinekenDirect,
        createHeinekenDirect,
        deleteHeinekenDirect,
        reopenApplication,
        completeApplication,
        toggleApplication,
        requestAccountNumbers,
        innserveEmails,
        wholesalers,
        loadWholesalers,
        updateContractInfo,
        skipPersonalGuarantee,
        skipDirectDebit,
        requestContractSignature
      }}
    >
      {children}
    </SDApplicationDetailContext.Provider>
  );
};
