import PermissionsHelper from 'utils/PermissionsHelper';
export const STORAGE_TOKEN_KEY = 'authorization_token';
export const STORAGE_TOKEN_TYPE = 'authorization_token_type';
export const STORAGE_TOKEN_USER = 'authorization_token_user';
export const DEFAULT_PUBLIC_ROUTE = '/';
export const STORAGE_ENTRY_URL_KEY = 'entry_point';

export default class AuthHelper {
  static getToken(): string | null {
    return window.localStorage.getItem(STORAGE_TOKEN_KEY);
  }

  static setAuth(token: string): void {
    window.localStorage.setItem(STORAGE_TOKEN_KEY, token);
  }
  static setAuthType(type: string): void {
    window.localStorage.setItem(STORAGE_TOKEN_TYPE, type);
  }
  static setAuthUser(user: string): void {
    window.localStorage.setItem(STORAGE_TOKEN_USER, user);
  }
  static getAuthUser(): string {
    const user = window.localStorage.getItem(STORAGE_TOKEN_USER);
    return user !== null ? user : '';
  }

  static getAuthType(): string | null {
    return window.localStorage.getItem(STORAGE_TOKEN_TYPE);
  }

  static removeAuth(): void {
    window.localStorage.removeItem(STORAGE_TOKEN_KEY);
    window.localStorage.removeItem(STORAGE_TOKEN_TYPE);
    window.localStorage.removeItem(STORAGE_TOKEN_USER);
  }

  static isAuthenticated(): boolean {
    return this.getToken() !== undefined && this.getToken() !== null;
  }

  static getDefaultRoute(): string {
    let privateRoute;
    const permissions = PermissionsHelper.getPermissions();
    if (PermissionsHelper.hasPermission(permissions, 'enquiries', 'viewList')) {
      privateRoute = '/enquiries?page=1';
    } else if (
      PermissionsHelper.hasPermission(permissions, 'prospect', 'viewList')
    ) {
      privateRoute = '/applications?page=1&statusKey=approved';
    } else if (
      PermissionsHelper.hasPermission(
        permissions,
        'smartDispenseEnquiries',
        'viewList',
      )
    ) {
      privateRoute = '/smart-dispense/enquiries?page=1';
    } else {
      privateRoute = '/applications?page=1&statusKey=boarding';
    }

    return this.isAuthenticated() ? privateRoute : DEFAULT_PUBLIC_ROUTE;
  }
}
