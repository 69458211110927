import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import Axios from '../Client';
import UpdateApplicationRequest from 'services/ApplicationService/requests/UpdateApplicationRequest';
import UpdateSalesRepsRequest from 'services/ApplicationService/requests/UpdateSalesRepsRequest';
import UpdateOutletDetailsRequest from './requests/UpdateOutletDetailsRequest';
import UpdateOutletSalesRequest from 'services/ApplicationService/requests/UpdateOutletSalesRequest';
import UpdateBillingDetailsRequest from 'services/ApplicationService/requests/UpdateBillingDetailsRequest';
import UpdateOrderDetailsRequest from 'services/ApplicationService/requests/UpdateOrderDetailsRequest';
import CreateNoteRequest from 'services/ApplicationService/requests/CreateNoteRequest';
import UpdateAdminDetailsRequest from 'services/ApplicationService/requests/UpdateAdminDetailsRequest';
import UpdateAdditionalInfoRequest from 'services/ApplicationService/requests/UpdateAdditionalInfoRequest';
import UpdateOffersRequest from 'services/ApplicationService/requests/UpdateOffersRequest';
import ApplicantRequest from 'services/ApplicationService/requests/ApplicantRequest';
import CreateHeinekenDirectRequest from 'services/ApplicationService/requests/CreateHeinekenDirectRequest';
import CreateNewApplicationRequest from 'services/ApplicationService/requests/CreateNewApplicationRequest';
import SubmitApplicationRequest from 'services/ApplicationService/requests/SubmitApplicationRequest';
import AccountNumbersRequest from 'services/ApplicationService/requests/AccountNumbersRequest';
import DeleteAppicationRequest from 'services/ApplicationService/requests/DeleteApplicationRequest';
import ToggleApplicationRequest from 'services/ApplicationService/requests/ToggleApplicationRequest';
import { LinkNewApplicationRequest } from 'services/ApplicationService/requests/LinkNewApplicationRequest';
import QueryParams from 'models/QueryParams';
import Application from 'models/Application';
import Contact from 'models/Contact';
import Comment from 'models/Comment';
import Mention from 'models/Mention';
import { BuyingGroup } from 'models/BuyingGroup';
import Pagination from 'models/Pagination';

const ApplicationService = {
  findApplication: async (id: number): Promise<Application> => {
    const response = await Axios.get<Application>(`/applications/${id}`);
    return response.data;
  },
  updateApplication: async (
    id: number,
    payload: UpdateApplicationRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${id}`,
      payload,
    );
    return response.data;
  },
  updateSalesReps: async (
    id: number,
    payload: UpdateSalesRepsRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${id}/sales-reps`,
      payload,
    );
    return response.data;
  },
  updateOutletDetails: async (
    applicationId: number,
    outletId: number,
    payload: UpdateOutletDetailsRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${applicationId}/outlets/${outletId}`,
      payload,
    );
    return response.data;
  },
  updateOutletSales: async (
    applicationId: number,
    outletId: number,
    payload: UpdateOutletSalesRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${applicationId}/outlets/${outletId}/sales`,
      payload,
    );
    return response.data;
  },
  updateBillingDetails: async (
    id: number,
    payload: UpdateBillingDetailsRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${id}/billing-details`,
      payload,
    );
    return response.data;
  },
  updateOrderDetails: async (
    id: number,
    payload: UpdateOrderDetailsRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${id}/order-details`,
      payload,
    );
    return response.data;
  },

  updateAdminDetails: async (
    id: number,
    payload: UpdateAdminDetailsRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${id}/admin-details`,
      payload,
    );
    return response.data;
  },

  updateAdditionalInfo: async (
    id: number,
    payload: UpdateAdditionalInfoRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${id}/additional-info`,
      payload,
    );
    return response.data;
  },

  updateOffers: async (
    id: number,
    payload: UpdateOffersRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${id}/offers`,
      payload,
    );
    return response.data;
  },

  updateHeinekenDirect: async (
    applicationId: number,
    heinekenDirectId: number,
    payload: CreateHeinekenDirectRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${applicationId}/heineken-direct/${heinekenDirectId}`,
      payload,
    );
    return response.data;
  },

  listContactDetails: async (id: number): Promise<Contact[]> => {
    const response = await Axios.get<Contact[]>(`/applications/${id}/persons`);
    return response.data;
  },

  loadApplicationComments: async (id: number): Promise<Comment[]> => {
    const response = await Axios.get<Comment[]>(`/applications/${id}/comments`);
    return response.data;
  },
  loadApplicationMentionUsers: async (id: number): Promise<Mention[]> => {
    const response = await Axios.get<Mention[]>(`/applications/${id}/mentions`);
    return response.data;
  },

  createNote: async (
    id: number,
    payload: CreateNoteRequest,
  ): Promise<Application> => {
    const response = await Axios.post<Application>(
      `/applications/${id}/note`,
      payload,
    );
    return response.data;
  },

  createApplicant: async (
    id: number,
    payload: ApplicantRequest,
  ): Promise<Application> => {
    const response = await Axios.post<Application>(
      `/applications/${id}/applicants`,
      payload,
    );
    return response.data;
  },
  deleteApplicant: async (
    applicationId: number,
    applicantId: number,
  ): Promise<Application> => {
    const response = await Axios.delete<Application>(
      `/applications/${applicationId}/applicants/${applicantId}`,
    );
    return response.data;
  },
  updateApplicant: async (
    applicationId: number,
    applicantId: number,
    payload: ApplicantRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${applicationId}/applicants/${applicantId}`,
      payload,
    );
    return response.data;
  },
  createHeinekenDirect: async (
    id: number,
    payload: CreateHeinekenDirectRequest,
  ): Promise<Application> => {
    const response = await Axios.post<Application>(
      `/applications/${id}/heineken-direct`,
      payload,
    );
    return response.data;
  },

  createNewApplication: async (
    payload: CreateNewApplicationRequest,
  ): Promise<Application> => {
    const response = await Axios.post<Application>(`/applications`, payload);
    return response.data;
  },

  deleteHeinekenDirect: async (
    applicationId: number,
    heinekenDirectId: number,
  ): Promise<Application> => {
    const response = await Axios.delete<Application>(
      `/applications/${applicationId}/heineken-direct/${heinekenDirectId}`,
    );
    return response.data;
  },

  submitApplication: async (
    applicationId: number,
    payload: SubmitApplicationRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${applicationId}/submit`,
      payload,
    );
    return response.data;
  },

  requestAccountNumbers: async (
    applicationId: number,
    payload: AccountNumbersRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${applicationId}/master-data`,
      payload,
    );
    return response.data;
  },
  paginateApplications: async (
    params: QueryParams,
    config?: AxiosRequestConfig,
  ): Promise<Pagination<Application>> => {
    let paramsObject = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== "" && v !== null),
    );

    const response = await Axios.get<Pagination<Application>>(
      `/applications`,
      {
        ...config,
        params: { ...paramsObject },
        paramsSerializer: (obj) => {
          return qs.stringify(obj, { encode: false });
        },
      },
    );
    return response.data;
  },
  completeApplication: async (applicationId: number) => {
    const response = await Axios.put<Application>(
      `/applications/${applicationId}/complete`,
    );
    return response.data;
  },
  reopenApplication: async (applicationId: number) => {
    const response = await Axios.put<Application>(
      `/applications/${applicationId}/reopen`,
    );
    return response.data;
  },
  deleteApplication: async (
    applicationId: number,
    payload: DeleteAppicationRequest,
  ): Promise<Application> => {
    const response = await Axios.delete<Application>(
      `/applications/${applicationId}`,
      { data: payload },
    );
    return response.data;
  },

  toggleApplication: async (
    applicationId: number,
    payload: ToggleApplicationRequest,
  ): Promise<Application> => {
    const response = await Axios.put<Application>(
      `/applications/${applicationId}/change`,
      payload,
    );
    return response.data;
  },

  linkNewApplication: async (
    applicationId: number,
    payload: LinkNewApplicationRequest,
  ): Promise<Application> => {
    const response = await Axios.post<Application>(`/applications/${applicationId}/buying-group`, payload);
    return response.data;
  },

  findLinkedApplications: async (id: number): Promise<BuyingGroup[]> => {
    const response = await Axios.get<BuyingGroup[]>(`/applications/${id}/buying-group`);
    return response.data;
  },
};


export default ApplicationService;